import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { log } from 'console';
@Component({
  selector: 'app-enquiry-detail',
  templateUrl: './enquiry-detail.component.html',
  styleUrls: ['./enquiry-detail.component.scss']
})
export class EnquiryDetailComponent implements OnInit {
  id: string;
  name: ''
  destination: ''
  email: ''
  addEnquiryDetailForm: FormGroup;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  private enquiryid: any;
  public submitted: boolean = false;
  data: any;
  notes: any = '';
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.addEnquiryDetailForm = this.formBuilder.group({
      //user_role: this.formBuilder.array([], [Validators.required])
    })
  }

  ngOnInit(): void {

    this.addEnquiryDetailForm = new FormGroup({
      name: new FormControl(),
      destination: new FormControl(),
      mobile: new FormControl(),
      email: new FormControl(),
      note: new FormControl(),


    });


    this.addEnquiryDetailForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      destination: ['', [Validators.required, Validators.minLength(3)]],
      mobile: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      email: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      notes: ['']
    });
    this.enquiryid = this.route.snapshot.paramMap.get('enquiry_id');
    // console.log('enquiry Id', this.enquiryid);
    this.editenquiry(this.id)
    if (
      this.enquiryid != undefined &&
      this.enquiryid != null &&
      this.enquiryid != ''
    ) {
      this.editenquiry(this.enquiryid);
    }


  }

  get f() {
    return this.addEnquiryDetailForm.controls;
  }
  editenquiry(id) {
    this.apiService.getEnquiryById(this.enquiryid).subscribe((res: any) => {
      console.log(res);
      this.data = res.data
      this.notes = this.data.notes
      // console.log(this.data.name);
      // console.log(this.data.email);
      if (res.success) {
        this.addEnquiryDetailForm.patchValue({
          name: res.data?.name,
        });

        this.addEnquiryDetailForm.patchValue({
          destination: res.data?.destination,
        });

        this.addEnquiryDetailForm.patchValue({
          mobile: res.data?.mobile,
        });

        this.addEnquiryDetailForm.patchValue({
          email: res.data?.email,
        });

        this.addEnquiryDetailForm.patchValue({
          notes: res.data?.notes,
        });

      }
    });
  }

  updateNotes(newNotes: string) {
    this.notes = newNotes;
  }

  updateNote() {
    // console.log(this.notes, "heloovfjkxdwe");

    const notes = {
      notes: this.notes
    }
    // console.log(this.notes,"helloo");

    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService

      .updateEnquiryById(this.enquiryid, notes)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/enquiry-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }
  // notes(notes: any) {
  //   throw new Error('Method not implemented.');
  // }

  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}
