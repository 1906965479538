import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
})
export class SuperAdminComponent implements OnInit {
  addSuperForm: FormGroup;
  public readOnly: boolean = false;
  isShown: boolean = false;
  userRoleList: any = [
    { id: 1, name: 'Web User', value: 'web_user', check: false },
    { id: 2, name: 'Admin Registration', value: 'admin_registration', check: false },
    { id: 3, name: 'Add Coupon', value: 'add_coupan', check: false },
    { id: 4, name: 'Add Notes', value: 'add_notes', check: false },
    { id: 5, name: 'Blog', value: 'blog', check: false },
    { id: 6, name: 'Boarding Point', value: 'boarding_point', check: false },
    { id: 7, name: 'Package Type', value: 'package_type', check: false },
    { id: 8, name: 'Adventure ', value: 'adventure', check: false },
    { id: 9, name: 'Destination', value: 'destination', check: false },
    { id: 10, name: 'Special Tour', value: 'special_tour', check: false },
    { id: 11, name: 'Make Trip', value: 'make_trip', check: false },
    { id: 12, name: 'Other Charge', value: 'other_charge', check: false },
    { id: 13, name: 'Add Gallery', value: 'add_gallery', check: false },
    { id: 14, name: 'Add Video', value: 'add_video', check: false },
    { id: 15, name: 'Report', value: 'report', check: false },
    { id: 16, name: 'Room Sharing', value: 'room_sharing', check: false },
    { id: 17, name: 'Tax Details', value: 'tax_list', check: false },
    { id: 18, name: 'Landing Page Details', value: 'landing_page_details', check: false },
    { id: 19, name: 'Refund', value: 'refund', check: false },
    { id: 20, name: 'Order Pay Link', value: 'order_pay_link', check: false }
  ];

  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public cancelCatRoute: string;
  private adminid: any;
  private status: any;
  public submitted: boolean = false;
  user: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.addSuperForm = this.formBuilder.group({
      //user_role: this.formBuilder.array([], [Validators.required])
    });
  }

  ngOnInit(): void {
    this.addSuperForm = new FormGroup({
      firstname: new FormControl(),
      lastname: new FormControl(),
      mobile: new FormControl(),
      email: new FormControl(),
      role: new FormControl(),
      password: new FormControl(),
      confirm_password: new FormControl(),
      status: new FormControl(),

      //user_role: new FormControl(),
    });
    this.addSuperForm = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(3)]],
      lastname: ['', [Validators.required, Validators.minLength(3)]],
      mobile: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      email: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      password: ['', [Validators.required]],
      //  confirm_password: ['', [Validators.required]],
      role: ['', [Validators.required]],
      status: ['', [Validators.required]],
      //user_role: [[], [Validators.required]],
      user_role: this.formBuilder.array([], [Validators.required]),

      //user: ['', [Validators.required]],
      // user: ['', Validators.requiredTrue]
    });
    this.adminid = this.route.snapshot.paramMap.get('admin_id');
    console.log('admin Idddddd', this.adminid);
    if (
      this.adminid != undefined &&
      this.adminid != null &&
      this.adminid != ''
    ) {
      this.editadminame(this.adminid);
    } else {
      this.addSuperForm.get('status').setValue('active');
    }
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  get f() {
    return this.addSuperForm.controls;
  }

  editadminame(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.adminId(dataForForm).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.addSuperForm.patchValue({
          firstname: res.getData.firstname,
        });

        this.addSuperForm.patchValue({
          lastname: res.getData.lastname,
        });

        this.addSuperForm.patchValue({
          mobile: res.getData.mobile,
        });
        this.addSuperForm.patchValue({
          email: res.getData.email,
        });

        this.addSuperForm.patchValue({
          password: res.getData.password,
        });

        this.addSuperForm.patchValue({
          role: res.getData.role,
        });

        if (res.getData.status == 'active') {
          this.addSuperForm.get('status').setValue('active');
        }
        this.readOnly = true;

        this.userRoleList.forEach((ele: any) => {
          if (res.getData.user_role?.includes(ele.value)) ele.check = true;
        });
      }
    });
  }

  setAdmin() {
    //this.submitted = true;

    // console.log(this.addSuperForm.value);
    // if (!this.addSuperForm.valid) {
    //   Swal.fire({
    //     text: 'Please enter valid details',
    //     icon: 'warning',
    //   });
    //   return;
    // }

    const userRole = [];
    this.userRoleList.forEach(ele => {
      if(ele.check === true) {
        userRole.push(ele.value);
      }
    });
    //console.log(userRole);
    this.addSuperForm.value.user_role = userRole;

    if (
      this.addSuperForm.value.firstname == undefined ||
      this.addSuperForm.value.firstname.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter first name',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter First name');
      // return false;
    }

    if (
      this.addSuperForm.value.lastname == undefined ||
      this.addSuperForm.value.lastname.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter last name',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Last name');
      // return false;
    }

    if (
      this.addSuperForm.value.mobile == undefined ||
      this.addSuperForm.value.mobile == ''
    ) {
      Swal.fire({
        text: 'Please enter mobile number',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Mobile Number');
      // return false;
    }

    if (
      this.addSuperForm.value.email == undefined ||
      this.addSuperForm.value.email.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter email',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Email');
      // return false;
    }

    if (
      this.addSuperForm.value.role == undefined ||
      this.addSuperForm.value.role == ''
    ) {
      Swal.fire({
        text: 'Please enter admin role',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Admin Role');
      // return false;
    }

    if (
      this.addSuperForm.value.password == undefined ||
      this.addSuperForm.value.password.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter password',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Password');
      // return false;
    }

    if (this.addSuperForm.status == 'active') {
      this.addSuperForm.get('status').setValue('active');
    }

    if (
      this.adminid != undefined &&
      this.adminid != null &&
      this.adminid != ''
    ) {
      //this.addSuperForm.get('role').clearValidators();
      this.updateAdminName(this.adminid);
    } else {
      // console.log(this.addSuperForm.value);
      // return;
      this.apiService.addRole(this.addSuperForm.value).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          //this.toastr.success(res.message);
          this.router.navigateByUrl('/super-admin-list');
        } else {
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
    }
  }

  updateAdminName(id) {
    //delete this.addSuperForm.value.password;
    // console.log(this.addSuperForm.value,'hiiiiiiiiiiii');
    this.apiService
      .upAdmin(this.addSuperForm.value, id)
      .subscribe((res: any) => {
        //console.log(this.addSuperForm.value, "valueeeeeee")
        console.log(res, 'uppp');

        if (res.success) {
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          //this.toastr.success(res.message);
          this.router.navigateByUrl('/super-admin-list');
        } else {
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }
  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}
