<div class="content">
      <div class="container-fluid">
            <div  class="card card-primary">
                  <div *ngIf="this.pricingid" class="card-body">
                        <div class="tab-container">
                            <hr style="border-top: 1px solid black; width: auto;">
                            <hr style="border-top: 1px solid black; width: 0%;">
                    
                            <!-- Define tabs for each page with routerLinkActive directive -->
                            <div class="tab circular-button navbar-nav" routerLinkActive="active" [routerLink]="['/make-trip', this.object]" title="Make Trip">1</div>
                            
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-date', this.object]" title="Make Trip Date">2</div>
                    
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-overview', this.object]" title="Make Trip Overview">3</div>
                    
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-itinerary', this.object]" title="Make Trip Itinerary">4</div>
                            
                            <hr style="border-top: 1px solid black; width: 300%;">
                            <hr style="border-top: 1px solid black; width: 400%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-inclusion', this.object]" title="Make Trip Inclusion">5</div>
                    
                            <hr style="border-top: 1px solid black; width: 156%;">
                            <hr style="border-top: 1px solid black; width: 500%;">
                    
                            <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-pricing-list', this.object]" title="Make Trip Pricing">6</div><br>
                            
                            <hr style="border-top: 1px solid black; width: auto;">
                        </div>
                    </div> 

            </div>
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addItineraryForm" (ngSubmit)="addMakeTourThree(id)">
                                    <div class="card-body">

                                          <div class="row" *ngIf="allDates?.length != 0">
                                                <div class="col-md-12" *ngFor="let data of allDates; let i = index">
                                                      <div class="form-group">
                                                            <label for="class_name"> Day : {{ i + 1 }}
                                                                  <span class="required"></span></label>

                                                            <input type="text" class="form-control"
                                                                  [formControlName]="'title' + i" placeholder="Title">


                                                      </div>
                                                      <div class="form-group">
                                                            <label for="class_name">Itinerary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck"
                                                                  [formControlName]="'desc' + i"
                                                                  placeholder="Description">

                                                            </ckeditor>
                                                      </div>
                                                      <div class="row" style="margin-bottom: 35px;">
                                                            <div class="col-md-3" style="margin-top: 25px;"
                                                                  *ngFor="let prop of propertyLists['properties_list_'+i]; let x = index">
                                                                  <div class="custom-control custom-checkbox">
                                                                        <label>
                                                                              <input type="checkbox"
                                                                                    [formControlName]="'properties' + i + (+x)"
                                                                                    [value]="prop.value"
                                                                                    (change)="prop.value = prop.check === true ? false : true"
                                                                                    [checked]="prop.check" />
                                                                              {{prop.name}}
                                                                        </label>
                                                                  </div>
                                                            </div>

                                                      </div>
                                                      <div class="form-group col-md-12">
                                                            <label for="imageUpload">Multiple Image</label>&nbsp;&nbsp;
                                                            <input type="file" id="imageUpload" multiple accept="image/*" (change)="addImage($event, i)">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <div class="row">
                                                                <div class="col-md-2" *ngFor="let images of itineraryImages[i] || []; let j = index">
                                                                    <div class="card">
                                                                        <img class="card-img-top" height="120px" [src]="images" alt="Card image">
                                                                        <div class="card-body p-1">
                                                                              <div class="text-center p-1">
                                                                                   <button class="btn btn-danger btn-sm btn-block mt-1" (click)="removeImage(i, j)">Remove</button>
                                                                              </div>
                                                                          </div>
                                                                        <!-- Add any additional controls/buttons if needed -->
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                </div>

                                                <div class="col-md-12">
                                                      <div class="form-group ">
      
                                                            <label for="class_name">Itinerary Note</label>
                                                            <ckeditor [config]="config_ck" formControlName="tourOutlineNote"> 
                        
                                                            </ckeditor>
                        
                                                        </div>
                                                </div>

                                          </div>



                                          <br>
                                          <br>

                                          <div class="col-md-4">
                                                <button type="button" class="btn btn-success mt-2"
                                                      (click)="addAdditionalInfo()">
                                                      <i class="fas fa-plus-circle"></i>
                                                </button>
                                          </div>
                                          <br>
                                          <div class="row"
                                                *ngFor="let group of additionalInfo.controls; let i = index;"
                                                formArrayName="multiple_itinerarydesc">
                                                <div class="col-md-12">
                                                      <div class="row form-group" [formGroupName]='i'>

                                                            <div class="col-md-6">
                                                                  <label for="class_name">Itinerary Title</label>
                                                                  <input type="text" class="form-control"
                                                                  placeholder="Itinerary Title "
                                                                  formControlName="itinerary_title">
                                                            </div>

                                                            <div class="col-md-12">
                                                                  <label for="class_name">Itinerary
                                                                        Description</label>
                                                                 
                                                                  <ckeditor [config]="config_ck"
                                                                  formControlName="itinerary_desc">
                                                                  </ckeditor>
                                                            </div>

                                                            <div class="col-md-2">
                                                                  <button type="button"
                                                                        class="btn btn-success mt-2 "
                                                                        (click)="addAdditionalInfo()">
                                                                        <i class="fas fa-plus-circle"></i>
                                                                  </button>&nbsp;
                                                                  <button type="button"
                                                                        class="btn btn-danger mt-2"
                                                                        (click)="removeAdditionalInfo(i)">
                                                                        <i class="fas fa-minus-circle "></i>
                                                                  </button>
                                                            </div>

                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'" [loading]="isAuthLoading" [color]="'info'"
                                          [disabled]="isAuthDisabled"></app-button>   
                                          <button type="submit" class="btn btn-info"
                                                [routerLink]="['/make-trip-overview', tripId]" style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>