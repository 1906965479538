import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-landing-report',
  templateUrl: './landing-report.component.html',
  styleUrls: ['./landing-report.component.scss']
})
export class LandingReportComponent implements OnInit, OnDestroy {
  dtOptions: any = {};
  public allData = [];
  private isDtInitialized: boolean = false;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private apiService: ApiService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [{
        extend: 'excel',
        exportOptions: {
          columns: [0,1,2,3,4,5,6]
        }
      }]
    };
    this.allLandingData();
  }

  allLandingData() {
    this.spinner.show();
    this.apiService.getAllLandingData().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      this.spinner.hide();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.spinner.hide();
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  formateDate(getDate: string) {
    if (getDate)
      return `${getDate.split("-")[2]}-${getDate.split("-")[1]}-${getDate.split("-")[0]}`;
    else
      return "-";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
