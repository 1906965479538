import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';

@Component({
  selector: 'app-add-menu-master-new',
  templateUrl: './add-menu-master-new.component.html',
  styleUrls: ['./add-menu-master-new.component.scss']
})
export class AddMenuMasterNewComponent implements OnInit {
  addMenuMasterFormnew: FormGroup;
  allData: any;
  private adminid: any;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  //adminid any;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngselectmodule: NgSelectModule,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.addMenuMasterFormnew = new FormGroup({
      category: new FormControl(),
      submneuArr: new FormControl(),
      //category: new FormControl(),
      status: new FormControl(),
      //isfeaturetour: new FormControl(),
    });
    this.addMenuMasterFormnew = this.formBuilder.group({
      category: ['', [Validators.required]],
      submneuArr: ['', [Validators.required]],
      //category: ['', [Validators.required]],
      status: [false],
      //isfeaturetour: [0]
    });
    this.adminid = this.route.snapshot.paramMap.get('menumasternew_id');
    if ( 
      this.adminid != undefined &&
      this.adminid != null &&
      this.adminid != ''
    ) {
      this.editmenumaster(this.adminid);
    } else {
      this.addMenuMasterFormnew.get('status').setValue('active')
    }
    this.menu();
  }

  editmenumaster(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getmenumappingbyID(id).subscribe((result: any) => {
      console.log(result.data);
      if (result.success) { 
        //console.log(result.getData, 'hiiiiiiiiiii');
        //localStorage.setItem('menumaster_id',result.getData.parentcategory)

        let menuData: any = result.data;
        const selectedAdventureTypeIds = menuData.submneuArr.map(item => item._id);
        this.addMenuMasterFormnew.patchValue({
          category: menuData.category,
          submneuArr: selectedAdventureTypeIds
          //parentcategory: menuData.parentcategory,
        });
       // CKEDITOR.instances[this.instance.name].setData(menuData.description);
        if (menuData.status == 'active') {
          this.addMenuMasterFormnew.get('status').setValue('active');
        }
        // if (menuData.isfeaturetour == '1') {
        //   this.addMenuMasterForm.get('isfeaturetour').setValue('1');
        // }
        // setTimeout(() => {
        //   this.importFestImg.nativeElement.innerText =
        //     menuData.image[0]?.orgName;
        // }, 1000);
      } else {
        this.toastr.error(result.message);
      }
    });
  }

  

  menu() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.allMenuMatser().subscribe((resp: any) => {
      // console.log(resp);
      this.allData = resp.data.filter((item: any) => item.status === 'active');
    // console.log(this.allData);
    });
  }

  addmastermenunew() {

    if (
      this.addMenuMasterFormnew.value.category == undefined ||
      this.addMenuMasterFormnew.value.category.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter Main Menu Name ',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter First name');
      // return false;
    }

    if (
      this.addMenuMasterFormnew.value.submneuArr == undefined ||
      this.addMenuMasterFormnew.value.submneuArr == ''
    ) {
      Swal.fire({
        text: 'Please enter Sub Menu',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter Last name');
      // return false;
    }

  
    if (
      this.adminid != undefined &&
      this.adminid != null &&
      this.adminid != ''
    ) {
      //this.addSuperForm.get('role').clearValidators();
      this.updateAdminName(this.adminid);
    } else {
      console.log(this.addMenuMasterFormnew.value);
      //return;
      this.apiService.addmenunew(this.addMenuMasterFormnew.value).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          //this.toastr.success(res.message);
          this.router.navigateByUrl('/add-menu-master-list-new');
        } else {
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
    }
  }


  updateAdminName(id) {
    // console.log(id)
    // return
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .menumappingupdatenew(id, this.addMenuMasterFormnew.value)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/add-menu-master-list-new');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }


}
 