import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
// import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-notifications-dropdown-menu',
  templateUrl: './notifications-dropdown-menu.component.html',
  styleUrls: ['./notifications-dropdown-menu.component.scss'],
})
export class NotificationsDropdownMenuComponent implements OnInit {
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  public messageArray: string[] = [];
  public myNewArr: any[] = [];
  public clickedNotify: string[] = [];
  dataTour: any = [];
  dataUser: any = [];
  message: string;
  time: any[] = [];
  notificationCount: any;
  clickNotification: boolean;
  changeCol: any;
  changeColArr: any[] = [];
  tripTrekArr: any[] = [];
  dummyArr: Array<any> = [1,2,3,4,5,6,7,8,9,10]
  page: number = 1;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private apiService: ApiService) { }

  ngOnInit() {
    // this.socket.on("message", (event: any) => {
    //   this.message = `${event} booked a tour`
    // })
    this.notificationList(this.page);
    // this.getNotificationCount();
  }

  // notificationList() {
  //   this.apiService.allNotification().subscribe((res: any) => {
  //     console.log(res, "ressssssssssssssss!!!!!!!!!!!!!!!!!!!!")
  //     const time1: any = new Date();
  //     for (let x = 0; x < res.data.length; x++) {
  //       this.changeCol = res.data[x].is_new;
  //       // ! time calc in hours and mins
  //       // time in seconds, to change divide by 60 for min and 3600 for hour
  //       const time2: any = new Date(res.data[x].created_at);
  //       let timeDiffinSec = Math.abs(time1 - time2) / 1000;
  //       if (res.data[x].is_new) {
  //         if(res?.data[x]?.applyTour_id?.payment_status != "Cancelled"){
  //           this.myNewArr.push({msg: `${res?.data[x]?.applyTour_id?.user_id?.firstname}  ${(res?.data[x]?.payment_status).toLowerCase()} amount ₹${res?.data[x]?.last_amount_paid} for ${res?.data[x]?.applyTour_id?.tour_id?.title} tour date ${res?.data[x]?.applyTour_id?.tourDate2}.`, id: res?.data[x]?.applyTour_id?._id, notification_id: res?.data[x]?._id})
  //         } else if (res?.data[x]?.applyTour_id?.payment_status == "Cancelled") {
  //           this.myNewArr.push({msg: `${res.data[x].applyTour_id.user_id.firstname} ${res.data[x].applyTour_id.payment_status.toLowerCase()} ${res.data[x].applyTour_id.tour_id.title} tour date ${res.data[x].applyTour_id.tourDate2}.`, id: res.data[x].applyTour_id._id, notification_id: res.data[x]._id})
  //         }
  //         this.messageArray.push(res.data[x]);
  //         this.changeColArr.push(this.changeCol);
  //         if (timeDiffinSec > 60) {
  //           let timeDiffinMin = timeDiffinSec / 60;
  //           if (timeDiffinMin > 60) {
  //             let timeDiffinHours = timeDiffinMin / 60;
  //             if (timeDiffinHours > 24) {
  //               let timeDiffinDays = timeDiffinHours / 30;
  //               if (timeDiffinDays > 30) {
  //               } else if (timeDiffinDays <= 30) {
  //                 if (Math.floor(timeDiffinDays) > 1) {
  //                   this.time.push(`${Math.floor(timeDiffinDays)}days`);
  //                 } else {
  //                   this.time.push(`${Math.floor(timeDiffinDays)}day`);
  //                 }
  //               }
  //             } else if (timeDiffinHours <= 24) {
  //               if (Math.floor(timeDiffinHours) > 1) {
  //                 this.time.push(`${Math.floor(timeDiffinHours)}hrs`);
  //               } else {
  //                 this.time.push(`${Math.floor(timeDiffinHours)}hr`);
  //               }
  //             }
  //           } else if (timeDiffinMin <= 60) {
  //             this.time.push(`${Math.floor(timeDiffinMin)}min`);
  //           }
  //         } else if (timeDiffinSec <= 60) {
  //           this.time.push(`${Math.floor(timeDiffinSec)}sec`);
  //         }
  //       } else {
  //         this.changeColArr.push(this.changeCol)
  //         this.clickedNotify.push(res.data[x])
  //         this.messageArray.splice(x, 1);
  //         this.time.splice(x, 1);
  //       }
  //     }
  //   })

  //   this.apiService.getNotificationCount().subscribe((res: any) => {
  //     this.notificationCount = res.message;
  //   })

  //   setTimeout(() => {
  //     // console.log(this.myNewArr)
  //   }, 1000)
  // }
  notificationList(page:number) {
    this.apiService.allNotification(page).subscribe((res: any) => {
      // console.log(res, "ressssssssssssssss!!!!!!!!!!!!!!!!!!!!")
      const time1: any = new Date();
      for (let x = 0; x < res.data.length; x++) {
        this.changeCol = res.data[x].is_new;
        const time2: any = new Date(res.data[x].created_at);
        let timeDiffinSec = Math.abs(time1 - time2) / 1000;
        if (res.data[x].is_new) {
          if (res?.data[x]?.applyTour_id?.payment_status != "Cancelled") {
            const user = res?.data[x]?.applyTour_id?.user_id || {};
            this.myNewArr.push({
              msg: `${user.firstname || 'Unknown'} ${(res?.data[x]?.payment_status || '').toLowerCase()} amount ₹${res?.data[x]?.last_amount_paid || 0} for ${res?.data[x]?.applyTour_id?.tour_id?.title} tour date ${res?.data[x]?.applyTour_id?.tourDate2}.`,
              id: res?.data[x]?.applyTour_id?._id,
              notification_id: res?.data[x]?._id
            });
          } else if (res?.data[x]?.applyTour_id?.payment_status == "Cancelled") {
            this.myNewArr.push({
              msg: `${res?.data[x]?.applyTour_id?.user_id?.firstname || 'Unknown'} ${res?.data[x].applyTour_id?.payment_status?.toLowerCase()} ${res?.data[x].applyTour_id?.tour_id?.title} tour date ${res.data[x].applyTour_id?.tourDate2}.`,
              id: res.data[x]?.applyTour_id?._id,
              notification_id: res.data[x]?._id
            });
          }
          this.messageArray.push(res.data[x]);
          this.changeColArr.push(this.changeCol);
          // ...time calculations
        } else {
          // ...handling for cases where is_new is false
        }
      }
    });
  
    this.apiService.getNotificationCount().subscribe((res: any) => {
      this.notificationCount = res.message;
    });
  
    setTimeout(() => {
      // console.log(this.myNewArr)
    }, 1000);
  }

  // getNotificationCount(){
  //   this.apiService.getNotificationCount().subscribe((res: any) => {
  //     this.notificationCount = res.message;
  //   });
  
  //   setTimeout(() => {
  //     // console.log(this.myNewArr)
  //   }, 1000);
  // }
  
  handleClick(elem: any) {
    this.apiService.seenNotification({ id: elem }).subscribe((res: any) => {
    })
  }

  toggleDropdownMenu() {
    // this.socket.emit('data-sending', { message: 1 })
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
      // this.socket.emit('dataSending', { message: 1 })
      // this.socket.on('sending', (event) => {
      //   this.message = `${event} booked a tour`;
      // })
    }
  }

  hideDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
      if (this.messageArray.length > 0) {
      }
    }
  }
}
