<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form >
                                    <div class="card-body">
                                         
                                          <div class="row" >
                                                <div class="form-group col-md-4" >
                                                      <label for="title">First Name<span
                                                                  class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                            
                                                            placeholder="Enter First Name" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Last Name<span class="required"></span></label>
                                                      <input type="text" class="form-control" 
                                                            placeholder="Enter Last Name">
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Mobile Number<span class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                             >
                                                     
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Email</label>
                                                      <input type="email" class="form-control" 
                                                            placeholder="Email"
                                                            
                                                            [readonly]="readOnly">
                                                      
                                                      
                                                            
                                                </div>
                                                
                                                

                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <!-- <label for="class_name">Admin Role <span
                                                                        class="required"></span></label>
                                                            <select class="form-control" formControlName="role">
                                                                  <option value="" selected>Select Role</option>
                                                                  <option value="0">Super Admin</option>
                                                                  <option value="1">Admin</option>
                                                            </select> -->

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <!-- <label for="class_name">Admin Role <span
                                                                        class="required"></span></label>
                                                            <select class="form-control" formControlName="role">
                                                                  <option value="" selected>Select Role</option>
                                                                  <option value="0">Super Admin</option>
                                                                  <option value="1">Admin</option>
                                                            </select> -->

                                                      </div>
                                                </div>

                                                <!-- <div class="card-body" style="margin-top: -42px;">
                                                      <div class="row">
                                                            <div class="col-md-4">

                                                                  <div class="custom-control custom-checkbox"
                                                                        style="margin-top: 18px; margin-left: -15px;">
                                                                        <input id="male" type="checkbox"
                                                                              class="custom-control-input"
                                                                              formControlName="status"
                                                                              (change)="addWebForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                              [checked]="addWebForm.value.status == 'active'" />
                                                                        <label class="custom-control-label"
                                                                              for="male">Status</label>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div> -->


                                          </div>

                                          <!-- <div *ngIf="addWebForm.value.role==='1'" class="row"
                                                style="border-top: 1px solid #565656;">

                                                <div class="col-md-3" style="margin-top: 25px;"
                                                      *ngFor="let web of userRoleList">

                                                      <div class="custom-control custom-checkbox">
                                                            <label>

                                                                  <input type="checkbox" [value]="web.value"
                                                                        (change)="web.check = web.check === true ? false : true"
                                                                        [checked]="web.check" />

                                                                  {{web.name}}

                                                            </label>


                                                      </div>
                                                </div>

                                          </div> -->

                                    </div>
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'"
                                                [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                          </app-button>
                                          <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/add-user-list']">Cancel</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>