<li class="nav-item dropdown">
  <a class="nav-link" data-toggle="dropdown" (click)="toggleDropdownMenu()">
    <i class="far fa-bell"></i>
    <span class="badge badge-warning navbar-badge">{{
      notificationCount
    }}</span>
  </a>
  <div
    #dropdownMenu
    class="dropdown-menu dropdown-menu-lg dropdown-menu-right my-dropdown-style"
  >
    <span class="dropdown-item dropdown-header"
      >{{ notificationCount }} Notifications</span
    >
    <div class="dropdown-divider"></div>
    <!-- <div
      *ngFor="let message of messageArray; let i = index"
    >
      <div class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle" *ngIf="message.payment_status == 'Cancelled'; else paid">
        <a
          [routerLink]="[
            '/user-order-history/particular-order/' + message.applyTour_id._id
          ]"
          class="dropdown-item p-0"
          (click)="handleClick(message._id)"
        >
          <b>{{ message.applyTour_id.user_id.firstname }}</b>&nbsp;<b>{{ message.payment_status }}</b>&nbsp;<b>{{ message.applyTour_id.tour_id.title }}</b> tour
          </a
        >
        <small style="margin: 2px 0px 0px 8px; color: grey;"><i>{{ time[i] }} ago</i></small>
      </div>
      <ng-template #paid>
        <div class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle">
          <a
            [routerLink]="[
              '/user-order-history/particular-order/' + message.applyTour_id._id
            ]"
            class="dropdown-item p-0"
            (click)="handleClick(message._id)"
          >
            <b>{{ message.applyTour_id.user_id.firstname }}</b> paid {{message.applyTour_id.payment_status | lowercase}} <b>&#8377;{{ message.last_amount_paid }}</b> for <b>{{ message.applyTour_id.tour_id.title }}</b> tour date {{message.applyTour_id.tourDate2}}
            </a
          >
          <small style="margin: 2px 0px 0px 8px; color: grey;"><i>{{ time[i] }} ago</i></small>
        </div>
      </ng-template>
    </div> -->
    <div class="sub-my-dropdown-style" [ngClass]="myNewArr.length > 0 ? '':'thisClassIsAdded'">
      <div *ngFor="let arr of dummyArr; let i = index">
        <div class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle" *ngIf="myNewArr[i]">
          <a
              [routerLink]="[
                '/user-order-history/particular-order/' + myNewArr[i]?.id
              ]"
              class="dropdown-item p-0"
              (click)="handleClick(myNewArr[i]?.notification_id)"
          >
            {{myNewArr[i]?.msg}}
            </a
          >
          <small style="margin: 2px 0px 0px 8px; color: grey;"><i>{{ time[i] }} ago</i></small>
        </div>
      </div>
    </div>

    <!-- <ng-template *ngIf="messageArray.length < 10">
      <div
      *ngFor="let message of clickedNotify; let i = index"
      class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle bg-secondary"
    >
      <a
        [routerLink]="[
          '/user-order-history/particular-order/' + message.applyTour_id._id
        ]"
        class="dropdown-item p-0"
      >
        <b>{{ dataUser[i] }}</b> booked a {{ tripTrekArr[i] | lowercase}} to
        <b>{{ dataTour[i] }}</b> for
        <b>&#8377;{{ message.last_amount_paid }}</b></a
      >
      <span style="margin-left: 8px">{{ time[i] }}h</span>
    </div>
    </ng-template> -->
    <!-- <a href="#" class="dropdown-item">
      <i class="fas fa-envelope mr-2"></i> 4 new messages
      <span class="float-right text-muted text-sm">3 mins</span>
    </a> -->
    <div class="dropdown-divider"></div>
    <!-- <a href="#" class="dropdown-item">
      <i class="fas fa-users mr-2"></i> 8 friend requests
      <span class="float-right text-muted text-sm">12 hours</span>
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item">
      <i class="fas fa-file mr-2"></i> 3 new reports
      <span class="float-right text-muted text-sm">2 days</span>
    </a> -->
    <div class="dropdown-divider"></div>
    <a routerLink="/notifications" class="dropdown-item dropdown-footer"
      >See All Notifications</a
    >
  </div>
</li>

<ng-template #notification>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> 4 new messages
    <span class="float-right text-muted text-sm">3 mins</span>
  </a>
  <div class="dropdown-divider"></div>
</ng-template>