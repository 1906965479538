import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-menu-master-list',
  templateUrl: './add-menu-master-list.component.html',
  styleUrls: ['./add-menu-master-list.component.scss']
})
export class AddMenuMasterListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  allMenu: any;
  render: number;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  constructor(private apiService: ApiService, 
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.render = 1;
    this.showMenu();
  }

  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showMenu() {
    const that = this;
    this.apiService.allMenuMatser().subscribe((resp: any) => {
      that.allMenu = resp.data;
      console.log(that.allMenu)
      if (this.render === 1) {
        this.dtTrigger.next();
      }
      this.render = 0;
    }); 
  }
  
  async menulist(id, status) {
    console.log(status,'hiiiiiiiiiii');
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes', 
    }).then((result) => {
      if (result.isConfirmed) {
        let getStatus = {};
        getStatus =
          status === 'active' ? { status: 'inactive' } : { status: 'active' };
          // console.log(getStatus)
          // let dataForForm = {
          //   id: getStatus,
          // };
          //return
          this.apiService
          .statusMenuMaster(getStatus, id)
          .subscribe((result: any) => {
            if (result.success) {
              Swal.fire({
                text: result.message,
                icon: 'success',
              });
              //this.toastr.success(result.message);
              this.showMenu();
            } else {
              Swal.fire({
                text: result.message,
                icon: 'error',
              });
              //this.toastr.error(result.message);
            }
          });
      }
    });
  }

}
