import {  Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { environment } from '../../../environments/environment';
import { log } from 'util';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-user-tour-details',
  templateUrl: './user-tour-details.component.html',
  styleUrls: ['./user-tour-details.component.scss']
})
export class UserTourDetailsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  [x: string]: any;
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;


  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.apiHost = environment.API_HOST;
    this.route.paramMap.subscribe(params => {
      this.tourId = params.get('_id');
      // console.log(this.tourId); 
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.UserReport();
    // console.log(this.tourId);
   
  }
 
  UserReport() {
    // const userId = this.tourId
    //console.log(userId, "nihsdfhs");
    this.apiService.getallTransactionBYuser(this.tourId).subscribe((res: any) => {
      console.log(res, 'invoiceeeee');
      this.data = res.data[0]
      console.log(this.data,">>>>>");
      
      this.allData = res.data
      console.log(this.allData)
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      //console.log(this.myTour, 'myTour');
      // if (res.success) {
      //   this.allOrders = res.getData;
      //   console.log(this.tourId, 'heloooooo');
      // } else {
      //   Swal.fire({
      //     icon: 'error',
      //     title: res.message,
      //   });
      // }
    });
  }

  openLink(invoice) {
    console.log(invoice)
    //return
    // Replace 'https://example.com/image.jpg' with the actual URL of the image or website you want to open
    window.open(this.apiHost + invoice, '_blank');
}

ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}
}
