<div class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12">
                  <div class="card card-primary">
                      <!-- form start -->
                      <form [formGroup]="addBoardingForm" (ngSubmit)="addPoint()"  >
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-md-4">
                                      <div class="form-group">
                                          <label for="class_name">Boarding Point <span class="required"></span></label>
                                          <input type="text" class="form-control" formControlName="boarding"  placeholder="Boarding Point"
                                          [ngClass]="{ 'is-invalid': submitted && f.boarding.errors }">
                                          <div *ngIf="submitted && f.boarding.errors"
                                                            class="invalid-feedback"></div>
                                                      <div *ngIf="f.boarding.errors?.minlength"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            First name must be at least 3 characters</div>
                                      </div>
                                  </div>
                                
                              </div>
                            </div>
                     <div class="card-body" style="margin-top: -42px;">
                        <div class="row">
                              <div class="col-md-4">
                                      
                                <div class="custom-control custom-checkbox">
                                    <input id="male" type="checkbox" class="custom-control-input"
                                    formControlName="status"  (change)="addBoardingForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                    [checked]="addBoardingForm.value.status == 'active'"/>
                               <label class="custom-control-label" for="male">Status</label> 
                                </div>
                       </div>
                             </div>
                    </div>
                    

                             
                         
                          <!-- /.card-body -->
                          <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                            [disabled]="isAuthDisabled"></app-button>
                          <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                            [routerLink]="['/boarding-point-list']">Cancel</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>

  