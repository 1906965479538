import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-make-trip-pricing-list',
  templateUrl: './make-trip-pricing-list.component.html',
  styleUrls: ['./make-trip-pricing-list.component.scss'],
})
export class MakeTripPricingListComponent implements OnInit {
  addPricingForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  public isAuthLoading = false;
  public isAuthDisabled = false;
  isShown: any = [];
  id: any;
  object: any;
  allData: any;
  allDates: any;
  pck: any;
  pck_strike: any;
  solo: any = [];
  share: any = [];
  rooms: any = [];
  boarding_point: any;
  allBoardData: any;
  allcoupon: any;
  allPackData: any;
  alldevData: any;
  addboard: any;
  board: any;
  tripId: any;
  allPricedata: any;
  dateSchedulebatch: any
  tourtype:any;
  boarding_point_type: any;
  private isDtInitialized: boolean = false;
  bpPrice: any;
  //comment: any;
  public strikingPrice: Array<any> = []
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  pricingid: any;
  AllTourData: any;

  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage, justify',
     /* uploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

      // Configure your file manager integration. This example uses CKFinder 3 for PHP.
      filebrowserBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
      filebrowserImageBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
      filebrowserUploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
      filebrowserImageUploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images'*/
  };
  batchdata: any;
  isCallingAddPrice: any;
  isCallingAddBatch: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  @ViewChild('importFestImg', { static: false }) importFestImg: ElementRef
  @ViewChild('importFestImgTwo', { static: false })
  
  importFestImgTwo: ElementRef;

  ngOnInit(): void {
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripfive(this.object);
    }
   
    this.getdetail(this.object);
    this.addPricingForm = new FormGroup({
      basicAmount: new FormControl(),
      strikingAmount: new FormControl(),
      bookPrice: new FormControl(),
      gst: new FormControl(),
      tcs: new FormControl(),
      dateScheduletrip: new FormControl(),
      infant_price: new FormControl(),
      child_price: new FormControl(),
      boarding_point_price: new FormControl(),
      tourDetailsNote:new FormControl(),
      status: new FormControl(),
    });
    this.addPricingForm = this.formBuilder.group({
      boarding_point_price: ['', [Validators.required]],
      tourDetailsNote: ['', []],
      bookPrice: ['0', [Validators.required]],
      gst: ['inclusive'],
      tcs: [''],
      infant_price: ['0'],
      child_price: ['0'],
      dateScheduletrip: [''],
      status: ['', []],
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    //this.coupon();
    this.addPricingForm.get('status').setValue('active');
    this.addPricingForm.get('gst').setValue('inclusive')


    
  }

  isCustomTourType = false;

  setTourType(tourType: string): void {
    this.isCustomTourType = tourType === 'customized';
    console.log(this.isCustomTourType);
    
}

 

  onTCSChange(event) {
    // Check if the "Inclusive" option for TCS is selected
    if (event.target.value === 'inclusive') {
      // If "Inclusive" option for TCS is selected, set the value of GST radio button to "Inclusive"
      this.addPricingForm.get('gst').setValue('inclusive');
    }else if(event.target.value === 'exclusive'){
      this.addPricingForm.get('gst').setValue('exclusive');
    }
  }

  onGSTChange(event) {
    // Check if the "Inclusive" option for TCS is selected
    if (event.target.value === 'inclusive') {
      // If "Inclusive" option for TCS is selected, set the value of GST radio button to "Inclusive"
      this.addPricingForm.get('tcs').setValue('inclusive');
    }else if(event.target.value === 'exclusive'){
      this.addPricingForm.get('tcs').setValue('exclusive');
    }
  }

  // coupon() {
  //   this.apiService.activeCoupanList().subscribe((res: any) => {
  //     console.log(res);
  //     this.allcoupon = res.data;
  //   });
  // }

  // onFileChange(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.addPricingForm.get('pricingImage1').setValue(file);
  //     this.importFestImg.nativeElement.innerText = event.target.files[0].name;
  //   }
  // }

  // handleTcsChange(event: any) {
 
  //   if (event.target.value === 'inclusive') {
  //    console.log(event.target.value);
  //    this.addPricingForm.patchValue({
  //     gst: event.target.value,
  //     tcs: event.target.value
  //   });
  //   }
  

  //   if (event.target.value === 'exclusive') {
  //     console.log(event.target.value);
  //     this.addPricingForm.patchValue({
  //       gst: event.target.value,
  //       tcs: event.target.value
  //     });
  //    }

  // }

  onFileChangeTwo(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addPricingForm.get('pricingImage2').setValue(file);
      this.importFestImgTwo.nativeElement.innerText =
        event.target.files[0].name;
    }
  }

  onChange(selectedValue: any) {
    // Handle the logic based on the selected value
    // console.log('Selected value:', selectedValue);
    let dataForForm = {
      batch_id: selectedValue,
    };
    this.spinner.show();
    this.apiService.getbatchlistBy(dataForForm, this.object).subscribe((result: any) => {
      
      // console.log(result);
      //console.log(result.getData.summary, 'hiiiiiiiiiiiiii');
      if (result.success) {
        this.spinner.hide();
        let TripPricingData: any = result.select_batch
        console.log(TripPricingData);
        
        let TripPricingData1: any = this.batchdata

        // console.log(TripPricingData,">>>>>>>"); 
        //const selectedAdventureTypeIds = TripPricingData.adventure_type.map(item => item._id);
        //this.allPricedata = result.getData.boarding_point;
        // console.log(this.allPricedata )
        this.tripId = TripPricingData._id;
        this.tourtype = TripPricingData.tourtype
        this.addPricingForm.patchValue({
          bookPrice: TripPricingData.bookPrice,
          strikingAmount: TripPricingData.strikingAmount,
          basicAmount: TripPricingData.basicAmount,
          gst: TripPricingData.gst,
          tcs: TripPricingData.tcs,
          child_price: TripPricingData?.child_price,
          infant_price:TripPricingData?.infant_price,
          dateScheduletrip: TripPricingData._id,
          tourDetailsNote:TripPricingData.tourDetailsNote,
        });

        console.log(this.addPricingForm);
        
       
        

        if (TripPricingData.packageType_price?.length) {
          TripPricingData.packageType_price = JSON.parse(TripPricingData.packageType_price);
          console.log(TripPricingData.packageType_price, 'hiiiiiiiiii');
          this.spinner.show();
          setTimeout(() => {
            TripPricingData.packageType_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packType].setValue(key.packPrice);
            });
            this.spinner.hide();
          }, 2000);
        }

        // if (TripPricingData.tourDetailsNote?.length) {
        //   TripPricingData.tourDetailsNote = JSON.parse(TripPricingData?.tourDetailsNote);
        //   console.log(TripPricingData.tourDetailsNote, 'hiiiiiiiiii');
        //   this.spinner.show();
        //   setTimeout(() => {
        //     TripPricingData.packageType_price.forEach((key, i) => {
        //       this.addPricingForm.controls[key.tourDetailsNote].setValue(key.tourDetailsNote);
        //     });
        //     this.spinner.hide();
        //   }, 2000);
        // }
        

        //! striking package price
        if(TripPricingData.packageType_striking_price?.length){
          TripPricingData.packageType_striking_price = JSON.parse(TripPricingData.packageType_striking_price);
            console.log(TripPricingData.packageType_striking_price, 'hiiiiiiiiii');
          this.spinner.show();
          setTimeout(() => {
            TripPricingData.packageType_striking_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packTypestriker].setValue(key.packPricestriker);
            });
            this.spinner.hide();
          }, 2000);
        }

        if (TripPricingData.roomSharing_price?.length) {
          TripPricingData.roomSharing_price = JSON.parse(TripPricingData.roomSharing_price);
          // console.log(TripPricingData.roomSharing_price, 'hiiiiiiiiii');
          this.spinner.show();
          setTimeout(() => {
            TripPricingData.roomSharing_price.forEach((key, i) => {
              this.addPricingForm.controls[key.roomType].setValue(key.roomPrice);
            });
            this.spinner.hide();
          }, 2000);
        }

        if (TripPricingData.boarding_point_price?.length) {
          TripPricingData.boarding_point_price = JSON.parse(TripPricingData.boarding_point_price
            );
          // console.log(TripPricingData.boarding_point_price, 'hiiiiiiiiii');
          // const isBpPriceExist = TripPricingData.boarding_point_price.map(bpType => TripPricingData.boarding_point.includes(bpType.bp));
          // console.log(isBpPriceExist);
          // if (isBpPriceExist.includes(true)) {
            this.spinner.show();
            setTimeout(() => {
            //  this.isShown = [];
            //  console.log(this.addPricingForm.value);
             TripPricingData.boarding_point_price.forEach((key, i) => {
               // this.addPricingForm.controls[key.bp].setValue(key.bp_price);
               // console.log(key, 'heelo');
               if (TripPricingData.boarding_point.includes(key.bp)) {
                 if (key.bp_price.length === 1) {
                   this.addPricingForm.get('boardplace' + i).setValue(key.bp_price[0].singleBoardPrice);
                   this.addPricingForm.get(key.bp).setValue('single'+i);
                  //  this.isShown.push('single' + i);
                 } else {
                   this.addPricingForm.get('Sleeper' + i).setValue(key.bp_price[0]['Sleeper']);
                   this.addPricingForm.get('3AC' + i).setValue(key.bp_price[1]['3AC']);
                   this.addPricingForm.get('2AC' + i).setValue(key.bp_price[2]['2AC']);
                   this.addPricingForm.get('1AC' + i).setValue(key.bp_price[3]['1AC']);
                   this.addPricingForm.get('Flight' + i).setValue(key.bp_price[4]['Flight']);
                   this.addPricingForm.get(key.bp).setValue('multi'+i);
                   
                  if (this.isShown.includes('single' + i)) {
                    const index = this.isShown.indexOf('single' + i);
                    this.isShown.splice(index, 1);
                    this.isShown.push('multi' + i);
                  }
                 }
               }
             });
            //  console.log(this.isShown);
             this.spinner.hide();
            }, 3000);
          
          // }
         if (TripPricingData.status == 'active') {
           this.addPricingForm.get('status').setValue('active');
         }
        }
      }

      
    });
}

  edittripfive(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.spinner.show();
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      // console.log(result.getData);
      this.pricingid = result.getData.bookPrice;
      //console.log(result.getData.summary, 'hiiiiiiiiiiiiii');
      if (result.success) {
        this.AllTourData = result.getData
        this.spinner.hide();
        let TripData: any = result.getData.select_batch[0]
        console.log(TripData);
        
        //const selectedAdventureTypeIds = TripData.adventure_type.map(item => item._id);
        this.allPricedata = result.getData.boarding_point;
        //console.log(this.allPricedata )
        this.tripId = TripData._id;
        this.tourtype = TripData.tourtype
        // console.log(TripData);
        // TripData.pricingImage1[0].name = TripData.pricingImage1[0].orgName;
        // TripData.pricingImage2[0].name = TripData.pricingImage2[0].orgName;
        this.addPricingForm.patchValue({
          bookPrice: TripData.bookPrice,
          strikingAmount: TripData.strikingAmount,
          basicAmount: TripData.basicAmount,
          gst: TripData.gst,
          tcs: TripData.tcs,
          child_price: TripData.child_price,
          infant_price:TripData.infant_price,
          dateScheduletrip: TripData._id,
          tourDetailsNote:TripData.tourDetailsNote
          //coupon_code: TripData.coupon_code?._id,
          // pricingImage1: TripData.pricingImage1[0],
          // pricingImage2: TripData.pricingImage2[0],
        });

        console.log(this.addPricingForm);
        

        
        

        // if (this.allData?.domesticorInternational === 'international') {
        //   this.addPricingForm.patchValue({
        //     tcs: TripData.gst
        //   });
        // }

        if (TripData.packageType_price?.length) {
          TripData.packageType_price = JSON.parse(TripData.packageType_price);
          //console.log(TripData.packageType_price, 'hiiiiiiiiii');
          setTimeout(() => {
            TripData.packageType_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packType].setValue(key.packPrice);
            });
          }, 1000);
        }

        // ! striking package price
        if(TripData.packageType_striking_price?.length){
          TripData.packageType_striking_price = JSON.parse(TripData.packageType_striking_price);
          setTimeout(() => {
            TripData.packageType_striking_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packTypestriker].setValue(key.packPricestriker);
            });
          }, 1000);
        }

        // if (TripData.soloType_price?.length) {
        //   TripData.soloType_price = JSON.parse(TripData.soloType_price);
        //   console.log(TripData.soloType_price, 'hiiiiiiiiii');
        //   setTimeout(() => {
        //     TripData.soloType_price.forEach((key, i) => {
        //       this.addPricingForm.controls[key.soloType + '_solo'].setValue(key.soloPrice);
        //     });
        //   }, 1000);
        // }

        // if (TripData.shareType_price?.length) {
        //   TripData.shareType_price = JSON.parse(TripData.shareType_price);
        //   console.log(TripData.shareType_price, 'hiiiiiiiiii');
        //   setTimeout(() => {
        //     TripData.shareType_price.forEach((key, i) => {
        //       this.addPricingForm.controls[key.sharedType + '_share'].setValue(key.sharedPrice);
        //     });
        //   }, 1000);
        // }

        if (TripData.roomSharing_price?.length) {
          TripData.roomSharing_price = JSON.parse(TripData.roomSharing_price);
          // console.log(TripData.roomSharing_price, 'hiiiiiiiiii');
          setTimeout(() => {
            TripData.roomSharing_price.forEach((key, i) => {
              this.addPricingForm.controls[key.roomType].setValue(key.roomPrice);
            });
          }, 1000);
        }

        if (TripData.boarding_point_price?.length) {
          TripData.boarding_point_price = JSON.parse(TripData.boarding_point_price
            );
          // console.log(TripData.boarding_point_price, 'hiiiiiiiiii');
          // const isBpPriceExist = TripData.boarding_point_price.map(bpType => TripData.boarding_point.includes(bpType.bp));
          // console.log(isBpPriceExist);
          // if (isBpPriceExist.includes(true)) {
            setTimeout(() => {
            //  this.isShown = [];
            //  console.log(this.addPricingForm.value);
             TripData.boarding_point_price.forEach((key, i) => {
               // this.addPricingForm.controls[key.bp].setValue(key.bp_price);
               // console.log(key, 'heelo');
               if (TripData.boarding_point.includes(key.bp)) {
                 if (key.bp_price.length === 1) {
                   this.addPricingForm.get('boardplace' + i).setValue(key.bp_price[0].singleBoardPrice);
                   this.addPricingForm.get(key.bp).setValue('single'+i);
                  //  this.isShown.push('single' + i);
                 } else {
                   this.addPricingForm.get('Sleeper' + i).setValue(key.bp_price[0]['Sleeper']);
                   this.addPricingForm.get('3AC' + i).setValue(key.bp_price[1]['3AC']);
                   this.addPricingForm.get('2AC' + i).setValue(key.bp_price[2]['2AC']);
                   this.addPricingForm.get('1AC' + i).setValue(key.bp_price[3]['1AC']);
                   this.addPricingForm.get('Flight' + i).setValue(key.bp_price[4]['Flight']);
                   this.addPricingForm.get(key.bp).setValue('multi'+i);
                  if (this.isShown.includes('single' + i)) {
                    const index = this.isShown.indexOf('single' + i);
                    this.isShown.splice(index, 1);
                    this.isShown.push('multi' + i);
                  }
                 }
               }
             });
            //  console.log(this.isShown);
            }, 2000);
          // }
         if (TripData.status == 'active') {
           this.addPricingForm.get('status').setValue('active');
         }
        //  setTimeout(() => {
        //    this.importFestImg.nativeElement.innerText =
        //      TripData.pricingImage1[0]?.orgName;
        //  }, 1000);
        //  setTimeout(() => {
        //    this.importFestImgTwo.nativeElement.innerText =
        //      TripData.pricingImage2[0]?.orgName;
        //  }, 1000);
        }
      }


      console.log(this.addPricingForm.value);
    });
  }

  getdetail(id) {
    let dataForForm = {
      id: id,
    };
    this.spinner.show()
    this.apiService.getTourId(dataForForm).subscribe((result: any) => {
      // console.log(result.getData.select_batch[0], 'result');
    this.spinner.hide()
      //this.allData = result.getData;
      this.batchdata = result.getData.select_batch[0]
      console.log(this.batchdata);
      
      this.allDates = result.Datedata
  
      
      console.log(result.getData.tourtype);
      this.tourtype = result.getData.tourtype
      this.setTourType(this.tourtype)      
      this.allDates.start_date = JSON.parse(this.allDates.start_date);
      this.allDates.end_date = JSON.parse(this.allDates.end_date);
      this.allDates.dateArr = [];
      for (let i = 0; i < this.allDates.start_date.length; i++) {
       if ((new Date()).getTime() < (new Date(this.allDates.start_date[i])).getTime())
        this.allDates.dateArr.push({
          startDate: this.allDates.start_date[i],
          endDate: this.allDates.end_date[i],
        });
      }
      //console.log( this.allDates.dateArr)
      this.allDates.dateArr.sort(function(a: any,b: any){
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });
      // if (this.allDates.dateArr.length === 0) {
      //     Swal.fire({
      //       title: 'No schedule available for this tour',
      //       text: "Please contact us for more details",
      //       icon: 'error',
      //       confirmButtonColor: '#3085d6',
      //       cancelButtonColor: '#d33',
      //       confirmButtonText: 'Ok',
      //     });
      // }
      //console.log(this.allDates)
      this.allData = result.getData;
      // console.log(this.allData.select_batch[0]._id)
      // for(let datef of this.allData.select_batch){
      //     console.log(datef)
      // }
      //return
      this.addboard = result.getData;
      console.log(this.addboard);
      
      //console.log(this.addboard);
      // this.pck = this.addboard.packageType.filter(pack => pack !== 'Solo bike' && pack !== 'Shared bike');
      // this.solo = this.addboard.soloBikeType;
      // this.share = this.addboard.sharedBikeType;
      if(this.addboard.tourtype == 'normal'){
        this.pck = this.addboard.packageType;
        // console.log(this.addboard.packageType);
        this.addboard.packageType.forEach((elem)=> {
          this.strikingPrice.push(elem + " strike")
        })
        console.log(this.strikingPrice);
        this.pck_strike = this.strikingPrice;
        // this.solo = this.addboard.soloBikeType;
        // this.share = this.addboard.sharedBikeType;
        this.rooms = this.addboard.roomSharing;
        console.log(this.rooms);
        
        this.board = this.allData.boarding_point;
        //console.log(this.board, 'hiiiiiiiiii');
        //console.log(this.solo, this.share, 'hellllll', this.pck, this.addPricingForm);
        this.pck.forEach((packData, index) => {
          //console.log(packData);
          this.addPricingForm.addControl(packData, new FormControl(''));
        });
        // console.log(this.pck);
        
        this.pck_strike.forEach((packStrikeData, index) => {
          console.log(packStrikeData);
          this.addPricingForm.addControl(packStrikeData, new FormControl(''));
        });
        console.log(this.pck_strike, this.addPricingForm);
        if (this.solo.length) {
          this.solo.forEach((soloData, index) => {
            this.addPricingForm.addControl(soloData + '_solo', new FormControl(''));
          });
        }
        if (this.share.length) {
          this.share.forEach((shareData, index) => {
            this.addPricingForm.addControl(shareData + '_share', new FormControl(''));
          });
        }
        this.rooms.forEach((roomData, index) => {
          this.addPricingForm.addControl(roomData, new FormControl(''));
        }); 
        this.board.forEach((boardData, index) => {
          this.addPricingForm.addControl(
            boardData,
            new FormControl('single' + index)
          );
          this.addPricingForm.addControl(
            'boardplace' + index,
            new FormControl('')
          );
          this.addPricingForm.addControl('Sleeper' + index, new FormControl(''));
          this.addPricingForm.addControl('3AC' + index, new FormControl(''));
          this.addPricingForm.addControl('2AC' + index, new FormControl(''));
          this.addPricingForm.addControl('1AC' + index, new FormControl(''));
          this.addPricingForm.addControl('Flight' + index, new FormControl(''));
          this.isShown.push('single' + index);
          // this.isShown.splice(index, 1);
          //this.isShown.push('multi' + index);
        });
      }else{
        this.pck = this.addboard.packageType;
        // console.log(this.addboard.packageType);
        this.addboard.packageType.forEach((elem)=> {
          this.strikingPrice.push(elem + " strike")
        })
        // console.log(this.strikingPrice);
        this.pck_strike = this.strikingPrice;
        // this.solo = this.addboard.soloBikeType;
        // this.share = this.addboard.sharedBikeType;
        this.rooms = this.addboard.roomSharing;
        this.board = this.allData.boarding_point;
        console.log(this.rooms, 'hiiiiiiiiii');
        //console.log(this.solo, this.share, 'hellllll', this.pck, this.addPricingForm);
        this.pck.forEach((packData, index) => {
          //console.log(packData);
          this.addPricingForm.addControl(packData, new FormControl('0'));
        });
        // console.log(this.pck);
        
        this.pck_strike.forEach((packStrikeData, index) => {
          console.log(packStrikeData);
          this.addPricingForm.addControl(packStrikeData, new FormControl('0'));
        });
        console.log(this.pck_strike, this.addPricingForm);
        if (this.solo.length) {
          this.solo.forEach((soloData, index) => {
            this.addPricingForm.addControl(soloData + '_solo', new FormControl('0'));
          });
        }
        if (this.share.length) {
          this.share.forEach((shareData, index) => {
            this.addPricingForm.addControl(shareData + '_share', new FormControl('0'));
          });
        }
        this.rooms.forEach((roomData, index) => {
          this.addPricingForm.addControl(roomData, new FormControl('0'));
        });
        this.board.forEach((boardData, index) => {
          this.addPricingForm.addControl(
            boardData,
            new FormControl('single' + index)
          );
          this.addPricingForm.addControl(
            'boardplace' + index,
            new FormControl('0')
          );
          this.addPricingForm.addControl('Sleeper' + index, new FormControl('0'));
          this.addPricingForm.addControl('3AC' + index, new FormControl('0'));
          this.addPricingForm.addControl('2AC' + index, new FormControl('0'));
          this.addPricingForm.addControl('1AC' + index, new FormControl('0'));
          this.addPricingForm.addControl('Flight' + index, new FormControl('0'));
          this.isShown.push('single' + index);
          // this.isShown.splice(index, 1);
          //this.isShown.push('multi' + index);
        });

       
      }

      console.log(this.addPricingForm.value);


     
    });
  }

  toggleShow(getVal: string, idx: string) {
    const toggleVal = getVal === 'multi' + idx ? 'single' + idx : 'multi' + idx;
    const index = this.isShown.indexOf(toggleVal);
    if (index !== -1) {
      this.isShown[index] = getVal;
    } else {
      console.error(getVal);
    }
  }

  addPrice(id) {
    if (!this.isCallingAddPrice) {
      this.isCallingAddBatch = true;
  
      if (this.addboard?.tourtype === 'normal') {
        if (!this.addPricingForm.value.dateScheduletrip) {
          Swal.fire({
            text: 'Please select a batch',
            icon: 'warning',
          });
          return false;
        }
  
        if (!this.addPricingForm.value.bookPrice) {
          Swal.fire({
            text: 'Please enter the booking amount',
            icon: 'warning',
          });
          return false;
        }
      }
  
      if (this.addPricingForm.status === 'active') {
        this.addPricingForm.get('status').setValue('active');
      }
  
      for (let i = 0; i < this.allPackData?.length; i++) {
        if (!this.addPricingForm.value.packType) {
          Swal.fire({
            text: 'Please enter all package prices',
            icon: 'warning',
          });
          return false;
        }
      }
  
      // Collecting data for API call
      let packagetData = [];
      let packagetStrikeData = [];
      let allSoloData = [];
      let allSharedData = [];
      let roomgetData = [];
      let boardingPointData = [];
  
      this.pck.forEach(packData => {
        packagetData.push({
          packType: packData,
          packPrice: this.addPricingForm.value[packData],
        });
      });
  
      this.pck_strike.forEach(packStrikeData => {
        packagetStrikeData.push({
          packTypestriker: packStrikeData,
          packPricestriker: this.addPricingForm.value[packStrikeData],
        });
      });
  
      if (this.solo.length) {
        this.solo.forEach(soloData => {
          allSoloData.push({
            soloType: soloData,
            soloPrice: this.addPricingForm.value[`${soloData}_solo`],
          });
        });
      }
  
      if (this.share.length) {
        this.share.forEach(shareData => {
          allSharedData.push({
            sharedType: shareData,
            sharedPrice: this.addPricingForm.value[`${shareData}_share`],
          });
        });
      }
  
      this.rooms.forEach(roomData => {
        roomgetData.push({
          roomType: roomData,
          roomPrice: this.addPricingForm.value[roomData],
        });
      });
  
      const roomDataWithTraveller = roomgetData.filter(item =>
        item.roomPrice && !isNaN(item.roomPrice) && Number(item.roomPrice) > 0
      );
  
      this.board.forEach((boardData, index) => {
        let bpPrice = [];
        if (this.addPricingForm.value[boardData] === `multi${index}`) {
          bpPrice = [
            { Sleeper: this.addPricingForm.value[`Sleeper${index}`] },
            { '3AC': this.addPricingForm.value[`3AC${index}`] },
            { '2AC': this.addPricingForm.value[`2AC${index}`] },
            { '1AC': this.addPricingForm.value[`1AC${index}`] },
            { Flight: this.addPricingForm.value[`Flight${index}`] },
          ];
        } else {
          bpPrice = [{ singleBoardPrice: this.addPricingForm.value[`boardplace${index}`] }];
        }
  
        boardingPointData.push({
          bp: boardData,
          bp_type: this.addPricingForm.value[boardData].slice(0, -1),
          bp_price: bpPrice,
        });
      });
  
      this.dateSchedulebatch = this.dateSchedule?.nativeElement?.value;
      this.addPricingForm.value.dateScheduletrip = this.dateSchedulebatch;
  
      if (this.addboard?.tourtype === 'customized') {
        this.dateSchedulebatch = this.allData.select_batch[0]._id;
        this.addPricingForm.get('bookPrice').setValue(0);
        this.addPricingForm.get('infant_price').setValue(0);
        this.addPricingForm.get('child_price').setValue(0);
      }
  
      // Confirmation pop-up
      Swal.fire({
        text: 'Do you want to submit this batch?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Add More Batch',
      }).then((result) => {
        if (result.isConfirmed) {
          // If "Yes" is clicked, submit via API and redirect
          this.submitBatchAPI(id, true, boardingPointData, packagetData, packagetStrikeData, roomgetData);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // If "Add More Batch" is clicked, submit via API and stay on the page
          this.submitBatchAPI(id, false, boardingPointData, packagetData, packagetStrikeData, roomgetData);
        }
      });
    }
  }
  
  submitBatchAPI(id, redirectToTripList, boardingPointData, packagetData, packagetStrikeData, roomgetData) {
    let formData = new FormData();
    formData.append('bookPrice', this.addPricingForm.get('bookPrice').value);
    formData.append('boarding_point_price', JSON.stringify(boardingPointData));
    formData.append('packageType_price', JSON.stringify(packagetData));
    formData.append('packageType_striking_price', JSON.stringify(packagetStrikeData));
    formData.append('roomSharing_price', JSON.stringify(roomgetData));
    formData.append('status', this.addPricingForm.get('status').value);
    formData.append('infant_price', this.addPricingForm.get('infant_price').value);
    formData.append('child_price', this.addPricingForm.get('child_price').value);
    formData.append('gst', this.addPricingForm.get('gst').value);
    formData.append('tourDetailsNote', this.addPricingForm.get('tourDetailsNote').value);
    formData.append('tcs', this.addPricingForm.get('tcs').value);
    formData.append('dateScheduleTrip', this.dateSchedulebatch);
    formData.append('isTourCreatedCompletely', '1');
  
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
  
    this.apiService.updateTourFive(formData, this.object, this.dateSchedulebatch).subscribe((res: any) => {
      this.isAuthLoading = false;
      this.isAuthDisabled = false;
  
      if (res.success) {
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        if (redirectToTripList) {
          this.router.navigateByUrl('/make-trip-list');
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }
  

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }


  addBatch(id) {
    if (!this.isCallingAddBatch) {
      this.isCallingAddPrice = true;
  
      // Perform all the checks and validations first
      if (this.addboard?.tourtype == 'normal') {
  
        if (
          this.addPricingForm.value.dateScheduletrip == undefined ||
          this.addPricingForm.value.dateScheduletrip == ''
        ) {
          Swal.fire({
            text: 'Please select a Batch',
            icon: 'warning',
          });
          return false;
        }
  
        if (
          this.addPricingForm.value.bookPrice == undefined ||
          this.addPricingForm.value.bookPrice == ''
        ) {
          Swal.fire({
            text: 'Please enter booking amount',
            icon: 'warning',
          });
          return false;
        }
      }
  
      // Validate the packType
      for (let i = 0; i < this.allPackData?.length; i++) {
        if (this.addPricingForm.value.packType == '' ||
          this.addPricingForm.value.packType == undefined
        ) {
          Swal.fire({
            text: 'Please enter all package prices',
            icon: 'warning',
          });
          return false;
        }
      }
  
      // Prepare the request data
      let packagetData = [];
      let packagetStrikeData = [];
      let allSoloData = [];
      let allSharedData = [];
      let roomgetData = [];
      let boardingPointData = [];
  
      this.pck.forEach((packData, index) => {
        packagetData.push({
          packType: packData,
          packPrice: this.addPricingForm.value[packData],
        });
      });
  
      const packageDataWithTraveller = packagetData.filter(item => item.packPrice !== undefined && item.packPrice !== '');
  
      this.pck_strike.forEach((packStrikeData, index) => {
        packagetStrikeData.push({
          packTypestriker: packStrikeData,
          packPricestriker: this.addPricingForm.value[packStrikeData],
        })
      });
  
      if (this.solo.length) {
        this.solo.forEach((soloData, index) => {
          allSoloData.push({
            soloType: soloData,
            soloPrice: this.addPricingForm.value[soloData + '_solo'],
          });
        });
      }
  
      if (this.share.length) {
        this.share.forEach((shareData, index) => {
          allSharedData.push({
            sharedType: shareData,
            sharedPrice: this.addPricingForm.value[shareData + '_share'],
          });
        });
      }
  
      this.rooms.forEach((roomData, index) => {
        roomgetData.push({
          roomType: roomData,
          roomPrice: this.addPricingForm.value[roomData],
        });
      });
  
      const roomDataWithTraveller = roomgetData.filter(item =>
        item.roomPrice !== undefined && item.roomPrice !== '' && !isNaN(item.roomPrice) && Number(item.roomPrice) > 0
      );
  
      this.board.forEach((boardData, index) => {
        let bpPrice = [];
        if (this.addPricingForm.value[boardData] === `multi${index}`) {
          bpPrice = [
            { Sleeper: this.addPricingForm.value[`Sleeper${index}`] },
            { '3AC': this.addPricingForm.value[`3AC${index}`] },
            { '2AC': this.addPricingForm.value[`2AC${index}`] },
            { '1AC': this.addPricingForm.value[`1AC${index}`] },
            { Flight: this.addPricingForm.value[`Flight${index}`] },
          ];
        } else {
          bpPrice = [
            { singleBoardPrice: this.addPricingForm.value[`boardplace${index}`] },
          ];
        }
  
        boardingPointData.push({
          bp: boardData,
          bp_type: this.addPricingForm.value[boardData].slice(0, -1),
          bp_price: bpPrice,
        });
      });
  
      // Set batch date for customized tour type
      this.dateSchedulebatch = this.dateSchedule?.nativeElement?.value;
      this.addPricingForm.value.dateScheduletrip = this.dateSchedulebatch;
      if (this.addboard?.tourtype == 'customized') {
        this.dateSchedulebatch = this.allData.select_batch[0]._id;
        this.addPricingForm.get('bookPrice').setValue(0);
        this.addPricingForm.get('infant_price').setValue(0);
        this.addPricingForm.get('child_price').setValue(0);
      }
  
      // Now show the SweetAlert2 confirmation popup
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to update all batches?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update!',
        cancelButtonText: 'No, cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          // Proceed with the API call if confirmed
          let requestData = {
            bookPrice: this.addPricingForm.get('bookPrice').value,
            boarding_point_price: [JSON.stringify(boardingPointData)], 
            packageType_price: [JSON.stringify(packagetData)], 
            packageType_striking_price: [JSON.stringify(packagetStrikeData)], 
            roomSharing_price: [JSON.stringify(roomgetData)],
            boarding_point_type: 'NaN',
            status: this.addPricingForm.get('status').value,
            infant_price: this.addPricingForm.get('infant_price').value,
            child_price: this.addPricingForm.get('child_price').value,
            gst: this.addPricingForm.get('gst').value,
            tourDetailsNote: this.addPricingForm.get('tourDetailsNote').value,
            tcs: this.addPricingForm.get('tcs').value,
            dateScheduleTrip: this.dateSchedulebatch,
            isTourCreatedCompletely: '1'
          };
  
          // Log the formatted requestData to verify everything is correct
          console.log('Formatted Request Data:', requestData);
  
          // Make the API call with the updated data
          this.apiService.updateBatch(requestData, this.object).subscribe((res: any) => {
            if (res.success) {
              Swal.fire({
                text: res.message,
                icon: 'success',
              });



              if(this.addboard?.tourtype == 'normal'){
                this.router.navigateByUrl('/make-trip-list');
              }
              if(this.addboard?.tourtype == 'customized'){
                this.router.navigateByUrl('/make-trip-list');
              }
            } else {
              this.isAuthLoading = false;
              this.isAuthDisabled = false;
              Swal.fire({
                text: res.message,
                icon: 'error',
              });
            }
          });
  
          // Call the getdetail method with the provided id
          this.getdetail(id);
  
        } else {
          // Reset the flags if the user clicks cancel
          this.isCallingAddBatch = false;
          this.isCallingAddPrice = false;
          console.log('Action was canceled and variables were reset.');
        }
      });
    }
  }
  
  
  


  
}
