<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form [formGroup]="addEnquiryDetailForm" (ngSubmit)="updateNote()">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="form-group col-md-3">
                                                      <label for="title">Name</label>
                                                      <input type="text" class="form-control" formControlName="name"
                                                            placeholder="Name" readonly />


                                                </div>
                                                <div class="form-group col-md-3">
                                                      <label>Tour Name</label>
                                                      <input type="text" class="form-control"
                                                            formControlName="destination" placeholder="Enter Tour Name"
                                                            readonly />


                                                </div>
                                                <div class="form-group col-md-3">
                                                      <label>Mobile Number</label>
                                                      <input type="text" class="form-control"
                                                            (keypress)="onlyNumberKey($event)" formControlName="mobile"
                                                            maxlength="10" placeholder="Mobile Number" readonly>


                                                </div>
                                                <div class="form-group col-md-3">
                                                      <label>Email</label>
                                                      <input type="email" class="form-control" formControlName="email"
                                                            placeholder="Email" readonly [readonly]="">


                                                </div>

                                                <div class="form-group col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Notes <span
                                                                        class="required"></span></label>
                                                            <textarea class="form-control" formControlName="notes"
                                                                  rows="4"
                                                                  (ngModelChange)="updateNotes($event)"></textarea>
                                                      </div>
                                                </div>




                                          </div>



                                    </div>
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'"
                                                [loading]="isAuthLoading" [color]="'info'"
                                                [disabled]="isAuthDisabled"></app-button>
                                          <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/enquiry-list']">Cancel</button>
                                    </div>

                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>