import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit {
  addVideoForm: FormGroup;
  public cancelCatRoute: string;
  private videoid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
     this.addVideoForm = new FormGroup({
      video_name: new FormControl(),
      video_url: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });
    this.videoid = this.route.snapshot.paramMap.get('video_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.videoid != undefined &&
      this.videoid != null &&
      this.videoid != ''
    ) {
      this.editVideo(this.videoid);
    } else {
      this.addVideoForm.get('status').setValue('active');
    }
  }

  editVideo(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.videoById(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addVideoForm.patchValue({
          video_name: result.getData.video_name,
          video_url: result.getData.video_url,
        });
        if (result.getData.status == 'active') {
          this.addVideoForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addVideo() {
    if (
      this.addVideoForm.value.video_name == undefined ||
      this.addVideoForm.value.video_name.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter video name',
        icon: 'warning',
      });
      return false;
      ;
    }

    if (
      this.addVideoForm.value.video_url == undefined ||
      this.addVideoForm.value.video_url.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter video url',
        icon: 'warning',
      });
      return false;
      ;
    }
    
    if (this.addVideoForm.status == 'active') {
      this.addVideoForm.get('status').setValue('active');
    }
    if (this.videoid != undefined && this.videoid != null && this.videoid != '') {
      this.updateVideo(this.videoid);
    } else {
      this.addVideoForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .videoAdd(this.addVideoForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/add-video-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateVideo(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .upVideo(this.addVideoForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/add-video-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
