import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { log } from 'console';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
@Component({
  selector: 'app-promotional-sms-email',
  templateUrl: './promotional-sms-email.component.html',
  styleUrls: ['./promotional-sms-email.component.scss']
})
export class PromotionalSmsEmailComponent implements OnInit {

  emailFormControl = new FormControl('');
  ccFormControl = new FormControl('');
  addSmsForm: FormGroup;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  Data: any;
  id: any;
 data: any;
  // router: any;
  emailForm: FormGroup;
  enteredEmails: string[] = [];
  toEmails: string[] = [];
  ccEmails: string[] = [];
  bccEmails: string[] = [];
  subjects: string[] = [];
  messages: string[] = [];



email: any;

  // constructor(
  //   private apiService: ApiService,
  //   private formBuilder: FormBuilder) {
  //   this.emailForm = this.formBuilder.group({
  //     to: ['', [Validators.required, Validators.email]]
  //   });
  // }




  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
    
  ) {
    this.addSmsForm = this.formBuilder.group({
      to: [''],
      cc: [''],
      bcc: [''],
      subject: [''],
      messages: ['']
    });
   }

  ngOnInit(): void {
    this.addSmsForm = new FormGroup({
      subject: new FormControl(),
      messages: new FormControl(),
      bcc: new FormControl(),
      cc: new FormControl(),
      to: new FormControl(),
    });
    this.addSmsForm = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.minLength(3)]],
      bcc: [''],
      cc: [''],
      messages:  [''],
        to: ['', [Validators.required, Validators.email]]
    });


  }

  // addEmail(field: string, event?: KeyboardEvent): void {
  //   let value = this.emailFormControl.value.trim();
  //   if (event) {
  //     value = (event.target as HTMLInputElement).value.trim();
  //   }
  //   if (value !== '' && this.isValidEmail(value)) {
  //     if (field === 'to') {
  //       this.toEmails.push(value);
  //     } else if (field === 'cc') {
  //       this.ccEmails.push(value);
  //       if (this.emailForm && this.emailForm.get('cc')) {
  //         this.emailForm.get('cc')?.setValue('');
  //       }
  //     } else if (field === 'bcc') {
  //       this.bccEmails.push(value);
  //       if (this.emailForm && this.emailForm.get('bcc')) {
  //         this.emailForm.get('bcc')?.setValue('');
  //       }
  //     }
  //     if (this.emailFormControl) {
  //       this.emailFormControl.setValue('');
  //     }
  //   }
    
  //   // if (value !== '' && this.isValidEmail(value)) {
  //   //   if (field === 'to') {
  //   //     this.toEmails.push(value);
  //   //   } else if (field === 'cc') {
  //   //     this.ccEmails.push(value);
  //   //     this.emailForm.get('cc')?.setValue('');
  //   //   } else if (field === 'bcc') {
  //   //     this.bccEmails.push(value);
  //   //     this.emailForm.get('bcc')?.setValue('');
  //   //   }
  //   //   this.emailFormControl.setValue('');
  //   // }
  // }
  addEmail(field: string, event?: KeyboardEvent): void {
    let value = this.emailFormControl.value.trim();
    if (event) {
      value = (event.target as HTMLInputElement).value.trim();
    }
    if (value !== '' && this.isValidEmail(value)) {
      if (field === 'to') {
        this.toEmails.push(value);
      } else if (field === 'cc') {
        this.ccEmails.push(value);
        this.addSmsForm.get('cc')?.reset(); // Reset the CC field
      } else if (field === 'bcc') {
        this.bccEmails.push(value);
        this.addSmsForm.get('bcc')?.reset(); // Reset the BCC field
      }
      this.emailFormControl.setValue(''); // Reset the TO field
    }
  }
  

  removeEmail(field: string, index: number): void {
    if (field === 'to') {
      this.toEmails.splice(index, 1);
    } else if (field === 'cc') {
      this.ccEmails.splice(index, 1);
    } else if (field === 'bcc') {
      this.bccEmails.splice(index, 1);
    }
  }
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  }







  subject(event: any) {
    this.subjects = event.target.value; 
console.log(this.subjects);

  }

  messeges(event: any) {
    this.messages = event.target.value;
console.log(this.subjects);

  }
  

// updateMail(id) {
//   const data = {
//     "recipients":{
//       "to": this.toEmails,
//       "cc": this.ccEmails,
//       "bcc": this.bccEmails,
//     },
//     "subject": this.subjects,
//     "mailBody": this.messages 
//   };
  
//   // Checking for non-email data in the arrays
//   const containsNonEmailData =
//     this.toEmails.some(email => !this.isValidEmail(email)) ||
//     this.ccEmails.some(email => !this.isValidEmail(email)) ||
//     this.bccEmails.some(email => !this.isValidEmail(email));

//   if (containsNonEmailData) {
//     // Alert if non-email data is found
//     Swal.fire({
//       text: 'Please provide valid email addresses.',
//       icon: 'error',
//     });
//     return; // Stop further execution
//   }

//   console.log(data, "hhhhhhhhh");

//   this.isAuthLoading = true;
//   this.isAuthDisabled = true;
//   this.apiService.sendMail(data)?.subscribe((res: any) => {
//     if (res.success) {
//       this.isAuthLoading = false;
//       this.isAuthDisabled = false;
//       Swal.fire({
//         text: res.message,
//         icon: 'success',
//       });
//       if (this.router) { // Check if router is defined
//         this.router.navigateByUrl('/promotional-sms-email-list');
//       }
//     } else {
//       this.isAuthLoading = false;
//       this.isAuthDisabled = false;
//       Swal.fire({
//         text: res.message,
//         icon: 'error',
//       });
//     }
//   });
// }


updateMail(id) {
 
  const data = {
    "recipients":{
      "to": this.toEmails,
      "cc": this.ccEmails,
      "bcc": this.bccEmails,
    },
    "subject": this.subjects,
    "mailBody": this.messages 
  };
  
  // Checking if the data is empty
  const isEmptyData = 
    Object.values(data).some(value => Array.isArray(value) ? value.length === 0 : !value);

  if (isEmptyData) {
    // Alert if data is empty
    Swal.fire({
      text: 'Please fill in all required fields.',
      icon: 'error',
    });
    return; // Stop further execution
  }
  this.spinner.show();
  // Checking for non-email data in the arrays
  const containsNonEmailData =
    this.toEmails.some(email => !this.isValidEmail(email)) ||
    this.ccEmails.some(email => !this.isValidEmail(email)) ||
    this.bccEmails.some(email => !this.isValidEmail(email));

  if (containsNonEmailData) {
    // Alert if non-email data is found
    Swal.fire({
      text: 'Please provide valid email addresses.',
      icon: 'error',
    });
    return; // Stop further execution
  }

  console.log(data, "hhhhhhhhh");

  this.isAuthLoading = true;
  this.isAuthDisabled = true;
  this.apiService.sendMail(data)?.subscribe((res: any) => {
    this.spinner.hide();
    if (res.success) {
      this.isAuthLoading = false;
      this.isAuthDisabled = false;
      Swal.fire({
        text: res.message,
        icon: 'success',
      });
      if (this.router) { // Check if router is defined
        this.router.navigateByUrl('/promotional-sms-email-list');
      }
    } else {
      this.isAuthLoading = false;
      this.isAuthDisabled = false;
      Swal.fire({
        text: res.message,
        icon: 'error',
      });
    }
  });
}

addSms(){

}



}
