<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addSpecialTourFrom" (ngSubmit)="addSpecial()">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Add Special Tour Title<span class="required"></span></label>
                                                            <input type="text" class="form-control"  formControlName="title" placeholder="Add Special Tour Title">

                                                      </div>
                                                </div>
                                                <!-- </div>

                                          <div class="row"> -->
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label>Image<span class="required"></span></label>
                                                            <div class="input-group">
                                                                  <div class="custom-file">
                                                                        <input type="file" accept="image/*"
                                                                              (change)="onFileChange($event)"
                                                                              class="custom-file-input"
                                                                              aria-describedby="imageFile"
                                                                              name="special_tour_image" id="img" />
                                                                        <label class="custom-file-label" #importImg
                                                                              for="special_tour_image">
                                                                              <span>Upload Image</span>
                                                                        </label>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>

                                          </div>
                                          
                                         <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="dev" type="checkbox" class="custom-control-input"
                                                                  formControlName="status"
                                                                  (change)="addSpecialTourFrom.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                  [checked]="addSpecialTourFrom .value.status == 'active'" />
                                                            <label class="custom-control-label" for="dev">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                         

                                    </div>




                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                            [disabled]="isAuthDisabled"></app-button>
                          <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                            [routerLink]="['/special-tour-list']">Cancel</button>
                                      </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>


