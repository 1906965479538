import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pay-link-orders',
  templateUrl: './pay-link-orders.component.html',
  styleUrls: ['./pay-link-orders.component.scss']
})
export class PayLinkOrdersComponent implements OnInit, OnDestroy {
  dtOptions: any = {};
  allOrdersWithLink: any;
  render: number;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
  validity: number[] = Array.from({length: 24}, (_, i) => i+1);
  linkValidTill: number = 1;
  orderId: string|undefined;
  totalUnpaidAddon = 0;

  constructor(
    private apiService: ApiService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: ['excel']
    };
    this.render = 1;
    this.showItem();
  }

  showItem() {
    this.apiService.getAllOrderWithLink().subscribe((resp: any) => {
      this.allOrdersWithLink = resp.data;
      console.log(this.allOrdersWithLink);
      for(let order of this.allOrdersWithLink) {
        if (order?.addons_id && order?.addons_id?.addons_data.length) {
          const addonData = order?.addons_id?.addons_data;
          for (let addon of addonData) {
            if (addon.status === 'unpaid') {
              this.totalUnpaidAddon += (addon.price * addon.quantity);
            }
          }
          order.addons_id = this.totalUnpaidAddon;
        }
      }
      if (this.render === 1) {
        this.dtTrigger.next();
      }
      this.render = 0;
    });
  }

  checkValidity(validTime: string): boolean {
    return new Date(validTime).getTime() < Date.now();
  }

  getOrderId(orderId: string) {
    this.orderId = orderId;
  }

  sendLink() {
    const dataToSend = {
      validTill: this.linkValidTill
    }
    this.apiService.reviveSendPayLink(this.orderId, dataToSend).subscribe((res: any) => {
      if (res.success) {
        this.linkValidTill = 1;
        this.toastr.success('New pay link sent successfully');
        (<any>$('#pay-link')).modal('hide');
        this.showItem();
      } else {
        this.toastr.error(res.message);
        console.error(res.message);
      }
    });
  }

  copyShareLink(linkToCopy: string) {
    const urlToShare = linkToCopy;
    // console.error(location.origin, location.href, location.pathname);
    if (!navigator.clipboard) {
      var txtArea = document.createElement('textarea');
      txtArea.value = urlToShare;
      document.body.appendChild(txtArea);
      txtArea.select();
      document.execCommand('copy');
      this.toastr.success('Copied to clipboard');
      txtArea.parentNode.removeChild(txtArea);
    } else {
      navigator.clipboard.writeText(urlToShare).then(() => {
        this.toastr.success('Copied to clipboard');
      }).catch(() => {
        this.toastr.error('Unable to copy');
      });
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
