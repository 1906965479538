<section class="content">
  <div class="row" >
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <a
            [routerLink]="['/promotional-sms-email']"
            class="btn btn-info float-right mb-3"
            >Add Email</a
          >
          <table 
            datatable 
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger" 
            class="row-border hover"
          >
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Subject Title</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data.subject }}</td>
                <td>{{ data.created_at }}</td>
                <td>{{data.status}}</td>            
                <td>
                  <div class="btn-group">
                    <button 
                      type="button" 
                      data-toggle="modal"
                       data-target="#exampleModalCenter"
                      class="btn btn-info"
                      (click)="promotionalemail(data._id)"
                    
                       >View </button>
                
                      <!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Email</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div  *ngIf="newMail?.length != 0" >
       
      <p>
      Subject:{{newMail?.subject}}
    </p>
    <p>
      Date: {{newMail?.created_at}}
      </p>
      
      </div> -->
    
    

      <div class="email-container">
        <div class="recipient-section">
            <label for="to">To:</label>
            <span id="toRecipient">{{newMail?.recipients.to}}</span>
        </div>
        <div class="recipient-section">
            <label for="bcc">BCC:</label>
            <span id="bccRecipient">{{newMail?.recipients.bcc}}</span>
        </div>
        <div class="recipient-section">
            <label for="cc">CC:</label>
            <span id="ccRecipient">{{newMail?.recipients.cc}}</span>
        </div>
        <div class="email-details">
            <label for="date">Date:</label>
            <span id="emailDate">{{newMail?.created_at}}</span>
        </div>
        <div class="email-details">
            <label for="subject">Subject:</label>
            <span id="emailSubject">{{newMail?.subject}}</span>
        </div>
        <div class="email-body">
            <label for="message">Message:</label>
            <span id="emailMessage">{{newMail?.mailBody}}</span>
        </div>
    </div>
    
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td colspan="3" class="no-data-available">No data!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
