import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private category: string;
  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;


  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    if (this.category === 'add-menu-list') {
      this.addLanName = 'Add Menu Type';
      this.addLanRoute = '/add-menu';
      // this.editCatRoute = '/news/edit-news';
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.refundlist();
  }

  refundlist() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.allRefund().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

}
