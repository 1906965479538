<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <form [formGroup]="addMenuMasterFormnew" (ngSubmit)="addmastermenunew()" >
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Main Menu Name <span class="required"></span> </label>
                                        <input type="text" class="form-control" formControlName="category"
                                            placeholder="Main Menu Name">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Sub Menu<span class="required"></span></label>
                                        <ng-select placeholder="Select Sub Menu" [multiple]="true" formControlName="submneuArr">
                                            <!-- <option value="" selected>Select Adventure Type
                                            </option> -->
                                            <!-- <option *ngFor="let item of allAdve; let i = index" value="{{item._id}}">
                                                {{item.adventure_type}} </option> -->
                                                <ng-option *ngFor="let item of allData" [value]="item._id">{{
                                                    item.menu }}
                                                </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                               
                            </div>
                    
                           
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <input id="male" type="checkbox" class="custom-control-input"
                                                formControlName="status"
                                                (change)="addMenuMasterFormnew.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                [checked]="addMenuMasterFormnew.value.status == 'active'" />
                                            <label class="custom-control-label" for="male">Status</label>
                                        </div>
                                    </div>
                                    
                                </div>
                           
                        </div>
                        <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Save'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>
                            <button type="button" class="btn btn-secondary ml-2"
                                [routerLink]="['/add-menu-master-list-new']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>