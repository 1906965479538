import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styleUrls: ['./add-media.component.scss'],
})
export class AddMediaComponent implements OnInit {
  isActive: boolean;
  uploadProgress: any;
  alldesti: any;
  getHost: any;
  item: any;
  tour: any;
  gallery: any;
  desti: string = '';
  both: any;
  subCat: any;
  radio: string = 'tour';
  private _userId: string = localStorage.getItem('id');
  getUploadData = [];

  mainCat: string = '';
  is_Tour: boolean = false;

  status: boolean = true;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.destination();
  }

  destination() {
    this.apiService.activeDestination().subscribe((res: any) => {
      console.log(res);
      this.alldesti = res.data;
    });
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = false;
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    let droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      this.isActive = false;
      if (this.checkFileType(droppedFiles)) {
        this.onDroppedFile(droppedFiles);
      } else {
        return;
      } 
    }
  }

  onSelectedFile(event: any) {
    if (event.target.files.length > 0) {
      if (this.checkFileType(event.target.files)) {
        this.onDroppedFile(event.target.files);
      } else {
        return;
      }
    }
  }

  // onDroppedFile(droppedFiles: any) {
  //   console.log(droppedFiles)
  //   if (this.desti === '') {
  //     Swal.fire({
  //       text: 'Please select destination',
  //       icon: 'warning',
  //     });
  //     return;
  //   }
  //   // console.log(this.desti);
  //   // return;

  //   //console.log(this.radio);
  //   // {{selectedSport.key === 'walking' ? 'steps' : 'km'}}

  //   //    if (this.desti.nativeElement.value === '') {
  //   //   Swal.fire({
  //   //     text: 'Please select language',
  //   //     icon: 'warning'
  //   //   });
  //   //   return;
  //   //   // this.toastr.error('Please Select Language');
  //   //   // return;
  //   // }

  //   let formData = new FormData();
  //   formData.append('status', 'active');
  //   formData.append('user_id', this._userId);
  //   formData.append('is_Tour', this.radio === 'tour' ? 'true' : 'false');

  //   formData.append('is_Gallery', this.radio === 'gallery' ? 'true' : 'false');
  //   formData.append('is_Both', this.radio === 'both' ? 'true' : 'false');

  //   formData.append('destination', this.desti);

  //   for (let item of droppedFiles) {
  //     console.log(item)
  //     //return;
  //     formData.append('gallery_image', item);
  //     this.apiService.addImage(formData).subscribe((result) => {
  //       this.uploadProgress = result;
  //       if (result.getData) {
  //         this.getUploadData.push(result.imageData);
  //         console.log(result);
  //       }
  //     });
  //     formData.delete('gallery_image');
  //   }
  // }
  onDroppedFile(droppedFiles: any) {
    // Remove the validation check for destination
    // if (this.desti === '') {
    //   Swal.fire({
    //     text: 'Please select destination',
    //     icon: 'warning',
    //   });
    //   return;
    // }

    let formData = new FormData();
    formData.append('status', 'active');
    formData.append('user_id', this._userId);
    formData.append('is_Tour', this.radio === 'tour' ? 'true' : 'false');

    formData.append('is_Gallery', this.radio === 'gallery' ? 'true' : 'false');
    formData.append('is_Both', this.radio === 'both' ? 'true' : 'false');

    // formData.append('destination', this.desti); // Remove appending destination to formData

    for (let item of droppedFiles) {
        formData.append('gallery_image', item);
        this.apiService.addImage(formData).subscribe((result) => {
            this.uploadProgress = result;
            if (result.getData) {
                this.getUploadData.push(result.imageData);
                // console.log(result);
            }
        });
        formData.delete('gallery_image');
    }
}

  checkFileType(allFiles): boolean {
    let allImages: Array<string> = ['png', 'jpg', 'jpeg', 'gif'];
    for (let file of allFiles) {
      if (allImages.indexOf(file.name.split('.')[1]) === -1) {
        Swal.fire({
          text: 'Please upload image files of above format',
          icon: 'warning',
        });
        return false;
      }
    }
    return true;
  }

  getImg(imgSrc) {
    return this.apiService.getHost(imgSrc);
  }
}
