import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-special-tour',
  templateUrl: './special-tour.component.html',
  styleUrls: ['./special-tour.component.scss']
})
export class SpecialTourComponent implements OnInit {
  addSpecialTourFrom: FormGroup;
  private category: string;
  private imageid: any;
  private status: any;
  public cancelCatRoute: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
   @ViewChild('importImg', { static: false }) importImg: ElementRef;
  ngOnInit(): void {
    this.addSpecialTourFrom = new FormGroup({
      title: new FormControl(),
      special_tour_image: new FormControl(),
      status: new FormControl(),
    });

    this.addSpecialTourFrom = this.formBuilder.group({
      title: ['', [Validators.required]],
      special_tour_image: ['', [Validators.required]],
      status: [false],
    });

    this.imageid = this.route.snapshot.paramMap.get('image_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.imageid != undefined &&
      this.imageid != null &&
      this.imageid != ''
    ) {
      this.ImageSpecial(this.imageid);
    } else {
      this.addSpecialTourFrom.get('status').setValue('active');
    }

  }

  onFileChange(event) {
    // console.log(event.target.files[0]);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addSpecialTourFrom.get('special_tour_image').setValue(file);
    }
    this.importImg.nativeElement.innerText = event.target.files[0].name;
  }

  ImageSpecial(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.SpecialImageById(dataForForm).subscribe((res: any) => {
      console.log(res);
      let { getData } = res;
      if (getData.status == 'active') {
        this.addSpecialTourFrom.get('status').setValue('active');
      }
      if (res.success) {
        this.addSpecialTourFrom
          .get('title')
          .setValue(getData.title);
        setTimeout(() => {
          this.importImg.nativeElement.innerText =
            getData.special_tour_image[0]?.orgName;
        }, 1000);
      }
    });
  }

   addSpecial() {
    if (
      this.addSpecialTourFrom.value.title.trim() == undefined ||
      this.addSpecialTourFrom.value.title.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter the title',
        icon: 'warning' 
      });
      return false;
      // this.toastr.error('Please Enter the Business Category');
      // return false;
    }

    if (
      this.imageid != undefined &&
      this.imageid != null &&
      this.imageid != ''
    ) {
      this.updateImage(this.imageid);
    } else {
      if (
        this.addSpecialTourFrom.value.special_tour_image == undefined ||
        this.addSpecialTourFrom.value.special_tour_image == ''
      ) {
        Swal.fire({
        text: 'Please upload the image',
        icon: 'warning' 
      });
      return false;
        // this.toastr.error('Please Select the Image.');
        // return false;
      }
      let formData = new FormData();
      formData.append(
        'title',
        this.addSpecialTourFrom.get('title').value
      );
      formData.append(
        'special_tour_image',
        this.addSpecialTourFrom.get('special_tour_image').value
      );
    //   if (this.addSpecialTourFrom.status == 'active') {
    //   this.addSpecialTourFrom.get('status').setValue('active');
    // }
      formData.append('user_id', this._userId);
      formData.append('status', this.addSpecialTourFrom.get('status').value);
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService.specialImage(formData).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
           Swal.fire({
              text: res.message,
              icon: 'success',
            });
          //this.toastr.success(res.message);
          this.router.navigateByUrl('/special-tour-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
              text: res.message,
              icon: 'error',
            });
          //this.toastr.error(res.message);
        }
      });
    }
  }

  updateImage(id) {
    let formData = new FormData();
    //console.log(this.addSpecialTourFrom.value.special_tour_image?.name);
    
    if(
      this.addSpecialTourFrom.value.special_tour_image?.name != undefined 
      // ||
      // this.addSpecialTourFrom.value.special_tour_image?.name != ''
    ) {
      //console.log('kalu');
      
      formData.append('special_tour_image', this.addSpecialTourFrom.get('special_tour_image').value);
    }
   
    formData.append('title', this.addSpecialTourFrom.get('title').value);
    formData.append('status', this.addSpecialTourFrom.get('status').value);
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateSpecialImage(formData, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
              text: res.message,
              icon: 'success',
            });
          this.router.navigateByUrl('/special-tour-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
              text: res.message,
              icon: 'error',
            });
          //this.toastr.error(res.message);
        }
     });
  }
}
