<div class="wrapper">
  <app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

  <app-menu-sidebar
    (mainSidebarHeight)="mainSidebarHeight($event)"
  ></app-menu-sidebar>

  <div #contentWrapper class="content-wrapper">
    <app-page-header></app-page-header>  
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
  <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
</div>
