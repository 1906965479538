import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-room-sharing',
  templateUrl: './add-room-sharing.component.html',
  styleUrls: ['./add-room-sharing.component.scss']
})
export class AddRoomSharingComponent implements OnInit {
  addRoomSharingForm: FormGroup;
  public cancelCatRoute: string;
  private roomid: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addRoomSharingForm = new FormGroup({
      room_name: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });
     this.roomid = this.route.snapshot.paramMap.get('room_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.roomid != undefined &&
      this.roomid != null &&
      this.roomid != ''
    ) {
      this.editroom(this.roomid);
    } else {
      this.addRoomSharingForm.get('status').setValue('active');
    }
  }

  editroom(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getRoomId(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addRoomSharingForm.patchValue({
          room_name: result.getData.room_name,
        });
        if (result.getData.status == 'active') {
          this.addRoomSharingForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addRooms() {
    if (
      this.addRoomSharingForm.value.room_name== undefined ||
      this.addRoomSharingForm.value.room_name.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter room name',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addRoomSharingForm.value.this._userId == undefined ||
    //   this.addRoomSharingForm.value.boarding.trim() == ''
    // )
    if (this.addRoomSharingForm.status == 'active') {
      this.addRoomSharingForm.get('status').setValue('active');
    }
    if (this.roomid != undefined && this.roomid != null && this.roomid != '') {
      this.updateRoom(this.roomid);
    } else {
      this.addRoomSharingForm.value.user_id = this._userId;
       this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .postAddRoom(this.addRoomSharingForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
             this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/room-sharing-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateRoom(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateRoomById(this.addRoomSharingForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/room-sharing-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
