<section class="content">
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a
              [routerLink]="['/blog']"
              class="btn btn-info float-right mb-3"
              >Add New Blog</a
            >
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                  <!-- <th>Description</th> -->
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allBlogs?.length != 0">
                <tr *ngFor="let blogs of allBlogs; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ blogs.title | titlecase }}</td>
                  <!-- <td><div [innerHTML]="blogs.description"></div></td> -->
                  <td *ngIf="blogs.status === 'active'">Active</td>
                  <td *ngIf="blogs.status !== 'active'">Inactive</td>
                  <td>
                    <div class="btn-group">
                      <button 
                        type="button" 
                        class="btn btn-info"
                        routerLink="/blog/edit-blog/{{ blogs._id }}"
                      ><i class="fas fa-pen"></i></button>
                      <button 
                        *ngIf="blogs.status === 'active'"
                        type="button" 
                        class="btn btn-success"
                        (click)="changeStaus(blogs._id, blogs.status)"
                      ><i class="far fa-check-circle"></i></button>
                      <button 
                        *ngIf="blogs.status !== 'active'"
                        type="button" 
                        class="btn btn-danger"
                        (click)="changeStaus(blogs._id, blogs.status)"
                      ><i class="fas fa-ban"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allBlogs?.length == 0">
                <tr>
                  <td></td>
                  <td class="no-data-available text-right">No data!</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  