<section class="content">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <a [routerLink]="['/make-trip']" class="btn btn-info float-right mb-3">Add Trip</a>
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Title</th>
                <th>Trip</th>
                <th>Trip Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data.title }}</td>
                <td>{{ data.tourtype | titlecase }}</td>
                <td>{{ data?.domesticorInternational | titlecase }} </td>
                <!-- <td>500</td> -->
                <!-- <td *ngIf="data.tripType !== undefined && data.tripType !== null">
                  <ng-container *ngIf="data.tripType === '1'">Trip</ng-container>
                  <ng-container *ngIf="data.tripType === '2'">Trek</ng-container>
                  <ng-container *ngIf="data.tripType === '3'">Festival</ng-container>
                </td> -->
                <td *ngIf="data.status !== undefined && data.status !== null">
                  <ng-container *ngIf="data.status === 'active'">Active</ng-container>
                  <ng-container *ngIf="data.status !== 'active'">Inactive</ng-container>
                </td>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-info" routerLink="/make-trip/{{ data._id }}"><i
                        class="fas fa-pen"></i></button>
                    <button *ngIf="data.status === 'active'" type="button" class="btn btn-success"
                      (click)="Triplist(data._id, data.status)"><i class="far fa-check-circle"></i></button>
                    <button *ngIf="data.status !== 'active'" type="button" class="btn btn-danger"
                      (click)="Triplist(data._id, data.status)"><i class="fas fa-ban"></i></button>
                    <button type="button" class="btn btn-warning" (click)="cloneTourData(data)"><i
                        class="fas fa-copy"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td colspan="3" class="no-data-available">No data!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>