<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="class_name">Mobile Number <span class="required"></span></label>
                                    <input type="text" (keypress)="onlyNumberKey($event)" maxlength="10"
                                        [(ngModel)]="mobile_number" name="mobile_number" class="form-control"
                                        placeholder="Mobile">
                                </div>
                            </div>
                            <div class="col-md-2 d-flex align-items-end">
                                <div class="form-group">
                                    <button type="button" (click)="searchUser()" class="btn btn-info">
                                        Search User
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-6"></div>

                            <div class="col-md-4" *ngIf="allTourByUser.length">
                                <div class="form-group">
                                    <label for="class_name">User's All Cancelled Tours</label>
                                    <select class="form-control" name="selected_tour"
                                        (change)="onTourSelect($event.target.value)">
                                        <option value="">Select Tour</option>
                                        <option *ngFor="let tour of allTourByUser" value="{{ tour?._id }}">{{
                                            tour?.tour_id?.title }} ({{ tour?.travel_date }})</option>
                                    </select>
                                </div>
                            </div>
                            <ng-container *ngIf="(selected_tour | keyvalue)?.length">
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tour Date</label>
                                        <input type="text" readonly value="{{ selected_tour?.travel_date }}"
                                            name="tour_date" class="form-control" placeholder="Date">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Total Number Of Person</label>
                                        <input type="text" readonly value="{{ selected_tour?.old_travel_no_of_traveler? selected_tour?.old_travel_no_of_traveler : selected_tour?.travel_no_of_traveler }}"
                                            name="tour_date" class="form-control" placeholder="Number Of Person">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Cancelled Person</label>
                                        <input type="text" readonly value="{{ +selected_tour?.old_travel_no_of_traveler ? +selected_tour?.old_travel_no_of_traveler - +selected_tour?.travel_no_of_traveler : 0}}"
                                            name="tour_date" class="form-control" placeholder="Number Of Person">
                                    </div>
                                    <!-- <ng-template #dothat>
                                      <div></div>
                                      <div></div>
                                    </ng-template> -->
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tour Amount</label>
                                        <input type="text" readonly value="{{ (selected_tour?.totalAmount).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }) }}"
                                            name="total_tour_amt" class="form-control" placeholder="Tour Amount">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                      <label for="class_name">Tour Amount Remaining</label>
                                      <input type="text" readonly
                                          value="{{ (selected_tour?.totalAmount - selected_tour?.amountPaid + selected_tour?.addons_id?.totalAddon).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}}"
                                          name="remaining_tour_amt" class="form-control"
                                          placeholder="Remaining Tour Amount">
                                  </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tour Amount Received</label>
                                        <input type="text" readonly
                                            value="{{(selected_tour?.totalAmount - (selected_tour?.totalAmount - selected_tour?.amountPaid + selected_tour?.addons_id?.totalAddon)).toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              })}}"
                                            name="remaining_tour_amt" class="form-control"
                                            placeholder="Remaining Tour Amount">
                                    </div>
                                </div>

                                

                                <ng-container *ngIf="this.selected_tour?.addons_id">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="class_name">Addon Amount</label>
                                            <input type="text" value="{{ (selected_tour.addons_id.totalAddon).toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              }) }}" readonly
                                                name="total_addon_amt" class="form-control" placeholder="Addon Amount">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="class_name">Addon Amount Remaining</label>
                                            <input type="text"
                                                value="{{ (selected_tour.addons_id.totalAddon - selected_tour.addons_id.paidAddon).toLocaleString('en-IN', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                  }) }}"
                                                readonly name="remaining_addon_amt" class="form-control"
                                                placeholder="Remaining Addon Amount">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="class_name">Addon Amount Received</label>
                                            <input type="text"
                                                value="{{ (selected_tour.addons_id.totalAddon - (selected_tour.addons_id.totalAddon - selected_tour.addons_id.paidAddon)).toLocaleString('en-IN', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                  }) }}"
                                                readonly name="remaining_addon_amt" class="form-control"
                                                placeholder="Remaining Addon Amount">
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Total Amount</label>
                                        <input type="text"
                                            value="{{ selected_tour?.addons_id ? (selected_tour?.totalAmount + selected_tour.addons_id.totalAddon).toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              }) : (selected_tour?.totalAmount).toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              }) }}"
                                            readonly name="remaining_addon_amt" class="form-control" placeholder="Total Amount">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Total Amount Remaining</label>
                                        <input type="text"
                                        value="{{ (selected_tour?.totalAmount - selected_tour?.amountPaid + selected_tour?.addons_id?.totalAddon).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}}"
                                            
                                            readonly name="remaining_addon_amt" class="form-control" placeholder="Remaining Amount">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                      <label for="class_name">Total Amount Received</label>
                                      <input type="text" readonly value="{{ (selected_tour?.amountPaid).toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }) }}"
                                          name="amount_paid" class="form-control" placeholder="Amount Paid">
                                  </div>
                                </div>

                                <!-- <div> value="{{ selected_tour?.addons_id.addons_data.length > 0
                                    ? (selected_tour?.totalAmount - selected_tour?.amountPaid) + (selected_tour.addons_id.totalAddon - selected_tour.addons_id.paidAddon) : (selected_tour?.totalAmount - selected_tour?.amountPaid) }}"</div> -->

                                <!-- <div class="col-md-4">
                                  <div></div>
                                  <div></div>
                                </div> -->

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Refund Amount</label>
                                        <input type="text" name="refund_amt" [(ngModel)]="refund_amt" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="Refund Amount">
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex align-items-end">
                                    <div class="form-group">
                                        <button type="button" (click)="submitRefund()" class="btn btn-info">
                                            Refund Amount
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
