<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- form start -->
                    <form [formGroup]="addLableForm" (ngSubmit)="addLable()"  >
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Lable  <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="tagLable"  placeholder="Lable">
                                    </div>
                                </div>
                              
                            </div>
                          </div>
                   <div class="card-body" style="margin-top: -42px;">
                      <div class="row">
                            <div class="col-md-4">
                                    
                              <div class="custom-control custom-checkbox">
                                  <input id="male" type="checkbox" class="custom-control-input"
                                  formControlName="status"  (change)="addLableForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                  [checked]="addLableForm.value.status == 'active'"/>
                             <label class="custom-control-label" for="male">Status</label> 
                              </div>
                     </div>
                           </div>
                  </div>
                  

                           
                       
                        <!-- /.card-body -->
                        <div class="card-footer text-right">
                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                          [disabled]="isAuthDisabled"></app-button>
                        <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                          [routerLink]="['/lable-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>










