<section class="content">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Name</th>
                                <th>Transcation Date And Time</th>
                                <th>Total Amount</th>
                                <th>Amount Paid</th>
                                <th>Remainging Amount</th>
                                <th>Invoice</th>
                                
                                
                            </tr>
                        </thead>
                        <tbody *ngIf="allData?.length != 0"  >
                            <tr *ngFor="let data of allData; let i = index">

                                <td >{{ i + 1 }}</td>
                                <td>{{data?.user_id?.firstname }}</td>
                                <td>{{data?.created_at | date:"d/M/yy,h:mm a"}}</td>
                                <td>{{data?.totalAmount}}</td>
                                <td>{{data?.amountPaid }}</td>
                                <td>{{data?.totalAmount - data?.amountPaid}}</td>
                                <td>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-info" (click)="openLink(data.pdf_url)">
                                            <i class="far fa-file-pdf">
                                                Invoice</i></button>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                        <tbody *ngIf="allUser?.length == 0">
                            <tr>
                                <td colspan="3" class="no-data-available">No data!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

