import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bike-options',
  templateUrl: './bike-options.component.html',
  styleUrls: ['./bike-options.component.scss']
})
export class BikeOptionsComponent implements OnInit {
  addBikeOptionForm: FormGroup;
  public cancelCatRoute: string;
  private bikeid: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addBikeOptionForm = new FormGroup({
      bike_name: new FormControl(),
      bike_opt: new FormControl('solo'),
      status: new FormControl()
    });
     this.bikeid = this.route.snapshot.paramMap.get('bike_id');
    if (
      this.bikeid != undefined &&
      this.bikeid != null &&
      this.bikeid != ''
    ) {
      this.editbike(this.bikeid);
    } else {
      this.addBikeOptionForm.get('status').setValue('active');
    }
  }

  editbike(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getBikeId(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addBikeOptionForm.patchValue({
          bike_name: result.getData.bike_name,
          bike_opt: result.getData.bike_opt
        });
        if (result.getData.status == 'active') {
          this.addBikeOptionForm.get('status').setValue('active');
        }
      }
    });
  }

  addBike() {
    if (
      this.addBikeOptionForm.value.bike_name== undefined ||
      this.addBikeOptionForm.value.bike_name.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter bike name',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addBikeOptionForm.value.bike_opt== undefined ||
      this.addBikeOptionForm.value.bike_opt.trim() == ''
    ) {
      Swal.fire({
        text: 'Please select bike type',
        icon: 'warning',
      });
      return false;
    }
    if (this.addBikeOptionForm.status == 'active') {
      this.addBikeOptionForm.get('status').setValue('active');
    }
    if (this.bikeid != undefined && this.bikeid != null && this.bikeid != '') {
      this.updateBike(this.bikeid);
    } else {
      this.addBikeOptionForm.value.user_id = this._userId;
       this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .postAddBike(this.addBikeOptionForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
             this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/bike-options-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateBike(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateBikeById(this.addBikeOptionForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/bike-options-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
