<div class="container px-4">
    <div class="row">
        <div class=" col-md-12">
            <div class="card">
                <div class="card-body" *ngIf="(allLandingData | json) != '{}'">
                    <div class="row gx-3 mb-3">
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">Name :-</div>
                            <div class="mb-1">{{ allLandingData.name }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">Email Address :-</div>
                            <div class="mb-1">{{ allLandingData.email }}</div>
                        </div>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">Phone No. :-</div>
                            <div class="mb-1">{{ allLandingData.phone }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">Travel Date :-</div>
                            <div class="mb-1">{{ formateDate(allLandingData.dot) }}</div>
                        </div>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">No. Of People :-</div>
                            <div class="mb-1">{{ allLandingData.people }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 font-weight-bold">Created At :-</div>
                            <div class="mb-1">{{ allLandingData.created_at | date: 'dd-MM-yyyy hh:mm a' }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="mb-1 font-weight-bold">Message :-</div>
                        <div class="mb-1">{{ allLandingData.message }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>