import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  admin_id: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'register-page');
    this.registerForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      mobile: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      retypePassword: new FormControl(null, Validators.required),
    });

    this.admin_id = this.route.snapshot.paramMap.get('admin_id');
    console.log(this.admin_id);
    if (this.admin_id != undefined && this.admin_id != '') {
      this.editAdmin(this.admin_id);
    }
  }

  async register() {
    if (this.registerForm.valid) {
      const testBy = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let mobNum = ""+this.registerForm.value.mobile;
      let isValid = testBy.test(this.registerForm.get("email").value.toLowerCase());
      if(this.registerForm.value.firstName.trim() === '') {
        this.toastr.error('Please Enter Valid First Name');
        return false;
      }
      if(this.registerForm.value.lastName.trim() === '') {
        this.toastr.error('Please Enter Valid Last Name');
        return false;
      }
      if(!isValid) {
        this.toastr.error('Please Enter Valid Email Address');
        return false;
      }
      if(mobNum.length !== 10) {
        this.toastr.error('Mobile No. Should Be Of Ten Digits');
        return false;
      }
      if (this.admin_id != undefined && this.admin_id != '' && this.admin_id != null) {
        this.updateAdmin(this.admin_id);
      } else {
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        let loginData = {
          firstname: this.registerForm.get("firstName").value,
          lastname: this.registerForm.get("lastName").value,
          email: this.registerForm.get("email").value,
          mobile: this.registerForm.get("mobile").value,
          password: this.registerForm.get("password").value,
          confirm_password: this.registerForm.get("retypePassword").value,
          role: '1'
        };
        await this.apiService.onRegister(JSON.stringify(loginData)).subscribe((result: any) => {
          console.log(result);
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            this.router.navigate(['/admin-list']);
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      }
      // this.appService.register();
    } else {
      this.toastr.error('Please Fill All The Fields');
    }
  }

  editAdmin(id) {
    this.registerForm.get('email').disable();
    let dataForForm = {
      "id": id
    };
    if (id) {
      this.apiService.getAdmin(JSON.stringify(dataForForm)).subscribe((result: any) => {
        if (result.success) {
          console.log(result.getData);
          let adminData: any = result.getData;
          this.registerForm.patchValue({
            firstName: adminData.firstname,
            lastName: adminData.lastname,
            email: adminData.email,
            mobile: adminData.mobile,
            password: adminData.password,
            retypePassword: adminData.password
          });
        } else {
          this.toastr.error(result.message);
        }
      });
    } else {
      this.toastr.error('No Data Found');
    }
  }

  async updateAdmin(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    let editData = {
      firstname: this.registerForm.get("firstName").value,
      lastname: this.registerForm.get("lastName").value,
      mobile: this.registerForm.get("mobile").value
    };
    await this.apiService.updateAdminById(id, JSON.stringify(editData)).subscribe((result: any) => {
      console.log(result);
      if (result.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.success(result.message);
        this.router.navigate(['/admin-list']);
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.error(result.message);
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
}
