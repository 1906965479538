<div class="content">
    <div class="container-fluid">
          <div class="row">
                <div class="col-md-12">
                      <div class="card card-primary">
                            <div class="card-body">
                              <div class="row" >

                                    <!-- <div class="form-group col-md-3">
                                          <label for="class_name">Agent Name </label>
                                          <select class="form-control" #cat>
                                                <option value="" selected>Plan Name</option>
                                                <option *ngFor="let item of this.allUser
                                                ; let i = index" value="{{item.tour_id._id }}">
                                                      {{ item.tour_id.title }}</option>
                                          </select>
                                    </div> -->
                                    <div class="form-group col-md-3">
                                          <label for="class_name">Tour Name </label>
                                          <select class="form-control" #cat
                                                >
                                                <option value="" selected>Plan Name</option>
                                                <option *ngFor="let item of this.allUser
                                                ; let i = index" value="{{item.tour_id._id }}">
                                                      {{ item.tour_id.title }}</option>
                                          </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                          <label for="class_name">Month</label>
                                                      <select class="form-control"  #month>
                                                            <option value="" [selected]="true"
                                                                  >Select Month</option>
                                                            <option value="01">January</option>
                                                            <option value="02">February</option>
                                                            <option value="03">March</option>
                                                            <option value="04">April</option>
                                                            <option value="05">May</option>
                                                            <option value="06">June</option>
                                                            <option value="07">July</option>
                                                            <option value="08">August</option>
                                                            <option value="09">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                      </select>
                                    </div>
                                   
                                    <div class="col-md-1 d-flex align-items-center justify-content-center">
                                          <button type="submit" class="btn btn-info"
                                              >Search</button>
                                    </div>
                              </div>
                              <div class="row">
                                    <div class="col-12">
                                          <div class="card">
                                                <div class="card-body">

                                                      <table datatable [dtOptions]="dtOptions"
                                                            [dtTrigger]="dtTrigger" class="row-border hover">
                                                            <thead>
                                                                  <tr>
                                                                        <th>S. No.</th>
                                                                        <th>Name</th>
                                                                        <th>Mobile</th>
                                                                        <th>Tour Name</th>
                                                                        <!-- <th>Package Type</th> -->
                                                                        <th>Payment Status</th>
                                                                        <th>Date & Time</th>
                                                                        <th>Number Of Traveler</th>
                                                                        <th>Total Amount</th>
                                                                        <th>Amount Paid</th>
                                                                        <th>Remaining Amount</th>

                                                                        <th>Order History</th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody *ngIf="allUser?.length != 0">
                                                                  <tr *ngFor="let user of allUser; let i = index">
                                                                        <td>{{ i + 1 }}</td>
                                                                        <td>{{ user?.user_id?.firstname }} {{
                                                                              user?.user_id?.lastname }} </td>
                                                                        <td>{{user?.user_id?.mobile}}</td>
                                                                        <!-- <td>{{user.travel_boarding_point.boarding}}
                                                                        </td> -->
                                                                        <td>{{user?.tour_id?.title}}
                                                                        </td>

                                                                        <!-- <td>{{this.getPackage(user.travel_packageType)}}
                                                                        </td> -->
                                                                        <td>{{user?.payment_status}}</td>
                                                                        <td>{{user?.payment_time | date:"d/M/yy,
                                                                              h:mm a"}}</td>
                                                                        <td>{{user.travel_no_of_traveler}}</td>
                                                                        <td>
                                                                              {{(user.totalAmount +
                                                                              user.addons_id).toLocaleString('en-IN',
                                                                              {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2
                                                                              })}}
                                                                        </td>
                                                                        <td>{{(+user?.amountPaid).toLocaleString('en-IN',
                                                                              {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2
                                                                              })}}</td>

                                                                        <td
                                                                              *ngIf="(this.user.finalPrice/(this.user.partial_payment))-((this.user.finalPrice/(this.user.partial_payment))-user?.travel_no_of_traveler)===1 && user?.payment_status==='Cancelled';else Amt">
                                                                              0</td>
                                                                        <ng-template #Amt>
                                                                              <td>{{(+user?.totalAmount +
                                                                                    +user?.addons_id -
                                                                                    +user?.amountPaid).toLocaleString('en-IN',
                                                                                    {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                    })}} </td>
                                                                              <!-- <td >{{user.addons_id.addons_data[i].price? user.addons_id.addons_data[i].price : user.totalAmount - user.amountPaid}}</td> -->
                                                                        </ng-template>
                                                                        <td>
                                                                              <div class="btn-group">
                                                                                    <!-- <button type="button" class="btn btn-info" [routerLink]="['/user-order-history/'+data._id]">
                                                                                          Order History</button> -->
                                                                                    <button type="button"
                                                                                          class="btn btn-info"
                                                                                          [routerLink]="['/user-tour-details/'+user?.checkout_id]">
                                                                                          View More
                                                                                    </button>
                                                                              </div>
                                                                        </td>
                                                                  </tr>
                                                            </tbody>
                                                            <tbody *ngIf="allUser?.length == 0">
                                                                  <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <!-- <td></td> -->
                                                                        <td>No data!</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                  </tr>
                                                            </tbody>
                                                      </table>

                                                </div>
                                          </div>
                                    </div>
                              </div>
                                
                            </div>
                      </div>
                </div>
          </div>
    </div>
</div>
