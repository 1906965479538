import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-refund-cancel-order',
  templateUrl: './refund-cancel-order.component.html',
  styleUrls: ['./refund-cancel-order.component.scss'],
})
export class RefundCancelOrderComponent implements OnInit {
  public mobile_number: string = '';
  public tour_date: string = '';
  public refund_amt: string = '';
  public allTourByUser: any = [];
  public selected_tour: any = {};

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {}

  searchUser() {
    if (!this.mobile_number || this.mobile_number.length !== 10) {
      Swal.fire({
        text: 'Please enter valid mobile number',
        icon: 'error',
      });
      return;
    }
    this.apiService
      .getAppliedTourByUser({ mobile_num: this.mobile_number })
      .subscribe((res: any) => {
        //console.log(res)
        this.selected_tour = {};
        if (res.success) {
          this.allTourByUser = res.data;
          console.log(this.allTourByUser);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.allTourByUser = [];
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }

  onTourSelect(orderId: string) {
    if (orderId !== '') {
      this.selected_tour = this.allTourByUser.find(
        (order: any) => order?._id === orderId
      );
      if (this.selected_tour?.addons_id) {
        const tax = this.selected_tour?.isOuter ? +this.selected_tour?.igst : (+this.selected_tour?.cgst + +this.selected_tour?.sgst);
        this.selected_tour.addons_id.totalAddon = this.selected_tour.addons_id.addons_data.reduce(
          (total: number, addon: any) =>
            total + (addon.price * addon.quantity)
        , 0);
        // this.selected_tour.addons_id.paidAddon = this.selected_tour.addons_id.addons_data.reduce(
        //   (total: number, addon: any) => {
        //     if (addon.status === 'paid'){
        //       console.log(addon)
        //       return total + (addon.price * addon.quantity)
        //     }
        //   }
        // , 0) || 0;
        let paidAddon = 0
        let addon = this.selected_tour.addons_id.addons_data.filter((elem: any) => {
          if(elem.status == 'paid'){
            paidAddon += elem.price * elem.quantity
          }
        })
        console.log(addon, this.selected_tour, paidAddon)
        this.selected_tour.addons_id.paidAddon = paidAddon
        //this.selected_tour.addons_id.totalAddon = +(this.selected_tour.addons_id.totalAddon + this.selected_tour.addons_id.totalAddon * tax * 0.01).toFixed(2);
        //this.selected_tour.addons_id.paidAddon = +(this.selected_tour.addons_id.paidAddon + this.selected_tour.addons_id.paidAddon * tax * 0.01).toFixed(2);
        // console.log(this.selected_tour.addons_id);
      }
      // console.log(this.selected_tour);
    } else {
      this.selected_tour = {};
    }
  }

  submitRefund() {
    console.log(this.refund_amt);
    if (this.refund_amt.trim() === '') {
      Swal.fire({
        text: 'Please enter refund amount',
        icon: 'error',
      });
      return;
    }
    if (confirm(`Are you sure want to refund ₹${this.refund_amt.trim()}?`)) {
      this.apiService.putAppliedTourRefundAmt(this.selected_tour._id, { refund_amt: Number(this.refund_amt.trim()) }).subscribe((res: any) => {
          if (res.success) {
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/refund-list');
          } else {
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
      });
    }
  }

  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}
