import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-refund-list-tax',
  templateUrl: './refund-list-tax.component.html',
  styleUrls: ['./refund-list-tax.component.scss']
})
export class RefundListTaxComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  apiHost = environment.API_HOST;
  private category: string;
  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  constructor(private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.refundlist();
  }

  refundlist() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.allRefund().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  sendDataToGenerateInvoice(pdfUrl: any) {
    console.log(pdfUrl);
    
    if (pdfUrl?.pdf_url) {
      window.open(this.apiHost+pdfUrl.pdf_url, '_blank');
    } else {
      console.error('Invalid PDF URL.');
    }
  }

}
