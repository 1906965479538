<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    
                    <form [formGroup]="addRoomSharingForm" (ngSubmit)="addRooms()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Room Name <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="room_name"
                                            placeholder="Room Name">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card-body" style="margin-top: -42px;">
                            <div class="row">
                                <div class="col-md-4">

                                    <div class="custom-control custom-checkbox">
                                        <input id="male" type="checkbox" class="custom-control-input"
                                            formControlName="status"
                                            (change)="addRoomSharingForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                            [checked]="addRoomSharingForm.value.status == 'active'" />
                                        <label class="custom-control-label" for="male">Status</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card-footer text-right">
                            <button type="submit" class="btn btn-info">Submit</button>

                            <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                                [routerLink]="['/room-sharing-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>