<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <form [formGroup]="MenuForm" (ngSubmit)="setmenu()">
                        <div class="card-body">
                            <div class="row "   *ngFor="let group of additionalInfo.controls; let i = index;"
                                formArrayName="main_menu">
                                <div class="col-md-12 " #dap draggable="true">
                                    <div class="row form-group" [formGroupName]='i'>

                                        <div class="col-md-6 " >
                                            <button type="button" class="btn btn-info btn-lg btn-block"
                                                style="line-height: 1.1;" data-toggle="modal"
                                                data-target="#exampleModal">Add </button>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-success"
                                                (click)="addAdditionalInfo()"><i
                                                    class="fas fa-plus-circle"></i></button>&nbsp;
                                            <button type="button" class="btn btn-danger" *ngIf="i"
                                                (click)="removeAdditionalInfo(i)"><i
                                                    class="fas fa-minus-circle"></i></button>
                                        </div>
                                        <div class="col-md-2">

                                        </div>
                                        <div class="col-md-2">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <!-- <label for="class_name">Tour</label> -->
                                                    <select class="form-control" formControlName="menu" >
                                                        <option value="" selected>Category
                                                        </option>
                                                        <option value="home">Home</option>
                                                        <option value="about">About</option>
                                                        <option value="contact">Contact</option>
                                                        <!-- <option *ngFor="let item of alldesti; let i = index"
                                                                value="{{item._id}}">
                                                                {{item.title}} </option> -->
                                                    </select>

                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <h3 style="text-align: center;">or</h3>
                                            </div>
                                            <br>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <!-- <label for="class_name">Tour</label> -->
                                                    <ng-select placeholder="Select Boarding Point" [multiple]="true" formControlName="submneuArr">
                                                        <ng-option value="delhi">Delhi
                                                        </ng-option>
                                                        <ng-option  value="mumbai">Mumbai
                                                        </ng-option>
                                                        <ng-option value="goa">Goa
                                                        </ng-option>
                                                        <ng-option value="kashmir">Kashmir
                                                        </ng-option>
                                                        <ng-option value="jammu">Jammu
                                                        </ng-option>
                                                        <ng-option value="punjab">Punjab
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-dismiss="modal">Close</button>
                                            <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading"
                                            [color]="'info'" [disabled]="isAuthDisabled"></app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>
                            <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                                [routerLink]="['/special-tour-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>