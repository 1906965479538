<section class="content">
  <div class="row" >
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <a
            [routerLink]="['/coupan-assign']"
            class="btn btn-info float-right mb-3"
            >Assign Voucher</a
          >
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover"
          >
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Voucher Name</th>
                <th>User Assigned</th>
                <th>User Used</th>
                <!-- <th>Voucher Expiry Date</th> -->
                <!-- <th>Description</th> -->
                <!-- <th>Status</th> -->
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data.coupon_code }}</td>
                <td>
                  <div class="col-8 d-flex flex-column">
                    <ng-select *ngIf="data.user_assign.length > 0; else noassigneduser" class="w-100" [multiple]="false" placeholder="User Assigned">
                      <ng-option *ngFor="let userData of data.user_assign; index as i" [value]="userData._id">{{userData.firstname}}&nbsp;{{userData.lastname}}</ng-option>
                    </ng-select>
                    <ng-template #noassigneduser>
                      <div>
                        No Users
                      </div>
                    </ng-template>
                  </div>
                </td>
                <!-- <td *ngIf="data.isShow === 'Voucher'">Voucher</td>
                <td *ngIf="data.status === 'Private'">Private</td>
                <td *ngIf="data.status === 'Public'">Public</td> -->
                <td>
                  <div class="col-8 d-flex flex-column">
                    <ng-select *ngIf="data.user_unassign.length > 0; else nousersused" class="w-100" [multiple]="false" placeholder="User Used">
                      <ng-option *ngFor="let userData of data.user_unassign; index as i" [value]="userData._id">{{userData.firstname}}&nbsp;{{userData.lastname}}</ng-option>
                    </ng-select>
                    <ng-template #nousersused>
                      <div>No Users</div>
                    </ng-template>
                  </div>
                </td>
                <!-- <td>{{ data.coupon_expiry_date | date }}</td> -->
                <!-- <td>{{ blogs.title | titlecase }}</td>
                <td><div [innerHTML]="blogs.description"></div></td> -->
              <!-- <td *ngIf="data.status === 'active'">Active</td>
              <td *ngIf="data.status !== 'active'">Inactive</td> -->
                <!-- <td>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-info"

                      routerLink="/add-voucher/edit-voucher/{{ data._id }}"
                    ><i class="fas fa-pen"></i></button>
                    <button
                      *ngIf="data.status === 'active'"
                      type="button"
                      class="btn btn-success"
                      (click)="videolist(data._id, data.status)"
                    ><i class="far fa-check-circle"></i></button>
                    <button
                      *ngIf="data.status !== 'active'"
                      type="button"
                      class="btn btn-danger"
                      (click)="videolist(data._id, data.status)"
                    ><i class="fas fa-ban"></i></button>
                    <button
                    type="button"
                    class="btn btn-warning"
                    (click)="deletecoupon(data._id)"
                  ><i class="fas fa-trash-alt"></i></button>
                  </div>
                </td> -->
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td ></td>
                <td class="no-data-available">No data!</td>
                <td ></td>
                <td ></td>
                <!-- <td ></td> -->
                <!-- <td ></td> -->
                <!-- <td ></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
