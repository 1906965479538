import { Component, OnInit,  OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalCount: any;
  data1: any;
  count: any;
}
@Component({
  selector: 'app-enquiry-list',
  templateUrl: './enquiry-list.component.html',
  styleUrls: ['./enquiry-list.component.scss']
})

export class EnquiryListComponent implements OnInit {
  //dtOptions: DataTables.Settings = {};
  allEnquiry : any;
  tourData: any;
  public allData: any;
  private category: string;
  public getFinancialYear: Array<any> = [];
  status: string
  tourname: string;
  month: string;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  private isDtInitialized: boolean = false;
  value: boolean = false;
  showtour: boolean = false
  //private isDtInitialized: boolean = false;
  //dtTrigger: Subject<any> = new Subject<any>();
  //@ViewChild(DataTableDirective) dtElement: DataTableDirective;

  eq:boolean=true
  id: any;
  tourtype: string;
  domesticorInternational: string;
  destination: string = '';
  allDataEnuiry: any
  alldesti: any;
  selectFinancialyear: string;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) { 
    // this.formArray = new FormArray([]);
  }

  ngOnInit(): void {
    this.status = ""
    this.tourname = ""
    this.month = ""
    this.selectFinancialyear = ""
    this.tourtype = ""
    this.domesticorInternational = ""
    this.destination = ""
    this.category = this.router.url.split('/')[1];
    if (this.category === 'enquiry-list') {
      // this.editCatRoute = '/news/edit-news';
    }
    var yearsLength = 5;
    this.selectFinancialyear = ""
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      scrollX: true,
      dom: 'Brftip',
      buttons: [
        { extend: 'copyHtml5', footer: true, title: 'Glabol Admin - Enquiry list' },
        { extend: 'excelHtml5', footer: true, title: 'Glabol Admin - Enquiry list' },
        { extend: 'csvHtml5', footer: true, title: 'Glabol Admin - Enquiry list' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', title: 'Glabol Admin - Enquiry list' }
      ],
      footerCallback: function (row, data, start, end, display) {let api = this.api();
        let nb_cols = api.columns().nodes().length;
          let j = 6;
          while (j < nb_cols) {
            let pageTotal = api
              .column(j)
              .data()
              .reduce((a, b) => (Number(a === '-' ? '0' : a) + Number(b === '-' ? '0' : b)), 0);
            $(api.column(j).footer()).html(`₹${pageTotal.toFixed(2)}`);
            j++;

          }
      }

    };
    this.enquiry();
    this.desti();
    //this.alltour();
  }

  // alltour(){
  //   this.apiService.completetour().subscribe((resp: any) => {
  //   console.log(resp)
  //   this.tourData = resp.data
  //   });
  // }

  desti(){
    this.spinner.show()
    this.apiService.getAllEnquiryMaster().subscribe((resp: any) => {
        this.alldesti = resp
        console.log(this.alldesti)
        this.spinner.hide()
   });      
  }
                                              

  enquiry(){

    this.status = '';
    this.tourtype = '';
    this.domesticorInternational = '';
    this.destination = '';
    this.tourname = '';
    this.month = '';
    this.selectFinancialyear = '';
    this.showtour = false;
    this.alldesti = { allData: [] };
    this.spinner.show()
    this.apiService.getAllEnquiryMaster().subscribe((resp: any) => {
        this.allData = resp
        console.log(this.allData)
        this.desti();
        this.spinner.hide()
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.spinner.hide()
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
   });      
  }

  ontourtypeSelect(id: any,  name: string) {
    console.log('Selected Name:', name)
    this.destination = name
    if (!id || id === 'undefined' || id === 'null') {
      this.showtour = false;
      return;
    }
    this.spinner.show();
    this.apiService.getmainmenu(id).subscribe(
      (resp: any) => {
        if (resp.success) {
          this.tourData = resp.allData;
          this.showtour = true;
        } else {
          this.showtour = false;
        }
        this.spinner.hide();
      },
      (error) => {
        console.error('API call error:', error);
        this.spinner.hide();
      }
    );
  }
  

  serach(){
    if (this.status == undefined || this.status == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Please Select Status',
        icon: 'error',
      });
      return false;
    }  
    const alldate = {
      status: this.status,
      tour_id: this.tourname,
      traveler_month: this.month,
      financialYear:this.selectFinancialyear,
      tourtype: this.tourtype,
      domesticorInternational: this.domesticorInternational,
      destination : this.destination
    }
    this.spinner.show()
    this.apiService.addenquirysearch(alldate).subscribe((resp: any) => {
        this.allData = resp;
      if (this.isDtInitialized) {
        this.spinner.hide()
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.spinner.hide()
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
   });   
  }
  

  async openenquiry(){
    this.eq=true
    this.spinner.show()
   await this.apiService.getAllopenEnquiry().subscribe((resp: any) => {
        this.allData = resp;
      if (this.isDtInitialized) {
        this.spinner.hide()
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.spinner.hide()
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  // this.enquiry()
  }
   
  closeenquiry(){
    this.eq=false
    this.spinner.show()
    this.apiService.getAllcloseEnquiry().subscribe((resp: any) => {
          this.allData = resp;
      // this.allData =allData?.allData
      // console.log(allData,"nnnnnn");
        if (this.isDtInitialized) {
          this.spinner.hide()
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide()
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
    });
  }

  deleteenquiry(id:any){
    this.id=id
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to Close Status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
       if (result.isConfirmed) {
        const data ={
          id:this.id
        }
       this.apiService.deleteEnquiryById(data).subscribe((res: any) => {
        if (res.success) {
          Swal.fire({
              text: res.message,
              icon: 'success',
            });
          //this.toastr.success(res.message);
           this.enquiry();
        } else {
           Swal.fire({
              text: res.message,
              icon: 'error',
            });
          //this.toastr.error(res.message);
        }
      });
      // else {
      //   return;
      // }
    }
    });
  }

//   async deleteenquiry(id, status) {
//     this.id=id
//     console.log(this.id,'hiiiiiiiiiii');

//   if (id == undefined || id == '') {
//     //this.toastr.error(' Id is Invalid');
//     Swal.fire({
//       text: 'Id is Invalid',
//       icon: 'error',
//     });
//     return false;
//   }

//   Swal.fire({
//     title: 'Are you sure?',
//     text: 'Are you sure, you want to change status?',
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes',
//   }).then((result) => {
//     if (result.isConfirmed) {
//       let getStatus = {};
//       getStatus =
//         status === 'active' ? { status: 'Close' } : { status: 'Open' };
        
//       this.apiService
//         .deleteEnquiryById(id, JSON.stringify(getStatus))
//         .subscribe((result: any) => {
//           if (result.success) {
//             Swal.fire({
//               text: result.message,
//               icon: 'success',
//             });
//             //this.toastr.success(result.message);
//             this.enquiry();
//           } else {
//             Swal.fire({
//               text: result.message,
//               icon: 'error',
//             });
//             //this.toastr.error(result.message);
//           }
//         });
//     }
//   });
// }


  gettour(id){
      console.log(id);
      this.apiService.getEnquiryById(id).subscribe((res: any) => {
        // console.log(res);
      });
  }
}
  


