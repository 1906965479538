import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-user-orders',
  templateUrl: './add-user-orders.component.html',
  styleUrls: ['./add-user-orders.component.scss']
})
export class AddUserOrdersComponent implements OnInit {
   public readOnly: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

}
