import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-video-list',
  templateUrl: './add-video-list.component.html',
  styleUrls: ['./add-video-list.component.scss']
})
export class AddVideoListComponent implements OnInit, OnDestroy {
  mobileMediaName: string | null = null;
  selectedMediaFile: File | null = null;
  existingVideoData: any = null; // To hold the fetched video data
  dtOptions: DataTables.Settings = {};
  private category: string;
  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;

  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  _id: any;
  mobilevideo: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    if (this.category === 'add-video-list') {
      this.addLanName = 'Add Video';
      this.addLanRoute = '/add-video';
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.video(); // Load the video list
    this.getExistingVideo(); // Fetch existing video data
  }

  // Fetch existing video data (GET API)
  getExistingVideo() {
     this.apiService.getvideo().subscribe((resp: any) => {
      if (resp.success) {
        console.log(resp.data[0]._id);
        this._id = resp.data[0]._id ;
        
        this.existingVideoData = resp.data[0].video[0]; 
        this.mobileMediaName = this.existingVideoData.orgname; 
      } else {
        this.toastr.error('Failed to fetch video data');
      }
    });
  }

  
  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedMediaFile = input.files[0];
      this.mobileMediaName = this.selectedMediaFile.name;
      this.uploadMedia();
    }
  }

       // Send selected file (photo or video) to the API using ApiService
  uploadMedia(): void {
    if (this.selectedMediaFile) {
      const formData = new FormData();
      formData.append('uploadFile', this.selectedMediaFile);
      console.log('Uploading media:', formData);

      this.apiService.uploadimages(formData).subscribe(
        (response: any) => {
          console.log(response);

          console.log(response);

          this.mobilevideo = response.file_data[0]
          
          if (response.status == 1) {
            // this.toastr.success('Media uploaded successfully');
          } else {
            this.toastr.error('Error uploading media');
          }
        },
        (error) => {
          this.toastr.error('Error uploading media');
          console.error('Error:', error);
        }
      );
    } else {
      this.toastr.error('Please select a file to upload');
    }
  }


  uploadVideoMobile(id): void {
    if (this.selectedMediaFile) {
      // const formData = this.mobilevideo

      const video = {
        video : this.mobilevideo
      }
    

      this.apiService.updateMobileVideo(video,id).subscribe(
        (response: any) => {
          console.log(response);
          
          if (response.success = 'true') {
            this.toastr.success('Media uploaded successfully');
            this.getExistingVideo();
          } else {
            this.toastr.error('Error uploading media');
          }
        },
        (error) => {
          this.toastr.error('Error uploading media');
          console.error('Error:', error);
        }
      );
    } else {
      this.toastr.error('Please select a file to upload');
    }
  }

  // Reset the media file input
  resetMedia(): void {
    this.mobileMediaName = null;
    this.selectedMediaFile = null;
  }

  video() {
    this.apiService.videoList().subscribe((resp: any) => {
      this.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async videolist(id, status) {
    if (!id) {
      Swal.fire({ text: 'Id is Invalid', icon: 'error' });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const getStatus = status === 'active' ? { status: 'inactive' } : { status: 'active' };
        this.apiService.VideoStatus(id, JSON.stringify(getStatus)).subscribe((result: any) => {
          if (result.success) {
            Swal.fire({ text: result.message, icon: 'success' });
            this.video();
          } else {
            Swal.fire({ text: result.message, icon: 'error' });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
