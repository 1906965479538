<section class="content">
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <a
              [routerLink]="['/add-menu']"
              class="btn btn-info float-right mb-3"
              >Add Menu</a
            > -->
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <!-- <th>Description</th> -->
                  <th>Trip Name</th>
                  <th>Refund Amount</th>
                  <th>Refund Date</th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let data of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.user_id.firstname }} {{ data.user_id.lastname
                }}</td>
                <td>{{ data.user_id.mobile }}</td>
                <td>{{ data.tour_id.title }}</td>
                <td>{{data.refundAmount}}</td>
                <td>{{data.updated_at | date:'medium'}}</td>
                  <!-- <td>{{ blogs.title | titlecase }}</td>
                     <td colspan="4" class="no-data-available">No data!</td>
                  <td><div [innerHTML]="blogs.description"></div></td> -->

                </tr>
              </tbody>
              <tbody *ngIf="allData?.length == 0">
                <tr>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td>No data!</td>
                 <td></td>
                 <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
