<div class="card-body">
  <!-- <div class="col-md-3 text-end ">
    <input
      type="text"
      [(ngModel)]="searchQuery"
      (input)="onSearchChange(searchQuery)"
      placeholder="Search notifications..."
      class="form-control mb-3"
    />
  </div> -->

  <div class="row d-flex justify-content-end ">
    <div class="col-md-3 pr-3" >
      <input
        type="text"
        [(ngModel)]="searchQuery"
        (input)="onSearchChange(searchQuery)"
        placeholder="Search notifications..."
        class="form-control mb-3"
      />
    </div>
  </div>
  
  <div class="table-container" (scroll)="onScroll($event)" style="overflow-y: auto; height: 600px;">
    <table class="table table-bordered" style="width: 100%;">
      <thead>
        <tr>
          <th>S. No.</th>
          <th>Notification</th>
          <th>Time</th>
          <th>Order History</th>
        </tr>
      </thead>
      <tbody *ngIf="filteredNotifications?.length != 0">
        <tr *ngFor="let notification of filteredNotifications; let i = index">
           <td>{{ i + 1 }}</td> <!-- Adjust index based on current page -->
          <td class="notification-text">{{ notification.msg }}</td>
          <td>{{ notification.date | date: "dd/MM/yyyy, hh:mm a" }}</td>
          <td>
            <button
              type="button"
              class="btn btn-info"
              [routerLink]="['/user-order-history/particular-order/' + notification.id]"
            >
              Order History
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="filteredNotifications?.length == 0">
        <tr>
          <td colspan="4">No data!</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
