import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-checkout-notes',
  templateUrl: './add-checkout-notes.component.html',
  styleUrls: ['./add-checkout-notes.component.scss']
})
export class AddCheckoutNotesComponent implements OnInit {
   addNotesForm: FormGroup;
  public cancelCatRoute: string;
  private noteid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
     private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
     this.addNotesForm = new FormGroup({
      note: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });

    this.noteid = this.route.snapshot.paramMap.get('note_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.noteid != undefined &&
      this.noteid != null &&
      this.noteid != ''
    ) {
      this.editNotes(this.noteid);
    } else {
      this.addNotesForm.get('status').setValue('active');
    }
  }

  editNotes(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.NotesId(dataForForm).subscribe((result: any) => {
      console.log(result.getData,'helllllllllo');
      if (result.success) {
        this.addNotesForm.patchValue({
          note: result.getData.note,
        });
        if (result.getData.status == 'active') {
          this.addNotesForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addNotes() {
    if (
      this.addNotesForm.value.note == undefined ||
      this.addNotesForm.value.note.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter notes',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addNotesForm.value.this._userId == undefined ||
    //   this.addNotesForm.value.boarding.trim() == ''
    // )
   
    if (this.noteid != undefined && this.noteid != null && this.noteid != '') {
      this.updateNote(this.noteid);
    } else {
      this.addNotesForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .Notes(this.addNotesForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/add-checkout-notes-list');
          } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateNote(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .upNotes(this.addNotesForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/add-checkout-notes-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
