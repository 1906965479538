import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-coupan-code',
  templateUrl: './coupan-code.component.html',
  styleUrls: ['./coupan-code.component.scss'],
})
export class CoupanCodeComponent implements OnInit {
  addCoupanForm: FormGroup;
  private category: string;
  public minDate = undefined;
  private blog_id: any;
  public news_type: any;
  private coupanid: any;
  alldesti: any;
  Private: any;
  Public: any;
  private _userId: string = localStorage.getItem('id');
  public cancelCatRoute: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public allUsers: Array<any> = [];
  selectAllValue = 'select-all'; // Placeholder for the "Select All" option
allSelected = false; // Track if all options are selected

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    this.tour();
    this.addCoupanForm = new FormGroup({
      coupon_code: new FormControl(),
      tour: new FormControl(),
      coupon_description: new FormControl(),
      discount_type: new FormControl(),
      coupon_amount: new FormControl(),
      coupon_expiry_date: new FormControl(),
      percentage_fix_amount: new FormControl(),
      minimum_spend: new FormControl(),
      usage_limit_per_coupon: new FormControl(),
      usage_limit_per_user: new FormControl(),
      isShow: new FormControl(),
      status: new FormControl(),
      // user_assign: new FormControl(),
      //user_role: new FormControl(),
    });
    this.addCoupanForm = this.formBuilder.group({
      coupon_code: ['', [Validators.required]],
      coupon_description: ['', [Validators.required]],
      discount_type: ['', [Validators.required]],
      coupon_amount: ['', [Validators.required]],
      coupon_expiry_date: ['', [Validators.required]],
      percentage_fix_amount: [''],
      tour: [''],
      minimum_spend: ['', [Validators.required]],
      usage_limit_per_coupon: [''],
      usage_limit_per_user: [''],
      isShow: ['', [Validators.required]],
      status: ['', [Validators.required]],
      // user_assign: ['']
      //user_role: [[], [Validators.required]],
      //user_role: this.formBuilder.array([], [Validators.required]),

      //user: ['', [Validators.required]],
      // user: ['', Validators.requiredTrue]
    });
    this.coupanid = this.route.snapshot.paramMap.get('coupan_id');
    console.log('admin Idddddd', this.coupanid);
    if (
      this.coupanid != undefined &&
      this.coupanid != null &&
      this.coupanid != ''
    ) {
      this.edicoupanname(this.coupanid);
    } else {
      this.addCoupanForm.get('status').setValue('active');
      this.addCoupanForm.get('isShow').setValue('Public');
    }
    //this.tour();
    console.log(this.addCoupanForm.value)
    // this.User()
  }

  tour() {
    this.apiService.addcompleteActive().subscribe((res: any) => {
      console.log(res);
      this.alldesti = res.getData;
    });
  }
  
  toggleSelectAll() {
    
    if (this.allSelected) {
      this.addCoupanForm.get('tour').setValue([]); 
      this.allSelected = false; 
    } else {
      
      const allIds = this.alldesti.map(item => item._id);
      this.addCoupanForm.get('tour').setValue(allIds); 
      this.allSelected = true; 
    }
  }

  onTourChange(event: any) {
   
    if (event.includes(this.selectAllValue)) {
      this.toggleSelectAll(); 
    }
  }

  
  // User() {
  //   // this.spinner.show();
  //   this.apiService.allWebUser().subscribe((resp: any) => {
  //     // this.spinner.hide();
  //     let sortedUsers = resp.getData.sort((a, b) => {
  //       // console.log(a, b)
  //       return a.firstname.localeCompare(b.firstname)
  //     })
  //     // console.log(resp, sortedUsers);
  //     this.allUsers = sortedUsers;
  //   });
  // }
  edicoupanname(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.coupanBYID(dataForForm).subscribe((res: any) => {
      if (res.success) {
        console.log(res,'heeeloo');
        let festData: any = res.getData;
        let date = new Date(festData.coupon_expiry_date);
        const getDate = new Date(Date.parse(festData.coupon_expiry_date));
        console.log({
          day: getDate.getDate(),
          month: getDate.getMonth() + 1,
          year: getDate.getFullYear(),
        });
        //const selectedTourTypeIds = festData.tour.map(item => item._id);
        this.addCoupanForm.patchValue({ 
          coupon_code: festData.coupon_code,
          coupon_description: festData.coupon_description,
          discount_type: festData.discount_type,
          //coupon_expiry_date: festData.coupon_expiry_date,
          coupon_amount: festData.coupon_amount,
          percentage_fix_amount: festData.percentage_fix_amount,
          minimum_spend: festData.minimum_spend,
          usage_limit_per_coupon: festData.usage_limit_per_coupon,
          usage_limit_per_user: festData.usage_limit_per_user,
          tour: festData.tour,
          coupon_expiry_date: {
            day: getDate.getDate(),
            month: getDate.getMonth() + 1,
            year: getDate.getFullYear(),
          },
        });

        if (festData.isShow == 'Private') {
          this.addCoupanForm.get('isShow').setValue('Private');
        }

        if (festData.isShow == 'Public') {
          this.addCoupanForm.get('isShow').setValue('Public');
        }

        if(festData.isShow == 'Voucher'){
          this.addCoupanForm.get('isShow').setValue('Voucher');
        }

        //this.addCoupanForm.get('isShow');

        if (festData.status == 'active') {
          this.addCoupanForm.get('status').setValue('active');
        }
        //this.readOnly = true;

        // this.userRoleList.forEach((ele: any) => {
        //   if (res.getData[0].user_role?.includes(ele.value)) ele.check = true;
        // });
      }
    });
  }

  setCoupan() {
    

    if (
      this.addCoupanForm.value.tour == undefined ||
      this.addCoupanForm.value.tour == ''
    ) {
      Swal.fire({
        text: 'Please enter Tour',
        icon: 'warning',
      });
      return false;
    }
        if (
      this.addCoupanForm.value.coupon_code == undefined ||
      this.addCoupanForm.value.coupon_code.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter coupon code',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addCoupanForm.value.coupon_description == undefined ||
      this.addCoupanForm.value.coupon_description.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter coupon description',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addCoupanForm.value.discount_type == undefined ||
      this.addCoupanForm.value.discount_type == ''
    ) {
      Swal.fire({
        text: 'Please select discount type',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addCoupanForm.value.coupon_amount == undefined ||
      this.addCoupanForm.value.coupon_amount == ''
    ) {
      Swal.fire({
        text: 'Please enter coupon amount',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addCoupanForm.value.coupon_expiry_date == undefined ||
      this.addCoupanForm.value.coupon_expiry_date == ''
    ) {
      Swal.fire({
        text: 'Please enter coupon expiry date',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   this.addCoupanForm.value.maximum_spend == undefined ||
    //   this.addCoupanForm.value.maximum_spend == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter maximum spend',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      this.addCoupanForm.value.minimum_spend == undefined ||
      this.addCoupanForm.value.minimum_spend == ''
    ) {
      Swal.fire({
        text: 'Please enter minimum spend',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   this.addCoupanForm.value.usage_limit_per_coupon == undefined ||
    //   this.addCoupanForm.value.usage_limit_per_coupon == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter usage limit per coupon',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    // if (
    //   this.addCoupanForm.value.usage_limit_per_user == undefined ||
    //   this.addCoupanForm.value.usage_limit_per_user == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter usage limit per user',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    //  if (
    //   this.addCoupanForm.value.isShow == undefined ||
    //   this.addCoupanForm.value.isShow == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter minimum spend',
    //     icon: 'warning',
    //   });
    //   return false;
    //   ;
    // }

    if (this.addCoupanForm.status == 'active') {
      this.addCoupanForm.get('status').setValue('active');
    }
    let date = `${this.addCoupanForm.value.coupon_expiry_date.month}/${this.addCoupanForm.value.coupon_expiry_date.day}/${this.addCoupanForm.value.coupon_expiry_date.year}`;
    //console.log(date, 'ggggggggggg');
    this.addCoupanForm.value.coupon_expiry_date = date;
    //console.log(this.addCoupanForm.value.coupon_expiry_date,'dateeeeeeeeeeeee');
    console.log(this.addCoupanForm.value, 'hiiiiiiiiiii');
    // return;
    if (
      this.coupanid != undefined &&
      this.coupanid != null &&
      this.coupanid != ''
    ) {
      this.updateCoup(this.coupanid);
    } else {
      this.addCoupanForm.value.user_id = this._userId;
      // this.addCoupanForm.value._id = this.coupanid;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      //return;
      this.apiService
        .addCoupan(this.addCoupanForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/coupan-code-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }

  updateCoup(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updatecoupan(this.addCoupanForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/coupan-code-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}
