<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <form [formGroup]="addSmsForm" (ngSubmit)="addSms()">
            <div class="card-body">

              <div class="row">
                <!-- TO -->
                <div class="col-md-7">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">TO</span>
                      </div>
                      <input style="margin-right: -11px;" type="text" [formControl]="emailFormControl"
                        class="form-control" placeholder="Recipient Email">
                      <button class="custom-button" (click)="addEmail('to')"></button>

                    </div>
                    <div *ngFor="let email of toEmails; let i = index" class="email-capsule">
                      {{ email }}
                      <span class="remove-email" (click)="removeEmail('to', i)">X</span>
                    </div>
                  </div>
                </div>

                <!-- CC -->
                <div class="col-md-7">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">CC</span>
                      </div>
                      <input type="text" formControlName="cc" class="form-control" placeholder="Recipient Email"
                        (keydown.enter)="addEmail('cc', $event)">
                    </div>
                    <div *ngFor="let email of ccEmails; let i = index" class="email-capsule">
                      {{ email }}
                      <span class="remove-email" (click)="removeEmail('cc', i)">X</span>
                    </div>
                  </div>
                </div>

                <!-- BCC -->
                <div class="col-md-7">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">BCC</span>
                      </div>
                      <input type="text" formControlName="bcc" class="form-control" placeholder="Recipient Email"
                        (keydown.enter)="addEmail('bcc', $event)">
                    </div>
                    <div *ngFor="let email of bccEmails; let i = index" class="email-capsule">
                      {{ email }}
                      <span class="remove-email" (click)="removeEmail('bcc', i)">X</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Subject -->

              <div class="col-md-7">
                <div class="input-group-prepend">
                  <label for="to">Subject</label>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input type="text" id="subject" formControlName="subject" [(ngModel)]="subjects"
                      class="form-control" placeholder=" Subject" (input)="subject($event)">
                  </div>
                </div>
              </div>

              <!-- Messages -->
              <div class="form-group">
                <div class="input-group-prepend">
                  <label>Messages</label>
                </div>
                <div class="input-group">
                  <textarea type="text" class="form-control" formControlName="messages" placeholder="Enter your message"
                    [(ngModel)]="messages" (input)="messeges($event)"></textarea>
                </div>
              </div>

              <!-- Rest of your form elements -->

            </div>
            <div class="card-footer text-right">
              <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                [disabled]="isAuthDisabled" (click)="updateMail (id)"></app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                [routerLink]="['/promotional-sms-email-list']">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>