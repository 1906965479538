import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-voucher',
  templateUrl: './add-voucher.component.html',
  styleUrls: ['./add-voucher.component.scss']
})
export class AddVoucherComponent implements OnInit {
  addvocherForm: FormGroup;
  public minDate = undefined;
  private voucherid: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    this.addvocherForm = new FormGroup({
      coupon_code: new FormControl(),
      tour: new FormControl(),
      coupon_description: new FormControl(),
      discount_type: new FormControl(),
      coupon_amount: new FormControl(),
      coupon_expiry_date: new FormControl(),
      percentage_fix_amount: new FormControl(),
      minimum_spend: new FormControl(),
      usage_limit_per_coupon: new FormControl(),
      usage_limit_per_user: new FormControl(),
      isShow: new FormControl(),
      status: new FormControl(),
      // user_assign: new FormControl(),
      //user_role: new FormControl(),
    });
    this.addvocherForm = this.formBuilder.group({
      coupon_code: ['', [Validators.required]],
      coupon_description: [''],
      discount_type: ['', [Validators.required]],
      coupon_amount: ['', [Validators.required]],
      coupon_expiry_date: ['', [Validators.required]],
      percentage_fix_amount: [''],
      tour: [''],
      minimum_spend: ['', [Validators.required]],
      usage_limit_per_coupon: [''],
      usage_limit_per_user: [''],
      isShow: ['', [Validators.required]],
      status: ['', [Validators.required]],
      // user_assign: ['']
      //user_role: [[], [Validators.required]],
      //user_role: this.formBuilder.array([], [Validators.required]),

      //user: ['', [Validators.required]],
      // user: ['', Validators.requiredTrue]
    });
    this.voucherid = this.route.snapshot.paramMap.get('voucher_id');
    //console.log('admin Idddddd', this.voucherid);
    if (
      this.voucherid != undefined &&
      this.voucherid != null &&
      this.voucherid != ''
    ) {
      this.editvoucher(this.voucherid);
    } else {
      this.addvocherForm.get('status').setValue('active');
      //this.addvocherForm.get('isShow').setValue('Public');
    }
    //this.tour();
    //console.log(this.addvocherForm.value)
  }

  editvoucher(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.coupanBYID(dataForForm).subscribe((res: any) => {
      if (res.success) {
        //console.log(res,'heeeloo');
        let festData: any = res.getData;
        let date = new Date(festData.coupon_expiry_date);
        const getDate = new Date(Date.parse(festData.coupon_expiry_date));
        console.log({
          day: getDate.getDate(),
          month: getDate.getMonth() + 1,
          year: getDate.getFullYear(),
        });
        this.addvocherForm.patchValue({
          coupon_code: festData.coupon_code,
          coupon_description: festData.coupon_description,
          discount_type: festData.discount_type,
          //coupon_expiry_date: festData.coupon_expiry_date,
          coupon_amount: festData.coupon_amount,
          percentage_fix_amount: festData.percentage_fix_amount,
          minimum_spend: festData.minimum_spend,
          //usage_limit_per_coupon: festData.usage_limit_per_coupon,
          //usage_limit_per_user: festData.usage_limit_per_user,
          tour: festData.tour,
          coupon_expiry_date: {
            day: getDate.getDate(),
            month: getDate.getMonth() + 1,
            year: getDate.getFullYear(),
          },
        });
        if (festData.status == 'active') {
          this.addvocherForm.get('status').setValue('active');
        }
        //this.readOnly = true;

        // this.userRoleList.forEach((ele: any) => {
        //   if (res.getData[0].user_role?.includes(ele.value)) ele.check = true;
        // });
      }
    });
  }

  setCoupan() {
    if (
      this.addvocherForm.value.coupon_code == undefined ||
      this.addvocherForm.value.coupon_code.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter voucher name',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addvocherForm.value.discount_type == undefined ||
      this.addvocherForm.value.discount_type == ''
    ) {
      Swal.fire({
        text: 'Please select discount type',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addvocherForm.value.coupon_amount == undefined ||
      this.addvocherForm.value.coupon_amount == ''
    ) {
      Swal.fire({
        text: 'Please enter voucher amount',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addvocherForm.value.coupon_expiry_date == undefined ||
      this.addvocherForm.value.coupon_expiry_date == ''
    ) {
      Swal.fire({
        text: 'Please enter voucher expiry date',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   this.addvocherForm.value.maximum_spend == undefined ||
    //   this.addvocherForm.value.maximum_spend == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter maximum spend',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      this.addvocherForm.value.minimum_spend == undefined ||
      this.addvocherForm.value.minimum_spend == ''
    ) {
      Swal.fire({
        text: 'Please enter minimum spend',
        icon: 'warning',
      });
      return false;
    }
    if (this.addvocherForm.status == 'active') {
      this.addvocherForm.get('status').setValue('active');
    }
    let date = `${this.addvocherForm.value.coupon_expiry_date.month}/${this.addvocherForm.value.coupon_expiry_date.day}/${this.addvocherForm.value.coupon_expiry_date.year}`;

    this.addvocherForm.value.coupon_expiry_date = date;
    this.addvocherForm.value.isShow = 'Voucher';
    this.addvocherForm.value.usage_limit_per_user = '1';
    this.addvocherForm.value.usage_limit_per_coupon = '500';
    //return;
    if (
      this.voucherid != undefined &&
      this.voucherid != null &&
      this.voucherid != ''
    ) {
      this.updateCoup(this.voucherid);
    } else {
      this.addvocherForm.value.user_id = this._userId;
      // this.addvocherForm.value._id = this.voucherid;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      // console.log(this.addvocherForm.value, 'hiiiiiiiiiii');
      // return;
      this.apiService
        .addCoupan(this.addvocherForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: 'Voucher Add Sucessfully',
              icon: 'success',
            });
            this.router.navigateByUrl('/add-voucher-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }

  updateCoup(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updatecoupan(this.addvocherForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: 'Voucher Update Sucessfully',
            icon: 'success',
          });
          this.router.navigateByUrl('/add-voucher-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }

}
