<!-- <div style="margin:50px">
    <div #dap *ngFor="let data of HEROES" draggable="true" class="box">
      {{ data.name }}
    </div>
    <div #dap draggable="true" class="box">B</div>
    <div #dap draggable="true" class="box">C</div>
    <div #dap draggable="true" class="box">dev</div>
</div>

<div class="row">
  <div class="col-md-6">
    <p>Hii This Is Dev</p>
  </div>
  <div class="col-md-6">
    <p>Hii This Is Dev</p>
  </div>
  <div class="col-md-6">
    <p>Hii This Is Dev</p>
  </div>
  <div class="col-md-6">
    <p>Hii This Is Dev</p>
  </div>
</div>

<div class="row">
  <div #dap draggable="true" class="box">rohit</div>
  <div #dap draggable="true" class="box">piyush</div>
</div> -->

<!-- <button mat-raised-button (click)="fileInput.click()">Select File</button>

<input style="display: none"  #attachments type="file" (change)="onFileChanged($event)" #fileInput multiple="true">

<div *ngFor="let selected of listOfFiles;let index = index">
  <h3>{{selected}}</h3>
  <button mat-icon-button (click)="removeSelectedFile(index)">delete</button>
</div> -->

<div class="row">
  <div class="col-8">
    <label class="btn btn-default p-0">
      <input
        type="file"
        accept="image/*"
        multiple
        (change)="selectFiles($event)"
      />
    </label>
  </div>

  <form [formGroup]="tourForm" (ngSubmit)="submitTour()" >
  <div formArrayName="dateRanges">
    <div *ngFor="let dateRange of dateRanges.controls; let i = index" [formGroupName]="i">
      <div>
        <label>Start Date:</label>
        <input type="date" formControlName="startDate">
        <label>End Date:</label>
        <input type="date" formControlName="endDate">
        <button type="button" (click)="removeDateRange(i)">Remove</button>
      </div>
    </div>
  </div>
  <button type="button" (click)="addDateRange()" >Add Date Range</button>
  <button type="submit">Submit</button>
</form>

<!-- <div>
  <img *ngFor="let preview of previews;let index = index" />
  <h2>[src]="preview" </h2>
  <button mat-icon-button (click)="removeSelectedFile(index)">delete</button>
</div> -->
<!-- <div *ngFor="let selected of listOfFiles;let index = index">
  <h3>{{selected}}</h3>
  
</div>  -->