<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <div class="card-body">
            <div class="row" >
              <div class="form-group col-md-3">
                <label for="class_name">Tour Name <span class="required"></span></label>
                <select class="form-control" #cat (change)="onCategorySelect($event.target.value);">
                  <option value="" selected>Plan Name</option>
                  <option *ngFor="let item of allTour; let i = index" value="{{item._id }}">
                    {{ item.title }}</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="class_name">Agent Name <span class="required"></span></label>
                <select class="form-control"  (change)="onAgentSelect($event.target.value);">
                  <option value="" selected>Agent Name</option>
                  <option  *ngFor="let item of allData; let i = index"  value="{{ item._id }}" >
                    {{ item.firstname }} {{ item.lastname }}</option>
                </select>
              </div>

              <div class="form-group col-md-3" *ngIf="this.allDate">
                <div class="form-group">
                  <label class="title-label">Batch <span class="required"></span></label>
                  <select class="form-control" id="slct" #dateSchedule>
                    <option value="" selected="selected">Select Batch
                    </option>
                    <option value="{{ date._id }}" *ngFor="let date of this.allDate?.select_batch; index as i">
                      {{ date.start_date
                      | date:
                      'dd/MM/yy' }} - {{ date.end_date | date: 'dd/MM/yy' }} ({{date.batchNumber}})
                      &nbsp;&nbsp;
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 form-group">
                <div class="form-group">
                  <label for="class_name">Status<span class="required"></span></label>
                  <select class="form-control" #status>
                    <option value="" selected>All</option>
                    <option value="Complete">Complete</option>
                    <option value="Partial">Partial</option>
                    <!-- <option value="Cancelled">Cancel</option> -->
                  </select>

                </div>
              </div>


              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select</label>
                  <select class="form-control" #Select>
                    <option value="" selected>Select Domestic/International</option>
                    <option value="domestic">Domestic</option>
                    <option value="international">International</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Tour Type</label>
                  <select class="form-control" #Tour_Type >
                    <option value="" selected>Select Tour Type</option>
                    <option value="normal">Normal</option>
                    <option value="customized">Customized</option>
                  </select>
                </div>
              </div>


              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Month</label>
                  <select class="form-control" #Month>
                    <option value="" selected>Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label for="class_name">Select Financial Year <span class="required"></span></label>
                <select class="form-control" [(ngModel)]="selectFinancialyear">
                  <option value="" [selected]="true" [disabled]="true">Select Year</option>
                  <option value={{year}} *ngFor="let year of getFinancialYear; let i = index">{{year}}</option>
                </select>
              </div>

              <div class="col-md-1 d-flex align-items-center justify-content-center mt-3">
                <button type="submit" class="btn btn-info" (click)="userreport()">Report</button>
              </div>
            </div>
         
              
                <!-- <div class="col-md-2 d-flex align-items-center justify-content-start">
                  <button type="submit" class="btn btn-info mt-3" (click)="financialList()">Financial Search</button>
                </div> -->
             
           
            <!-- <div>
              <button (click)="value = !value" class="btn btn-info mb-3">{{value? 'Plan Search':'Financial Search'}}</button>
            </div> -->
            <br>
            <button class="btncsv" (click)="exportToCSV()">CSV</button>

            <button class="btncsv" (click)="exportToExcel()">Excel</button>

            <button class="btncsv" (click)="exportToPDF()">PDF</button>
            <!-- <button (click)="exportToExcel()">Export to Excel</button> -->
            <div class="table-container" (scroll)="onScroll($event)">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
              
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>User Name</th>
                    <th>Tour Name</th>
                    <th>Tour User No.</th>
                    <th>Mobile Number</th>
                    <th>Date & Time</th>
                    <th>Agent Name</th>
                    <th>Amount</th>
                    <th>Amount Paid</th>
                    <th>remaining Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                    </tr>
  
                </thead>
                <tbody *ngIf="response?.length !== 0">
                  <ng-container *ngFor="let data of response; let i = index">
                    <tr>
                      <td>{{ i + 1 }}</td>
                      <td>{{ data?.user_id?.firstname }}</td>
                      <td>{{ data?.tour_id?.title }}</td>
                      <td>{{ data?.old_travel_no_of_traveler }}</td>
                      <td>{{ data?.user_id?.mobile }}</td>
                      <td>{{ data?.payment_time | date: "d/M/yy, h:mm a" }}</td>
                      <td>
                        {{
                          (data?.bookedBy?.firstname || data?.bookedBy?.lastname)
                          ? (data?.bookedBy?.firstname || 'N/A') + ' ' + (data?.bookedBy?.lastname || 'N/A')
                          : 'N/A'
                        }}
                      </td>
                      <td>{{ data?.finalPrice }}</td>
                      <td>{{ data?.amountPaid }}</td>
                      <td>{{ data?.remainingAmount }}</td>

                      <td>{{ data?.status }}</td>
                      <td>
                        <div class="btn-group">
                          <button type="button" class="btn btn-outline-warning" 
                                  data-toggle="modal" data-target="#exampleModal"
                                  (click)="User(data?.user_id?._id, data?.tour_id?._id, 
                                                 data?.old_travel_no_of_traveler, 
                                                 data?.travel_packageType_data, 
                                                 data?.travel_roomSharing_data, 
                                                 data?.travel_boarding_point?.boarding, 
                                                 data?._id)">
                            User Details
                          </button>
                        </div>
                      </td>
                    </tr>
                
                    <!-- Traveler Details Section -->
                    <tr *ngIf="data.userTourDetail && data.userTourDetail.length > 0">
                      <td colspan="8" style="    overflow: scroll;">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Traveler Name</th>
                              <th>Gender</th>
                              <th>DOB</th>
                              <th>Mobile</th>
                              <th>Email</th>
                              <th>Room Sharing</th>
                              <th>packageType</th>
                              <th>City</th>
                              <th>Country</th>
                              <th>Pincode</th>
                              <th>State</th> 
                            <th>Id Proof</th>
                              <th>Id Number</th>
                              <th>Age</th>
                              <th>Boarding Point</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let traveler of data.userTourDetail[0]?.travelers;let j=index">
                              <tr>
                                <td>{{j+1 }}</td>
                                <td>{{ traveler?.name }}</td>
                                <td>{{ traveler?.gender }}</td>
                                <td>{{ traveler?.DOB }}</td>
                                <td>{{ traveler?.mobile }}</td>
                                <td>{{ traveler?.email }}</td>
                                <td>{{ traveler?.roomSharing }}</td>
                                <td>{{ traveler?.packageType }}</td>
                                <td>{{ traveler?.city }}</td>
                                <td>{{ traveler?.country }}</td>
                                <td>{{ traveler?.pincode }}</td>
                                <td>{{ traveler?.state }}</td> 
                                <td>{{ traveler?.Idproof }}</td>
                                <td>{{ traveler?.IdNumber }}</td>
                                <td>{{ traveler?.age }}</td>
                                <td>{{ traveler?.boarding_point }}</td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                
                    <!-- Display length if no traveler details -->
                    <tr *ngIf="!data.userTourDetail || data.userTourDetail.length === 0">
                      <td colspan="8">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Traveler Name</th>
                              <th>Gender</th>
                              <th>DOB</th>
                              <th>Mobile</th>
                              <th>Email</th>
                              <th>Room Sharing</th>
                              <th>packageType</th>
                              <th>City</th>
                              <th>Country</th>
                              <th>Pincode</th>
                              <th>State</th>
                              <th>Id Proof</th>
                              <th>Id Number</th>
                              <th>Age</th>
                              <th>Boarding Point</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let traveler of check(data?.old_travel_no_of_traveler);let k=index">
                              <tr>
                                <td>{{k+1 }}</td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                
                <tbody *ngIf="allData?.length == 0">
                  <tr>
                    <td colspan="3" class="no-data-available">No data!</td>
                  </tr>
                </tbody>
              </table>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
          <div class="modal-body">
            <div formArrayName="travelers" *ngFor="let traveler of travelersFormArray.controls; let i = index">
              <div class="accordion" id="accordionExample">

                <div [formGroupName]="i" class="card">
                  <div class="card-header" id="heading{{i}}">
                    <h2 class="mb-0">
                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                        [attr.data-target]="'#collapseOne'+i" aria-expanded="true"
                        [attr.aria-controls]="'collapseOne'+i">
                        Traveller {{ i + 1 }} Details
                      </button>
                    </h2>
                  </div>
                  <div [id]="'collapseOne'+i" class="collapse" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body">
                      <div class="form-row">

                        <div class="form-group  col-md-3">
                          <label for="name">Full Name</label>
                          <input type="text" class="form-control" formControlName="name" placeholder="Name">
                        </div>
                        <div class="form-group col-md-3">
                          <label for="birth">Date Of Birth</label>
                          <input type="date" class="form-control" (change)="calculateAgeForTraveler(i)" [min]="minDate" placeholder="yyyy-mm-dd" formControlName="DOB">
                        </div>
                        
                        <div class="form-group col-md-3">
                          <label>Age</label>
                          <input type="number" class="form-control" placeholder="Age" formControlName="age" readonly>
                        </div>
  

                        <div class="form-group  col-md-3">
                          <label>Mobile No.</label>
                          <input type="text" class="form-control" formControlName="mobile" maxlength="10"
                            (keypress)="onlyNumberKey($event)" placeholder="Mobile No.">
                        </div>

                        <div class="form-group  col-md-3">
                          <label for="email">Email Id</label>
                          <input type="text" class="form-control" formControlName="email" placeholder="Email Id">
                        </div>

                        <div class="form-group col-md-3">
                          <label for="class_name">Country <span class="required"></span></label>
                          <select class="form-control" formControlName="country">
                            <option value="" selected>Select Country
                            </option>
                            <option *ngFor="let country of country?.Data; let i = index"
                              value="{{country.countryName}}">
                              {{country.countryName}} </option>
                          </select>


                        </div>

                        <div class="form-group  col-md-3">
                          <label for="state">State</label>
                          <input type="text" class="form-control" formControlName="state" placeholder="State">
                        </div>

                        <div class="form-group  col-md-3">
                          <label for="pincode">Pin Code</label>
                          <input type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                            formControlName="pincode" placeholder="Pin Code">
                        </div>

                        <div class="form-group  col-md-3">
                          <label for="City">City</label>
                          <input type="text" class="form-control" formControlName="city" placeholder="City">
                        </div>

                        <div class="form-group col-md-3">
                          <label>ID Proof</label>
                          <select class="form-control" formControlName="Idproof"
                            (ngModelChange)="onIdProofChange($event)">
                            <option value="" selected>Select ID Proof</option>
                            <option value="adharcard">Adharcard</option>
                            <option value="pancard">Pancard</option>
                            <option value="drivinglicence">Driving Licence</option>
                          </select>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Package
                              Type</label>
                            <select class="form-control" formControlName="packageType">
                              <option value="" selected>Select Package
                                Type</option>
                              <option *ngFor="let package of myData" [value]="package.packType">{{
                                package.packType }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Room
                            Sharing</label>
                          <select class="form-control" formControlName="roomSharing">
                            <option value="" selected>Select Room
                              Type</option>
                            <option *ngFor="let room of roomdata" [value]="room.roomType">{{
                              room.roomType }}</option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Boarding
                            Point</label>
                          <select class="form-control" formControlName="boarding_point">
                            <option value="" selected>Select Boarding
                              Type</option>
                            <option [value]="this.boardingdata">{{ this.boardingdata }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="ID">ID Number</label>
                          <input type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                            formControlName="IdNumber"
                            placeholder="Enter Adhara card, Driving license, or PAN card number" maxlength="12"
                            #idNumberInput>
                        </div>
                        <div class="form-group col-md-3">
                          <label #importFestImg for="Document_Front">Upload Doc. (Front)</label><br>
                          <div class="file-upload">
                            <input type="file" (change)="onFileChange($event, i)" accept=".jpg, .jpeg, .png, .pdf"
                              name="Document_Front">
                            <img [src]="apiHost + (fornviewID && fornviewID[i] ? fornviewID[i] : '')"
                              *ngIf="fornviewID && fornviewID[i] !== 'null'" alt="Uploaded Image">
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="aadhaar">Upload Doc. (Back)</label><br>
                          <div class="file-upload">
                            <input type="file" (change)="onFileChangeback($event, i)" accept=".jpg, .jpeg, .png, .pdf"
                              name="Document_Back">
                            <img [src]="apiHost + (backviewID && backviewID[i] ? backviewID[i] : '')"
                              *ngIf="fornviewID && fornviewID[i] !== 'null'" alt="Uploaded Image">
                          </div>
                        </div>

                      </div>
                      <button type="button" class="btn btn-outline-warning" class="submit"
                        (click)="onSubmit()">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>