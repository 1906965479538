import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adminlte';

  constructor() {
    let info:any={"institute_id":"5fd66187265b4e431478609f", "institute_name":"easyeval"}
    sessionStorage.setItem('staffSession',JSON.stringify(info));
}
}
