import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-landing-detail',
  templateUrl: './view-landing-detail.component.html',
  styleUrls: ['./view-landing-detail.component.scss']
})
export class ViewLandingDetailComponent implements OnInit {
  private landing_id: string;
  public allLandingData: any = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(paramData => {
      console.log(paramData?.landing_id);
      this.landing_id = paramData?.landing_id;
      this.getLandingData();
    });
  }

  getLandingData() {
    this.apiService.getLandingData(this.landing_id).subscribe((res: any) => {
      if (res.success) {
        this.allLandingData = res.data;
        console.log(this.allLandingData);
      } else {
        console.log(res);
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }

  formateDate(getDate: string) {
    if (getDate)
      return `${getDate.split("-")[2]}-${getDate.split("-")[1]}-${getDate.split("-")[0]}`;
    else
      return "-";
  }
}
