import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu-mapping',
  templateUrl: './menu-mapping.component.html',
  styleUrls: ['./menu-mapping.component.scss']
})
export class MenuMappingComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private category: string;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;
  allData: any = [
    {name: 'Top Menu', value: 'top-menu'},
    {name: 'Header Menu', value: 'header-menu'},
    {name: 'Footer Menu', value: 'footer-menu'},
    {name: 'Bottom Menu', value: 'bottom-menu'}
  ]

  private isDtInitialized: boolean = false;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    //console.log(this.allData)
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
