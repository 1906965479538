<aside #mainSidebar class="main-sidebar elevation-4 sidebar-dark-info">
	<!-- Brand Logo -->
	<a [routerLink]="['/']" class="brand-link navbar-info">
		<img src="assets/img/cropped-glabol-192x192.png" alt="AdminLTE Logo"
			class="brand-image img-circle elevation-3" style="opacity: 0.8" />
		<span class="brand-text font-weight-bolder">&nbsp;Glabol</span>
	</a>
	<!-- Sidebar -->
	<div class="sidebar">
		<!-- Sidebar user (optional) -->
		<div class="user-panel mt-3 pb-3 mb-3 d-flex">
			<div class="image">
				<img src="assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
				
			</div>
			<div class="info d-block" style="color:#c2c7d0;">
				{{userInfo?.firstname + " " + userInfo?.lastname}}
				<!-- <a [routerLink]="['/profile']" class="d-block">{{userData?.firstname + " " + userData?.lastname}}</a> -->
			</div>
		</div>
		<!-- Sidebar Menu -->
		<nav class="mt-2">
			<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
				<!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
				<li class="nav-item">
					<a [routerLink]="['/']" class="nav-link">
						<i class="nav-icon fas fa-tachometer-alt"></i>
						<p>Dashboard</p>
					</a>
				</li>
				


				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("admin_registration")' class="nav-item has-treeview">
					<a href="#" class="nav-link"  (click)="toggle('admin')"  >
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-user-tie"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Add Admin<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview"[class.show]="isActive('admin')">
						<li class="nav-item">
							<a [routerLink]="['/super-admin']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/super-admin-list']" class="nav-link">
								<p class="submenu">Admin List</p>
							</a>
						</li>
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("web_user")' class="nav-item has-treeview" >
					<a href="#" class="nav-link" (click)="toggle('web')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-user"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Web User<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('web')">
					
						<li class="nav-item">
							<!-- <a [routerLink]="['/add-user-list']" class="nav-link">
								<p class="submenu">All User</p>
							</a> -->

							<a (click)="navigate('/add-user-list')" class="nav-link">
								<p class="submenu">All User</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("web_user")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('notification')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-bell"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Notification<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview"[class.show]="isActive('notification')">
						<li class="nav-item">
							<a [routerLink]="['/notifications']" class="nav-link">
								<p class="submenu">All Notification</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("web_user")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('reminder')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon 	fas fa-exclamation-circle"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Reminder<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('reminder')">
						<li class="nav-item">
							<a [routerLink]="['/user-reminder']" class="nav-link">
								<p class="submenu">All Reminder</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("order_pay_link")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('paylink')">
						<i class="nav-icon fas fa-money-check"></i>
						<p>Pay Link Order<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('paylink')">
						<li class="nav-item">
							<a [routerLink]="['/order-pay-link-list']" class="nav-link">
								<p class="submenu">Order With Pay Link</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("promotional_sms_email")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('promotional')">

						<i class="nav-icon fa fa-envelope"></i>

						<p>Promotional <i class="fas fa-angle-right right"></i></p>
					</a>
					<ul class="nav nav-treeview" [class.show]="isActive('promotional')">
						<li class="nav-item">
							<a [routerLink]="['/promotional-sms-email']" class="nav-link">
								<p class="submenu">Promotional SMS/Email</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/promotional-sms-email-list']" class="nav-link">
								<p class="submenu">Promotional List</p>
							</a>
						</li>
					</ul>
				</li>
				
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("report")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('report')">
						<!-- <i class="fa-solid fa-file-excel"></i> -->
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-file-excel"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Report<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('report')">
						<li class="nav-item">
							<a [routerLink]="['/report']" class="nav-link">
								<p class="submenu">Report</p>
							</a>
						</li>

						<!-- <li class="nav-item">
							<a [routerLink]="['/agent-booking-order']" class="nav-link">
								<p class="submenu">Agent Report</p>
							</a>
						</li> -->


						<li class="nav-item">
							<a [routerLink]="['/enquiry-list']" class="nav-link">
								<p class="submenu">Enquiry Report</p>
							</a>
						</li>

						<li class="nav-item">
							<a [routerLink]="['/tour-user-details']" class="nav-link">
								<p class="submenu"> User Travel Report</p>
							</a>
						</li>

						<li class="nav-item">
							<a [routerLink]="['/travelled-report']" class="nav-link">
								<p class="submenu">Travelled Report</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/booked-report']" class="nav-link">
								<p class="submenu">Booked Report</p>
							</a>
						</li>




					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("tax_list")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('taxdetails')">
						<i class="nav-icon fas fa-hand-holding-usd"></i>
						<p>Tax Details<i class="fas fa-angle-right right"></i></p>
					</a>
					<ul class="nav nav-treeview" [class.show]="isActive('taxdetails')">
						<li class="nav-item">
							<a [routerLink]="['/tax-list']" class="nav-link">
								<p class="submenu">All Tax List</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("landing_page_details")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('landingpage')">
						<i class="nav-icon fas fa-ad"></i>
						<p>Landing Page Details<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('landingpage')">
						<li class="nav-item">
							<a [routerLink]="['/landing-page-details']" class="nav-link">
								<p class="submenu">All Details</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_coupan")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('coupon')">
						<i class="nav-icon far fa-clipboard"></i>
						<p>Add Coupon<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('coupon')">
						<li class="nav-item">
							<a [routerLink]="['/coupan-code']" class="nav-link">
								<p class="submenu">Add Coupon</p>
							</a>
						</li>


						<li class="nav-item">
							<a [routerLink]="['/coupan-code-list']" class="nav-link">
								<p class="submenu">Coupon List</p>
							</a>
						</li>

						<!-- <li class="nav-item">
							<a [routerLink]="['/coupan-assign']" class="nav-link">
								<p class="submenu">Assign Coupon</p>
							</a>
						</li> -->

					</ul>
				</li>

              

				<!-- <li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_coupan")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('enquiry')">
						<i class="nav-icon fa fa-question"></i>
						<p>Enquiry<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('enquiry')">
						<li class="nav-item">
							<a [routerLink]="['/enquiry-list']" class="nav-link">
								<p class="submenu">Enquiry List</p>
							</a>
						</li>


						

					</ul>
				</li> -->

				<!-- <li class="nav-item">
							<a [routerLink]="['/enquiry-detail']" class="nav-link">
								<p class="submenu">Enquiry Detail</p>
							</a>
						</li> -->




				<!-- <li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_coupan")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('userdetails')">
						<i class="nav-icon far	fas fa-file-alt"></i>
						<p>Tour User Details<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('userdetails')">
						<li class="nav-item">
							<a [routerLink]="['/tour-user-details']" class="nav-link">
								<p class="submenu">Tour User Details</p>
							</a>
						</li>
					</ul>
				</li> -->




				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_coupan")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('voucher')" >
						<i class="nav-icon far fa-clipboard"></i>
						<p>Add Voucher<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('voucher')">
						<li class="nav-item">
							<a [routerLink]="['/add-voucher']" class="nav-link">
								<p class="submenu">Add Voucher</p>
							</a>
						</li>


						<li class="nav-item">
							<a [routerLink]="['/add-voucher-list']" class="nav-link">
								<p class="submenu">Voucher List</p>
							</a>
						</li>

						<li class="nav-item">
							<a [routerLink]="['/coupan-assign']" class="nav-link">
								<p class="submenu">Assign Voucher</p>
							</a>
						</li>

						<li class="nav-item">
							<a [routerLink]="['/voucher-user-list']" class="nav-link">
								<p class="submenu">User List</p>
							</a>
						</li>

					</ul>
				</li>

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_notes")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('nots')">

						<i class="nav-icon far fa-sticky-note"></i>

						<p>Add Notes<i class="fas fa-angle-right right"></i></p>
					</a>
					<ul class="nav nav-treeview" [class.show]="isActive('nots')">
						<!-- <li class="nav-item">
							<a [routerLink]="['/add-checkout-notes']" class="nav-link">
								<p class="submenu">Add Notes</p>
							</a>
						</li> -->
						<li class="nav-item">
							<a [routerLink]="['/add-checkout-notes-list']" class="nav-link">
								<p class="submenu">Notes List</p>
							</a>
						</li>

					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("blog")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('blog')">

						<i class="nav-icon fas fa-clipboard-list"></i>

						<p>Blog<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('blog')">
						<li class="nav-item">
							<a [routerLink]="['/blog']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/blog-list']" class="nav-link">
								<p class="submenu">Blog List</p>
							</a>
						</li>
					
						<!-- <li class="nav-item">
							<a [routerLink]="['/tour-user-details']" class="nav-link">
								<p class="submenu">Tour User Details</p>
							</a>
						</li> -->
						
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("boarding_point")' class="nav-item has-treeview">
					<a href="#" class="nav-link"(click)="toggle('bordingpoint')">

						<i class="nav-icon fas fa-tasks"></i>

						<p>Boarding Point<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('bordingpoint')">
						<li class="nav-item">
							<a [routerLink]="['/boarding-point']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/boarding-point-list']" class="nav-link">
								<p class="submenu">Boarding List</p>
							</a>
						</li>
					</ul>
				</li>

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("label_point")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('lable')">

						<i class="nav-icon fas fa-list-ul"></i>

						<p>Lable <i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('lable')">
						<li class="nav-item">
							<a [routerLink]="['/lable']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/lable-list']" class="nav-link">
								<p class="submenu">Lable List</p>
							</a>
						</li>
					</ul>
				</li>

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("package_type")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('packtype')">

						<i class="nav-icon fas fa-archive"></i>

						<p>Package Type<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('packtype')">
						<li class="nav-item">
							<a [routerLink]="['/package-type']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/package-type-list']" class="nav-link">
								<p class="submenu">Package List</p>
							</a>
						</li>
						<!-- <li class="nav-item">
							<a [routerLink]="['/bike-options-list']" class="nav-link">
								<p class="submenu">Bike Option List</p>
							</a>
						</li> -->
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("adventure")'  class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('adventure')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-clipboard-list"></i>
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Adventure <i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('adventure')">
						<li class="nav-item">
							<a [routerLink]="['/adventure-type']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/adventure-type-list']" class="nav-link">
								<p class="submenu">Adventure List</p>
							</a>
						</li>
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("destination")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('destination')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-map-marked-alt"></i>
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Destination<i class="fas fa-angle-right right"></i></p>
					</a>
 
					<ul class="nav nav-treeview" [class.show]="isActive('destination')">
						<li class="nav-item">
							<a [routerLink]="['/destination-city']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/destination-city-list']" class="nav-link">
								<p class="submenu">Destination List</p>
							</a>
						</li>
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("special_tour")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('specialtour')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-camera"></i>
						<!-- <i class="fas fa-camera"></i> -->
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Special Tour<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('specialtour')">
						<li class="nav-item">
							<a [routerLink]="['/special-tour']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/special-tour-list']" class="nav-link">
								<p class="submenu">Special Tour List</p>
							</a>
						</li>
					</ul>
				</li>
				<!-- =================== -->

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("room_sharing")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('roomsharing')">
						<!-- <i class="fas fa-chevron-circle-left"></i> -->
						<i class="nav-icon fas fa-hotel"></i>
						<!-- <i class="fas fa-camera"></i> -->
						<!-- <i class="fas fa-map-marked-alt"></i> -->
						<!-- <i class="fas fa-clipboard-list"></i> -->
						<p>Room Sharing<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('roomsharing')">
						<li class="nav-item">
							<a [routerLink]="['/room-sharing']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/room-sharing-list']" class="nav-link">
								<p class="submenu">Room Sharing List</p>
							</a>
						</li>
					</ul>
				</li>

				<!-- =================== -->
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("make_trip")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('maketour')">

						<i class="nav-icon fas fa-plane-departure"></i>

						<p>Make a Tour<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('maketour')">
						<li class="nav-item">
							<a [routerLink]="['/make-trip']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/make-trip-list']" class="nav-link">
								<p class="submenu">Tour List</p>
							</a>
						</li>
						<!-- <li class="nav-item">
							<a [routerLink]="['/test']" class="nav-link">
								<p class="submenu">Test</p>
							</a>
						</li> -->
					</ul>
				</li>
				
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("make_trip")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('addmenu')">

						<i class="nav-icon fas fa-home"></i>

						<p>Add Menu<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('addmenu')">
						<!-- <li class="nav-item">
							<a [routerLink]="['/add-menu']" class="nav-link">
								<p class="submenu">Add</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-menu-list']" class="nav-link">
								<p class="submenu">Menu List</p>
							</a>
						</li> -->
						<li class="nav-item">
							<a [routerLink]="['/add-menu-master']" class="nav-link">
								<p class="submenu">Destination Menu</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-menu-master-list']" class="nav-link">
								<p class="submenu">Destination Menu List</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-menu-master-new']" class="nav-link">
								<p class="submenu">Menu Submenu Master</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-menu-master-list-new']" class="nav-link">
								<p class="submenu">Menu Submenu List</p>
							</a>
						</li>
						
						
						<!-- <li class="nav-item">
							<a [routerLink]="['/menu-mapping']" class="nav-link">
								<p class="submenu">Mapping</p>
							</a>
						</li> -->
						<!-- <li class="nav-item">
							<a [routerLink]="['/test']" class="nav-link">
								<p class="submenu">Test</p>
							</a>
						</li> -->
					</ul>
				</li>
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("other_charge")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('othercharges')">
						<!-- <i class="fas fa-coins"></i> -->

						<i class="nav-icon fas fa-coins"></i>

						<p>Other Charge<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('othercharges')">
						<li class="nav-item">
							<!-- <a [routerLink]="['/other-charges']" class="nav-link">
								<p class="submenu">Add</p>
							</a> -->
						</li>
						<li class="nav-item">
							<a [routerLink]="['/other-charges-list']" class="nav-link">
								<p class="submenu">Other Charge List</p>
							</a>
						</li>
					</ul>
				</li>

				<!-- =================== -->
				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("refund")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('refund')">
						<i class="nav-icon fas fa-wallet"></i>
						<p>Refund<i class="fas fa-angle-right right"></i></p>
					</a>
					<ul class="nav nav-treeview" [class.show]="isActive('refund')">
						<li class="nav-item">
							<a [routerLink]="['/refund-canceled-order']" class="nav-link">
								<p class="submenu">Refund Canceled Order</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/refund-list']" class="nav-link">
								<p class="submenu">Refund List</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/refund-tax-list']" class="nav-link">
								<p class="submenu">Refund Tax List</p>
							</a>
						</li>
					</ul>
				</li>
				<!-- =================== -->

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_gallery")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('addgallery')">

						<i class="nav-icon fas fa-image"></i>

						<p>Add Gallery<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('addgallery')">
						<li class="nav-item">
							<a [routerLink]="['/add-media']" class="nav-link">
								<p class="submenu">Add Gallery</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-media-list']" class="nav-link">
								<p class="submenu">Gallery List</p>
							</a>
						</li>

					</ul>
				</li>

				<li *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_video")' class="nav-item has-treeview">
					<a href="#" class="nav-link" (click)="toggle('addvideo')">

						<i class="nav-icon fas fa-video"></i>

						<p>Add Video<i class="fas fa-angle-right right"></i></p>
					</a>

					<ul class="nav nav-treeview" [class.show]="isActive('addvideo')">
						<li class="nav-item">
							<a [routerLink]="['/add-video']" class="nav-link">
								<p class="submenu">Add Video</p>
							</a>
						</li>
						<li class="nav-item">
							<a [routerLink]="['/add-video-list']" class="nav-link">
								<p class="submenu">Video List</p>
							</a>
						</li>

					</ul>
				</li>

			</ul>
		</nav>
		<!-- /.sidebar-menu -->
	</div>
	<!-- /.sidebar -->
</aside>
