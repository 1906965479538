import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators, FormArray
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';
@Component({
  selector: 'app-set-menu',
  templateUrl: './set-menu.component.html',
  styleUrls: ['./set-menu.component.scss']
})
export class SetMenuComponent implements OnInit {
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  HEROES: { id: number; name: string }[];
  @ViewChildren('dap') levels: QueryList<ElementRef>;
  dragEl: any;
  MenuForm: FormGroup = this.formBuilder.group({
    menu: ['', Validators.required],
    main_menu: this.formBuilder.array([this.addAdditionalInfoGroup()]),
    submneuArr: new FormControl(),
  }); 

  private addAdditionalInfoGroup(): FormGroup {
    return this.formBuilder.group({
      main_menu: []
    });
  }
  //Add Fields

  //Add Fields
  addAdditionalInfo(): void {
    this.additionalInfo.push(this.addAdditionalInfoGroup());
  }

  //Remove Fields
  removeAdditionalInfo(index: number): void {
    this.additionalInfo.removeAt(index);
  }

  //Fields Array
  get additionalInfo(): FormArray {
    return <FormArray>this.MenuForm.get('main_menu');
  }


  constructor( private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngselectmodule: NgSelectModule,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.levels['_results']) {
      // console.log(this.levels['_results'])
      var items = this.levels['_results'].map((el) => el.nativeElement);
      // console.log(items)
      var dragEl = null;

      function handleDragStart(e) {
        this.style.opacity = '0.4';

        dragEl = this;
        console.log("handleDragStart from c",this);
        console.log(this, this.innerHTML);

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text', this.innerHTML);
      }

      function handleDragOver(e) {
        if (e.preventDefault) {
          e.preventDefault();
        }

        e.dataTransfer.dropEffect = 'move';

        return false;
      }

      function handleDragEnter(e) {
        this.classList.add('over');
      }

      function handleDragLeave(e) {
        this.classList.remove('over');
      }

      function handleDrop(e) {
        if (e.stopPropagation) {
          e.stopPropagation();
        }

        if (dragEl != this) {
          dragEl.innerHTML = this.innerHTML;
          this.innerHTML = e.dataTransfer.getData('text');
        }

        return false;
      }

      function handleDragEnd(e) {
        this.style.opacity = '1';

        items.forEach(function (item) {
          item.classList.remove('over');
        });
      }

      items.forEach(function (item) {
        item.addEventListener('dragstart', handleDragStart);
        item.addEventListener('dragenter', handleDragEnter);
        item.addEventListener('dragover', handleDragOver);
        item.addEventListener('dragleave', handleDragLeave);
        item.addEventListener('drop', handleDrop);
        item.addEventListener('dragend', handleDragEnd);
      });
    }
  }

  handleDragStart(e) {
    console.log('e', e);
    // e.style.opacity = '0.4';

    // this.dragEl = e;
    // console.log(this, e.innerHTML);

    // e.dataTransfer.effectAllowed = 'move';
    // e.dataTransfer.setData('text', e.innerHTML);
  }

  handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }

    e.dataTransfer.dropEffect = 'move';

    return false;
  }

  handleDragEnter(e) {
    e.classList.add('over');
  }

  handleDragLeave(e) {
    e.classList.remove('over');
  }

  handleDrop(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    if (this.dragEl != e) {
      this.dragEl.innerHTML = e.innerHTML;
      e.innerHTML = e.dataTransfer.getData('text');
      console.log(this.dragEl.innerHTML)
    }

    return false;
  }

  handleDragEnd(e) {
    e.style.opacity = '1';
  }
  
  
  setmenu(){
    console.log(this.MenuForm.value)
    console.log('hello')
    
  }
}
