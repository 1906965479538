import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent implements OnInit {
  public allNotifications: any = [];
  public filteredNotifications: any = []; 
  page: number = 1; 
  isLoading: boolean = false; 
  searchQuery: string = ''; 

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
   
    this.loadNotifications(this.page);
  }

  loadNotifications(page: number): void {
    if (this.isLoading) return; 
    this.isLoading = true; 
    this.spinner.show();

    this.apiService.allNotification(page).subscribe((res: any) => {
      this.spinner.hide();
      this.isLoading = false; 
      console.log(res);
      

      if (res.data.length > 0) {
    
        if (page === 1) {
          this.allNotifications = [];
        }

     
        const newNotifications = res.data.map(notification => ({
          msg: notification?.applyTour_id?.payment_status !== "Cancelled"
            ? `${notification.applyTour_id.user_id.firstname} paid ${notification.payment_status.toLowerCase()} ₹${notification.last_amount_paid} for ${notification.applyTour_id.tour_id.title} tour date ${notification.applyTour_id.tourDate2}.`
            : `${notification.applyTour_id.user_id.firstname} ${notification.applyTour_id.payment_status.toLowerCase()} ${notification.applyTour_id.tour_id.title} tour date ${notification.applyTour_id.tourDate2}.`,
          id: notification.applyTour_id._id,
          notification_id: notification._id,
          date: new Date(notification.created_at)
        }));

    
        this.allNotifications = [...this.allNotifications, ...newNotifications];

     
        this.filterNotifications();
      }
    }, (error) => {
      console.error('Error fetching notifications:', error);
      this.isLoading = false; 
      this.spinner.hide();
    });
  }

  onScroll(event: any): void {
    const element = event.target;
   
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
      this.loadMoreNotifications(); 
    }
  }

  loadMoreNotifications(): void {
    if (!this.isLoading) {
      this.page += 1;
      this.loadNotifications(this.page); 
    }
  }

  onSearchChange(query: string): void {
    this.searchQuery = query.toLowerCase(); 
    this.filterNotifications(); 
  }

  filterNotifications(): void {
    if (this.searchQuery) {
      console.log(this.filterNotifications);
      
      this.filteredNotifications = this.allNotifications.filter(notification =>
        notification.msg.toLowerCase().includes(this.searchQuery)
      );
    } else {
      this.filteredNotifications = this.allNotifications; 
    }
  }

  
}
