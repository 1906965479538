<section class="content">
      <div class="row" >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Mobile Video/Photo</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input 
                        type="file" 
                        accept="image/*, video/*" 
                        (change)="onFileChange($event)" 
                        class="custom-file-input" 
                        name="mobile_media" 
                        id="mobile_media"
                      />
                      <label class="custom-file-label" for="mobile_media">
                        {{ mobileMediaName || 'Choose Mobile Video/Photo' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-4">
                <button class="btn btn-primary" (click)="uploadVideoMobile(_id)" [disabled]="!selectedMediaFile">Send</button>
              </div>
              <a
                [routerLink]="['/add-video']"
                class="btn btn-info float-right mb-3"
                >Add Video</a
              >
              <table 
                datatable 
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger" 
                class="row-border hover"
              >
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Video Name</th>
                    <th>Video URL</th>
                    <!-- <th>Description</th> -->
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="allData?.length != 0">
                  <tr *ngFor="let data of allData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data.video_name }}</td>
                    <td>{{ data.video_url }}</td>
                    <!-- <td>{{ blogs.title | titlecase }}</td>
                    <td><div [innerHTML]="blogs.description"></div></td> -->
                    <td *ngIf="data.status === 'active'">Active</td>
                    <td *ngIf="data.status !== 'active'">Inactive</td>
                    <td>
                      <div class="btn-group">
                        <button 
                          type="button" 
                          class="btn btn-info"
                          routerLink="/add-video/edit-add-video/{{ data._id }}"
                        ><i class="fas fa-pen"></i></button>
                        <button 
                          *ngIf="data.status === 'active'"
                          type="button" 
                          class="btn btn-success"
                          (click)="videolist(data._id, data.status)"
                        ><i class="far fa-check-circle"></i></button>
                        <button 
                          *ngIf="data.status !== 'active'"
                          type="button" 
                          class="btn btn-danger"
                          (click)="videolist(data._id, data.status)"
                        ><i class="fas fa-ban"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="allData?.length == 0">
                  <tr>
                    <td colspan="3" class="no-data-available">No data!</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    