<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <!-- form start -->
          <form [formGroup]="addBlogForm" (ngSubmit)="addBlogs()">

            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Title<span class="required"></span></label>
                    <input type="text" class="form-control" formControlName="title" placeholder="Enter Title" />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Image<span class="required"></span></label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input type="file" accept="image/*" (change)="onFileChange($event)" class="custom-file-input"
                          aria-describedby="imageFile" name="blog_image" />
                        <label class="custom-file-label" #importImg for="blog_image">
                          <span>Upload Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="class_name">Destination Tour <span class="required"></span></label>
                    <select class="form-control" formControlName="destination">
                      <option value="" selected>Select Tour Destination
                      </option>
                      <option *ngFor="let item of alldesti; let i = index" value="{{item._id}}">
                        {{item.menu
                        }} </option>
                    </select>

                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Meta Title</label>
                    <input type="text" class="form-control" formControlName="Meta_Title" placeholder="Enter Title" />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">Meta Keywords</label>
                    <input type="text" class="form-control" formControlName="Meta_Keywords" placeholder="Enter Keywords" />
                  </div>
                </div>

                <!-- <div class="col-md-4">
                  <div class="form-group">
                    <label for="text">Meta Keywords</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="Meta_Description" placeholder="Enter Title"></textarea>
                
                  </div>
                </div> -->


              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="class_name">Content<span class="required"></span></label>
                    <!-- <textarea #ckedit formControlName="description">

                    </textarea> -->
                    <ckeditor [config]="config_ck" formControlName="description">

                    </ckeditor>
                  </div>
                </div>
              </div>

              <div class="row"></div>
              <div class="row">
                <div class="col-md-4">
                  <div class="custom-control custom-checkbox">
                    <input class="form-check-input" type="checkbox" id="status" formControlName="status"
                      (change)="addBlogForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                      [checked]="addBlogForm.value.status == 'active'" />
                    <label for="status" class="form-check-label">Status</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer text-right">
              <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
              <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                [disabled]="isAuthDisabled"></app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                [routerLink]="['/blog-list']">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <app-ck-editor></app-ck-editor> -->