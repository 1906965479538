import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';

@Component({
  selector: 'app-reminder-dropdown-menu',
  templateUrl: './reminder-dropdown-menu.component.html',
  styleUrls: ['./reminder-dropdown-menu.component.scss']
})
export class ReminderDropdownMenuComponent implements OnInit {
  public allDate: Array<any> = [];
  public time: Array<any> = [];
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  constructor(private renderer: Renderer2, private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.getReminder().subscribe((res: any) => {
      //console.log(res);
      this.allDate = res.data

      const time1: any = new Date();
      for (let x = 0; x < res.data.length; x++) {
        // console.log(res.data[x]);

        // this.handleClick(res.data[x]._id)
        // console.log(this.changeCol, x);

        // ! time calc
        // time in seconds, to change divide by 60 for min and 3600 for hour
        const time2: any = new Date(res.data[x].created_at);
        let timeDiffinSec = Math.abs(time1 - time2) / 1000;
        if (res.data[x].created_at) {
          // this.messageArray.push(res.data[x]);
          // this.changeColArr.push(this.changeCol);
          if (timeDiffinSec > 60) {
            let timeDiffinMin = timeDiffinSec / 60;
            if (timeDiffinMin > 60) {
              let timeDiffinHours = timeDiffinMin / 60;
              if (timeDiffinHours > 24) {
                let timeDiffinDays = timeDiffinHours / 30;
                if (timeDiffinDays > 30) {
                  // console.log("Long Ago");
                } else if (timeDiffinDays <= 30) {
                  // console.log(timeDiffinDays);
                  if (Math.floor(timeDiffinDays) > 1) {
                    this.time.push(`${Math.floor(timeDiffinDays)}days`);
                  } else {
                    this.time.push(`${Math.floor(timeDiffinDays)}day`);
                  }
                }
              } else if (timeDiffinHours <= 24) {
                // console.log(timeDiffinHours);
                if (Math.floor(timeDiffinHours) > 1) {
                  this.time.push(`${Math.floor(timeDiffinHours)}hrs`);
                } else {
                  this.time.push(`${Math.floor(timeDiffinHours)}hr`);
                }
              }
            } else if (timeDiffinMin <= 60) {
              // console.log(timeDiffinMin);
              this.time.push(`${Math.floor(timeDiffinMin)}min`);
            }
          } else if (timeDiffinSec <= 60) {
            // console.log(timeDiffinSec);
            this.time.push(`${Math.floor(timeDiffinSec)}sec`);
          }
        } else {
          // this.changeColArr.push(this.changeCol)
          // this.clickedNotify.push(res.data[x])
          // this.messageArray.splice(x, 1);
          // this.time.splice(x, 1);
        }
        // console.log(this.changeColArr);

        // timeDiffinSec = timeDiffinSec / 3600;
        // console.log(timeDiffinSec);

        // console.log(time2, "and", time1, "maths", timeDiff / 60)
        // console.log(res.data[x].applyTour_id.tour_id);

        // ! user and tour api called
        // this.apiService.getTourId({ id: res.data[x].applyTour_id.tour_id }).subscribe((response: any) => {
        //   console.log(response.getData);
        //   this.dataTour.push(response.getData.title);
        //   this.tripTrekArr.push(response.getData.tripAndTrekType);
        //   console.log(this.dataTour)
        // })
        // this.apiService.adminId({ id: res.data[x].applyTour_id.user_id }).subscribe((resp: any) => {
        //    console.log(resp.getData.firstname);
        //   this.dataUser.push(resp.getData.firstname);
        // })
      }
    })
  }

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
    }
  }

  hideDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
    }
  }

}
