<div class="content">
  <div class="container-fluid">
    <div *ngIf="this.pricingid" class="card card-primary">
      <div class="card-body">
        <div class="tab-container">
          <hr style="border-top: 1px solid black; width: auto;">
          <hr style="border-top: 1px solid black; width: 0%;">

          <!-- Define tabs for each page with routerLinkActive directive -->
          <div class="tab circular-button navbar-nav" routerLinkActive="active"
            [routerLink]="['/make-trip', this.object]" title="Make Trip">1</div>

          <hr style="border-top: 1px solid black; width: 300%;">
          <hr style="border-top: 1px solid black; width: 400%;">

          <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-date', this.object]"
            title="Make Trip Date">2</div>

          <hr style="border-top: 1px solid black; width: 300%;">
          <hr style="border-top: 1px solid black; width: 400%;">

          <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-overview', this.object]"
            title="Make Trip Overview">3</div>

          <hr style="border-top: 1px solid black; width: 300%;">
          <hr style="border-top: 1px solid black; width: 400%;">

          <div class="tab circular-button" routerLinkActive="active"
            [routerLink]="['/make-trip-itinerary', this.object]" title="Make Trip Itinerary">4</div>

          <hr style="border-top: 1px solid black; width: 300%;">
          <hr style="border-top: 1px solid black; width: 400%;">

          <div class="tab circular-button" routerLinkActive="active"
            [routerLink]="['/make-trip-inclusion', this.object]" title="Make Trip Inclusion">5</div>

          <hr style="border-top: 1px solid black; width: 156%;">
          <hr style="border-top: 1px solid black; width: 500%;">

          <div class="tab circular-button" routerLinkActive="active"
            [routerLink]="['/make-trip-pricing-list', this.object]" title="Make Trip Pricing">6</div><br>

          <hr style="border-top: 1px solid black; width: auto;">
        </div>
      </div>
    </div>

    <div *ngIf="this.pricingid && this.touttype == 'normal'" class="col-md-12">
      <div class="card card-primary">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group ">
                <label for="class_name">Select Financial Year <span class="required"></span></label>
                <select class="form-control" [(ngModel)]="selectFinancialyear">
                  <option value="Select Year" [selected]="true" [disabled]="true">Select Year</option>
                  <option value={{year}} *ngFor="let year of getFinancialYear; let i = index">{{year}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group ">
                <label for="class_name">Select Month <span class="required"></span></label>
                <select class="form-control" >
                  <option value="Select Year" [selected]="true" [disabled]="true">Select Month</option>
                  <option value="0-1">January</option>
                  <option value="0-2">February</option>
                  <option value="0-3">March</option>
                  <option value="0-4">April</option>
                  <option value="0-5">May</option>
                  <option value="0-6">June</option>
                  <option value="0-7">July</option>
                  <option value="0-8">August</option>
                  <option value="0-9">September</option>
                  <option value="0-10">October</option>
                  <option value="0-11">November</option>
                  <option value="0-12">December</option>
                </select>
              </div>
            </div>
            <div class="col-md-2 d-flex align-items-center justify-content-start">
              <button type="submit" class="btn btn-info mt-3" (click)="financialList()">Financial Search</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>


    <div class="row" *ngIf="this.touttype == 'normal'">
      <div class="col-md-12">
        <div class="card card-primary">
          <form [formGroup]="addMakeDateForm" (ngSubmit)="submitForm(id) ">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Start Date <span class="required"></span></label>
                        <input type="date" id="start_date1" formControlName="start_date1" class="form-control"
                          (change)="checkDates(); updateTableData(); updateNightOrDay()" [min]="todayDate"  [value]="todayDate">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>End Date<span class="required"></span></label>
                        <input type="date" id="end_date1" formControlName="end_date1" class="form-control"
                          (change)="checkDates(); updateTableData(); updateNightOrDay();" [min]="minEndDate">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label>Label</label>
                      <input type="text" class="form-control"  formControlName="label" 
                      placeholder="Enter Label" />
                    </div>
                    <div class="col-md-1">
                      <div class="form-group">
                        <button style="margin-top: 32px;" type="button" class="btn btn-info"
                          (click)="addRow(); updateNightOrDay()"
                          [disabled]="!addMakeDateForm.get('start_date1').value || !addMakeDateForm.get('end_date1').value">Add</button>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="remainder-day">Last Reminder Day<span class="required"></span></label>
                        <select class="form-control" name="lastReminderDay" formControlName="lastReminderDay"
                          id="remainder-day">
                          <option *ngFor="let rd of reminderDays" [selected]="rd===1" value="{{rd}}">
                            {{rd}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="night-day">Night/Day<span class="required"></span></label>
                        <span *ngIf="differ || count else noValue" class="form-control">{{differ || count}}Night / {{ count + 1 ||differ + 1 }}Day</span>

                        <ng-template #noValue>
                          <span class="form-control">0Night / 0Day</span>
                        </ng-template>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" #addDate>
                  <div *ngIf="tableData.length === 0; else tableContent">
                    <p class="tablebox">No Tour Dates Found.</p>
                </div>
                <ng-template #tableContent>
                  <table class="table" >
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>Batch Number</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Label</th>
                        <th>Show Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of tableData; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td >
                          <p class="boxdate">{{ row.batchNumber ? row.batchNumber : 'No Batches'  }}</p>
                        </td>
                        <td>
                          <p class="boxdate">{{ row.start_date | date: 'dd-MM-yyyy' }}</p>
                        </td>
                        <td>
                          <p class="boxdate">{{ row.end_date | date: 'dd-MM-yyyy' }}</p>
                        </td>
                        <td>
                          <p class="boxdate">{{ row.label ? row.label : 'No Label' }}</p>
                        </td>
                        <td>
                          <label class="checkbox-container">
                            <input type="checkbox" class="checkboxcolor" [checked]="row.show_date"
                              (change)="updateCheckbox($event.target.checked, i)">
                          </label>
                        </td>
                        <td>
                          <button type="button" class="removebtn"
                          (click)="removeRow(i)" >Remove</button>
                          <!-- <span style="padding: 6px; background-color: gray; color: #fff; border-radius: 5px;"
                            (click)="removeRow(i)">
                            Remove
                          </span> -->
                        </td>
                      </tr>
                    </tbody>


                  </table>
                </ng-template>
                  <hr style="border-top: 2px solid black; margin-top: 0rem; margin-bottom: revert-layer;" />
                </div>

                <!-- <div class="col-md-12" #addDate>
                        <table class="table">
                            <thead>
                              <thead>
                                <tr>
                                  <th>S.NO</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                  <th>Show Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of tableData; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>
                                        <p class="boxdate">{{ row.startDate | date: 'dd-MM-yyyy' }}</p>
                                    </td>
                                    <td>
                                        <p class="boxdate">{{ row.endDate | date: 'dd-MM-yyyy' }}</p>
                                    </td>
                                    <td>
                                        <label class="checkbox-container">
                                            <input type="checkbox" class="checkboxcolor" [formControl]="row.showDateControl">
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td><button class="btnn btn " (click)="removeRow(i)">Remove</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <hr style="border-top: 2px solid black; margin-top: 0rem; margin-bottom: revert-layer;" />
                    </div> -->


              </div>
            </div>

            <!-- <div class="card-footer">
              <button type="submit" class="btn btn-info">Submit</button>
            </div> -->

            <div class="card-footer text-right">
              <app-button [type]="'submit'" [block]="false" [text]="'Next'"
                    [loading]="isAuthLoading" [color]="'info'"
                    [disabled]="isAuthDisabled"></app-button>
              <button type="submit" class="btn btn-info"
              [routerLink]="['/make-trip', object]"   style="float:left;">Previous</button>
        </div>

          </form>

        </div>
      </div>
    </div>



    <div class="row" *ngIf="this.touttype == 'customized'">
      <div class="col-md-12">
        <div class="card card-primary">
          <form [formGroup]="addMakeDateForm" (ngSubmit)="submitForm(id) ">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                   
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="remainder-day">Last Reminder Day<span class="required"></span></label>
                        <select class="form-control" name="lastReminderDay" formControlName="lastReminderDay"
                          id="remainder-day">
                          <option *ngFor="let rd of reminderDays" [selected]="rd===1" value="{{rd}}">
                            {{rd}}</option>
                        </select>
                      </div>
                    </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="night-day">Night/Day<span class="required"></span></label>
                          <select class="form-control" aria-label="Default select example" formControlName="dayornight">
                       
                            <option *ngFor="let rd of reminderDays" value="{{rd}}" selected>
                              {{rd}}Night/{{rd+1}}Day</option>
                          </select>
                         
                          <!-- <select class="form-control" name="dayornight" formControlName="dayornight"
                            id="night-day">
                            <option value="" selected>Select Night/Day
                            </option>
                            <option *ngFor="let rd of reminderDays" [selected]="rd===1" value="{{rd}}" selected>
                              {{rd}}Night/{{rd+1}}Day</option>
                          </select> -->
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <app-button [type]="'submit'" [block]="false" [text]="'Next'"
                    [loading]="isAuthLoading" [color]="'info'"
                    [disabled]="isAuthDisabled"></app-button>
              <button type="submit" class="btn btn-info"
              [routerLink]="['/make-trip', object]"   style="float:left;">Previous</button>
        </div>

          </form>

        </div>
      </div>
    </div>


  </div>
</div>