<section class="content">
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover text-center"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone No.</th>
                  <th>Travel Date</th>
                  <th>No. of People</th>
                  <th class="d-none">Message</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let data of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ formateDate(data.dot) }}</td>
                  <td>{{ data.people }}</td>
                  <td class="d-none"><div class="b">{{ data.message }}</div></td>
                  <td>
                    <div class="btn-group">
                        <button 
                          type="button" 
                          class="btn btn-info"
                          routerLink="/landing-page-details/view-landing-detail/{{ data._id }}"
                        >View</button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allData?.length == 0">
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="no-data-available">No data!</td>
                    <td></td>
                    <td></td>
                    <td class="d-none"></td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
  