import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../../utils/services/api.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-promotional-sms-email-list',
  templateUrl: './promotional-sms-email-list.component.html',
  styleUrls: ['./promotional-sms-email-list.component.scss']
})
export class PromotionalSmsEmailListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private category: string;
  public addLanName: string;
  public addLanRoute: string;
  public allData: any;
  private emailid: any;
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
   @ViewChild(DataTableDirective) dtElement: DataTableDirective;
mails: any;
id: any;
  data_id: any;
  data: any;
  newMail: any;
mailBody: any;


  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    if (this.category === 'promotional-sms-email-list') {
      this.addLanName = 'Add Promotional-Sms/Email';
      this.addLanRoute = '/promotional-sms-email';
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.email();
   
  }

  email(){
    this.apiService.getpromotionalMails().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.mails;
      console.log( this.allData);
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }


  promotionalemail(id){
    console.log(id);
    
    this.apiService.getSentMailId(id).subscribe((resp: any) => {
      console.log(resp,"mjjsfjhd");
this.newMail=resp.mail
console.log( this.newMail);

    });

  }
 
  }

  


