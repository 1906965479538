<div class="content">
  <div class="container-fluid">
        <div class="row">
              <div class="col-md-12">
                    <div class="card card-primary p-3">
                      <div class="card-body col-12 d-flex">
                        <form [formGroup]="addCoupanToUsers" (ngSubmit)="setCoupon()" class="card-body col-12 d-flex flex-wrap">
                          <div class="col-6 d-flex flex-column">
                            <label>Vouchers</label>
                            <ng-select *ngIf="allVoucher.length > 0" class="w-75" [multiple]="false" formControlName="voucher" placeholder="Select Voucher" (change)="onChangeVoucherDetail($event)">
                              <!-- <option value="Select Voucher" [selected]="true" [disabled]="true">Select Voucher</option> -->
                              <ng-option *ngFor="let date of allVoucher; index as i" [value]="date._id">{{date.coupon_code}}</ng-option>
                            </ng-select>
                          </div>
                          <div class="col-6 d-flex flex-column">
                            <label>Users</label>
                            <ng-select *ngIf="allData.length > 0" class="w-75" [multiple]="true" formControlName="userList" placeholder="Select Users" (change)="onChangeUserDetail($event)">
                              <!-- <option value="Select User" [selected]="true" [disabled]="true">Select User</option> -->
                              <ng-option *ngFor="let date of allData; index as i" [value]="date._id">{{date.firstname}} {{date.lastname}} ({{date.mobile}})</ng-option>
                            </ng-select>
                          </div>
                          <div class="p-2 mt-3">
                            <button type="submit" role="button" class="btn btn-primary save-btn">Save</button>
                          </div>
                        </form>
                      </div>
                    </div>
              </div>
        </div>
  </div>
</div>
