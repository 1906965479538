<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addOtherChargeForm" (ngSubmit)="addOrderCharge()" >
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="col-md-3">
                                                      <div class="form-group">
                                                            <label for="class_name">SGST %<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" formControlName="cgst" placeholder="SGST" >
                                                      </div>
                                                </div>

                                                <div class="col-md-3">
                                                      <div class="form-group">
                                                            <label for="class_name">CGST %<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" formControlName="sgst" placeholder="CGST" >
                                                      </div>
                                                </div>

                                                <div class="col-md-3">
                                                      <div class="form-group">
                                                            <label for="class_name">TCS %<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" formControlName="tcs" placeholder="TCS" >
                                                      </div>
                                                </div>
                                                <div class="col-md-3">
                                                      <div class="form-group">
                                                            <label for="class_name">IGST %<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" formControlName="igst" placeholder="IGST" >
                                                      </div>
                                                </div>

                                                <div class="col-md-3">
                                                      <div class="form-group">
                                                            <label for="class_name">Transaction Charge %<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" formControlName="transactional_charge"
                                                                  placeholder="Transaction Charge" >
                                                      </div>
                                                </div>


                                          </div>
                                    </div>
                                    <!-- <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input" formControlName="status"
                                                                  (change)="addOtherChargeForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                  [checked]="addOtherChargeForm.value.status == 'active'" />
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div> -->




                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <button type="submit" class="btn btn-info">Submit</button>

                                          <button type="button" class="btn btn-secondary ml-2"
                                                [routerLink]="['/other-charges-list']">Cancel</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>