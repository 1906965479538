import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-package-type',
  templateUrl: './package-type.component.html',
  styleUrls: ['./package-type.component.scss']
})
export class PackageTypeComponent implements OnInit {
  addPackageForm: FormGroup;
  public cancelCatRoute: string;
  private packid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addPackageForm = new FormGroup({
      package_type: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });
     this.packid = this.route.snapshot.paramMap.get('package_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.packid != undefined &&
      this.packid != null &&
      this.packid != ''
    ) {
      this.editpack(this.packid);
    } else {
      this.addPackageForm.get('status').setValue('active');
    }
  }

  editpack(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getPackId(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addPackageForm.patchValue({
          package_type: result.getData.package_type,
        });
        if (result.getData.status == 'active') {
          this.addPackageForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addPacka() {
    if (
      this.addPackageForm.value.package_type== undefined ||
      this.addPackageForm.value.package_type.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter package type',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addPackageForm.value.this._userId == undefined ||
    //   this.addPackageForm.value.boarding.trim() == ''
    // )
    if (this.addPackageForm.status == 'active') {
      this.addPackageForm.get('status').setValue('active');
    }
    if (this.packid != undefined && this.packid != null && this.packid != '') {
      this.updatePackage(this.packid);
    } else {
      this.addPackageForm.value.user_id = this._userId;
       this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addPack(this.addPackageForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
             this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/package-type-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updatePackage(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updatePack(this.addPackageForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/package-type-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
