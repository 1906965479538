<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <form [formGroup]="addMenuMasterForm"  (ngSubmit)="addmastermenu()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Category/Page <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="menu"
                                            placeholder="Category/Page">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Title <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="title"
                                            placeholder="Title">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tag Line <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="tagline"
                                            placeholder="Tag Line">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Image (Only 1920x1080)<span class="required"></span></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChange($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="image" />
                                                <label class="custom-file-label" #importFestImg for="image">
                                                    <span>Upload Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Selecte SubCategory<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="parentcategory">
                                            <option value="" selected>Select SubCategory
                                            </option>
                                            <option *ngFor="let item of allData; let i = index" value="{{item._id}}">
                                                {{item.menu_type}} </option>
                                        </select>

                                    </div>
                                </div> -->
                                <div class="form-group col-md-12">
                                    <label for="class_name">About Us <span class="required"></span></label>
                                    <ckeditor [config]="config_ck" formControlName="aboutus">
                                    </ckeditor>
                                </div>
                            </div>
                            <br>
                           
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <input id="male" type="checkbox" class="custom-control-input"
                                                formControlName="status"
                                                (change)="addMenuMasterForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                [checked]="addMenuMasterForm.value.status == 'active'" />
                                            <label class="custom-control-label" for="male">Status</label>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <input id="female" type="checkbox" class="custom-control-input"
                                                formControlName="isfeaturetour"
                                                (change)="addMenuMasterForm.get('isfeaturetour').setValue($event.target.checked ? '1' : '0')"
                                                [checked]="addMenuMasterForm.value.isfeaturetour == '1'" />
                                            <label class="custom-control-label" for="female">Is Feature</label>
                                        </div>
                                    </div>
                                </div>
                           
                        </div>
                        <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Save'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>
                            <button type="button" class="btn btn-secondary ml-2"
                                [routerLink]="['/add-menu-master-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>