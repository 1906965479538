<section class="content">
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <a
              [routerLink]="['/add-menu']"
              class="btn btn-info float-right mb-3"
              >Add Menu</a
            > -->
            <table 
              datatable 
              [dtOptions]="dtOptions"
              
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Option</th>
                  <!-- <th>Description</th> -->
                  <!-- <th>Status</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let data of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-info"
                        [routerLink]="[
                        '/set-menu/' + data.value
                      ]"
                      >
                       Edit
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tbody *ngIf="allData?.length == 0">
                <tr>
                  <td ></td>
                  <td></td>
                  <td >No data!</td>
                  <td ></td>
                </tr>
              </tbody> -->
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>