<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form [formGroup]="addSuperForm" (ngSubmit)="setAdmin()">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="form-group col-md-4">
                                                      <label for="title">First Name<span
                                                                  class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                            formControlName="firstname"
                                                            placeholder="Enter First Name"
                                                            [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                                                            <div *ngIf="submitted && f.firstname.errors"
                                                            class="invalid-feedback"> </div>
                                                      <div *ngIf="f.firstname.errors?.minlength"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            First name must be at least 3 characters</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Last Name<span class="required"></span></label>
                                                      <input type="text" class="form-control" formControlName="lastname"
                                                            [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                                                            placeholder="Enter Last Name">
                                                      <div *ngIf="submitted && f.lastname.errors"
                                                            class="invalid-feedback"> </div>
                                                      <div *ngIf="f.lastname.errors?.minlength"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            Last name must be at least 3 characters</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Mobile Number<span class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                            (keypress)="onlyNumberKey($event)" formControlName="mobile"
                                                            maxlength="10" placeholder="Mobile Number"
                                                            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                                      <div *ngIf="submitted && f.mobile.errors"
                                                            class="invalid-feedback"></div>
                                                      <div *ngIf="f.mobile.errors?.pattern"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            Mobile number should of 10 digits</div>

                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Email<span class="required"></span></label>
                                                      <input type="email" class="form-control" formControlName="email"
                                                            placeholder="Email"
                                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                            [readonly]="readOnly">
                                                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                      </div>
                                                      <div *ngIf="f.email.errors?.pattern"
                                                            style="font-size: 13px; margin-bottom: 15px; color: #ff0000; ">
                                                            Email must be a valid email address</div>
                                                </div>

                                                <div class="form-group col-md-4">
                                                      <label>Password<span class="required"></span></label>
                                                      <input type="password" class="form-control"
                                                            formControlName="password" placeholder="Password">
                                                </div>

                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Admin Role <span
                                                                        class="required"></span></label>
                                                            <select class="form-control" formControlName="role">
                                                                  <option value="" selected>Select Role</option>
                                                                  <option value="0">Super Admin</option>
                                                                  <option value="1">Admin</option>
                                                            </select>

                                                      </div>
                                                </div>

                                                <div class="card-body" style="margin-top: -42px;">
                                                      <div class="row">
                                                            <div class="col-md-4">

                                                                  <div class="custom-control custom-checkbox"
                                                                        style="margin-top: 18px; margin-left: -15px;">
                                                                        <input id="male" type="checkbox"
                                                                              class="custom-control-input"
                                                                              formControlName="status"
                                                                              (change)="addSuperForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                              [checked]="addSuperForm.value.status == 'active'" />
                                                                        <label class="custom-control-label"
                                                                              for="male">Status</label>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>


                                          </div>

                                          <div *ngIf="addSuperForm.value.role==='1'" class="row"
                                                style="border-top: 1px solid #565656;">

                                                <div class="col-md-3" style="margin-top: 25px;"
                                                      *ngFor="let web of userRoleList">

                                                      <div class="custom-control custom-checkbox">
                                                            <label>

                                                                  <input type="checkbox" [value]="web.value"
                                                                        (change)="web.check = web.check === true ? false : true"
                                                                        [checked]="web.check" />

                                                                  {{web.name}}

                                                            </label>


                                                      </div>
                                                </div>

                                          </div>

                                    </div>
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'"
                                                [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                          </app-button>
                                          <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/super-admin-list']">Cancel</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>