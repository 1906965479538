<div class="content">
    <div class="container-fluid">
        <div *ngIf="this.showBar" class="card card-primary">
            <div class="card-body">
                <div class="tab-container">
                    <hr style="border-top: 1px solid black; width: auto;">
                    <hr style="border-top: 1px solid black; width: 0%;">
            
                    <!-- Define tabs for each page with routerLinkActive directive -->
                    <div class="tab circular-button navbar-nav" routerLinkActive="active" [routerLink]="['/make-trip', this.tripid]" title="Make Trip">1</div>
                    
                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">
            
                    <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-date', this.tripid]" title="Make Trip Date">2</div>
            
                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">
            
                    <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-overview', this.tripid]" title="Make Trip Overview">3</div>
            
                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">
            
                    <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-itinerary', this.tripid]" title="Make Trip Itinerary">4</div>
                    
                    <hr style="border-top: 1px solid black; width: 300%;">
                    <hr style="border-top: 1px solid black; width: 400%;">
            
                    <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-inclusion', this.tripid]" title="Make Trip Inclusion">5</div>
            
                    <hr style="border-top: 1px solid black; width: 156%;">
                    <hr style="border-top: 1px solid black; width: 500%;">
            
                    <div class="tab circular-button" routerLinkActive="active" [routerLink]="['/make-trip-pricing-list', this.tripid]" title="Make Trip Pricing">6</div><br>
                    
                    <hr style="border-top: 1px solid black; width: auto;">
                </div>
            </div> 
        </div>

        <div class="row">

            <div class="col-md-12">
                <div class="card card-primary">

                    <form [formGroup]="addMakeTripForm" (ngSubmit)="addtrip()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Select Category<span class="required"></span></label>
                                        <select class="form-control" formControlName="menumaster_id" (change)="onChange($event.target.value)">
                                            <option value="" selected>Select Menu Category
                                            </option>
                                            <option *ngFor="let item of allMenu; let i = index" value="{{item._id}}" >
                                                {{item.menu}} </option>
                                        </select>
                                        <!-- <label for="class_name">Boarding Point<span class="required"></span></label>
                                        <ng-select placeholder="Select Boarding Point" [multiple]="true"
                                            formControlName="boardingArr">
                                            <ng-option *ngFor="let subCat of SubCats" [value]="subCat.boarding">{{
                                                subCat.boarding }}
                                            </ng-option>
                                        </ng-select> -->

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tour Title <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="title"
                                            placeholder="Title">
                                    </div>
                                </div>
                             
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Image (Only 1920x1080)<span class="required"></span></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChange($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="tour_image" />
                                                <label class="custom-file-label" #importFestImg for="tour_image">
                                                    <span>Upload Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Banner Image (Only 1920x1080)<span class="required"></span></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChangebanner($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="banner_image" />
                                                <label class="custom-file-label" #importBannerImg for="banner_image">
                                                    <span>Banner Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Mobile Video GIF</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFilemobilegif($event)"
                                                    class="custom-file-input" aria-describedby="mobile_gif"
                                                    name="mobile_gif" />
                                                <label class="custom-file-label" #importvideogif for="mobile_gif">
                                                    <span>Mobile Video GIF</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Boarding Point<span class="required"></span></label>
                                        <ng-select placeholder="Select Boarding Point" [multiple]="true"
                                            formControlName="boardingArr">
                                            <ng-option *ngFor="let subCat of SubCats" [value]="subCat.boarding">{{
                                                subCat.boarding }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Adventure Type<span class="required"></span></label>
                                        <ng-select placeholder="Select Adventure Type" [multiple]="true" formControlName="adventure_type">
                                                <ng-option *ngFor="let item of allAdve" [value]="item._id">{{
                                                    item.adventure_type }}
                                                </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Label<span class="required"></span></label>
                                        <ng-select placeholder="Select Label " [multiple]="true" formControlName="tagLable_id">
                                       
                                                <ng-option *ngFor="let item of allLable" [value]="item._id">{{
                                                    item.tagLable }}
                                                </ng-option>
                                        </ng-select>


                                    </div>
                                </div> -->

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Label</label>
                                        <select class="form-control" formControlName="tagLable_id">
                                            <option value="" selected>Select Label
                                            </option>
                                            <option *ngFor="let item of allLable" [value]="item._id">{{
                                                    item.tagLable }} </option>
                                        </select>
                                     

                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Package Type<span class="required"></span></label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="packageArr" (change)="selectedPackage($event)">
                                            <ng-option *ngFor="let subPac of allpackag" [value]="subPac.package_type">{{
                                                subPac.package_type }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Destination</label>
                                        <select class="form-control" formControlName="destination">
                                            <option value="" selected>Select Destination
                                            </option>
                                            <option *ngFor="let item of alldesti; let i = index" value="{{item._id}}">
                                                {{item.destination}} </option>
                                        </select>

                                    </div>
                                </div>
                                

                                <!-- <div class="col-md-4" *ngIf="packageSelected.includes('Solo bike')">
                                    <div class="form-group">
                                        <label for="class_name">Solo Bikes</label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="soloBikeArr">
                                            <ng-option *ngFor="let solobike of allsoloBike" [value]="solobike.bike_name">{{
                                                solobike.bike_name }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>

                                <div class="col-md-4" *ngIf="packageSelected.includes('Shared bike')">
                                    <div class="form-group">
                                        <label for="class_name">Shared Bikes</label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="sharedBikeArr">
                                            <ng-option *ngFor="let sharebike of allsharedBike" [value]="sharebike.bike_name">{{
                                                sharebike.bike_name }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div> -->

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Room Sharing</label>
                                        <ng-select placeholder="Select Rooms" [multiple]="true"
                                            formControlName="roomArr">
                                            <ng-option *ngFor="let room of allrooms" [value]="room.room_name">{{
                                                room.room_name }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Similar Blog</label>
                                        <ng-select placeholder="Similer Blog" [multiple]="true"
                                            formControlName="similar_blog">
                                            <ng-option *ngFor="let item of allBlog" [value]="item._id">{{
                                               item.title}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Similar Trip</label>
                                        <ng-select placeholder="Similer Trip" [multiple]="true"
                                            formControlName="similar_trip">
                                            <ng-option *ngFor="let item of allTrip" [value]="item._id">{{
                                            item.title }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Number Of Travellar<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="no_of_traveler">
                                            <option value="" selected>Select Travellar</option>

                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>

                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Trip/Trek</label>
                                        <select class="form-control" formControlName="tripType">
                                            <option value="" selected>Select Trip/Trek</option>
                                            <option value="1">Trip</option>
                                            <option value="2">Trek</option>
                                            <!-- <option *ngFor="let item of allmenu; let i = index" value="3">
                                                {{item.menu_type}} </option> -->
                                            <!-- <option value="3">Three</option> -->
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tour Type<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="tourtype" >
                                            <option value="" selected disabled >Select Normal/Customized Trip</option>

                                            <option value="normal">Normal</option>
                                            <option value="customized">Customized</option>
                                           
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Domestic/International Trip<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="domesticorInternational" >
                                            <option value="" selected disabled >Select Domestic/International Trip</option>

                                            <option value="domestic">Domestic</option>
                                            <option value="international">International</option>
                                            <!-- <option *ngFor="let item of allmenu; let i = index" value="3">
                                                {{item.menu_type}} </option> -->
                                            <!-- <option value="3">Three</option> -->
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Tag/Label(Filling fast/solid Out)<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="taglabel">
                                            <option value="" selected>Select Tag/Label(Filling fast/solid Out)</option>

                                            <option value="Domestic">Filling fast</option>
                                            <option value="Internation">solid Out</option>
                                          
                                        </select>
                                    </div>
                                </div> -->

                               

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Infant From</label>
                                        <input type="text" (keypress)="onlyNumberKey($event)" maxlength="3" class="form-control" formControlName="infantfrom"
                                            placeholder="Infant From">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">To</label>
                                        <input type="text" (keypress)="onlyNumberKey($event)" maxlength="3" class="form-control" formControlName="infantto"
                                            placeholder="To">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Call Expert Number</label>
                                        <input type="text" class="form-control" formControlName="callexpertnumber"
                                            placeholder="Call Expert Number">
                                    </div>
                                </div>
                                


                                
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Child From</label>
                                        <input type="text" (keypress)="onlyNumberKey($event)" maxlength="3" class="form-control" formControlName="childfrom"
                                            placeholder="Child From">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">To</label>
                                        <input type="text" (keypress)="onlyNumberKey($event)" maxlength="3" class="form-control" formControlName="childto"
                                            placeholder="To">
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">PDF Upload</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="application/pdf" class="custom-file-input"
                                                    (change)="onFileChangepdf($event)" aria-describedby="imageFile"
                                                    name="tour_pdf" />
                                                <label class="custom-file-label" #importPDFImg for="tour_pdf">
                                                    <span>Upload Pdf</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="class_name">Location<span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="location"
                                            placeholder="Enter Location">
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <label for="class_name">Tour Type<span class="required"></span></label>
                                    <div class="form-group">
                                        <div class="ml-4">
                                            <input class="form-check-input" type="radio" name="tour_type"
                                                id="flexRadioDefault1" value="normal" checked=[true]
                                                formControlName="tour_type">
                                            <label class="form-check-label">
                                                Normal
                                            </label>
                                            <input class="form-check-input  ml-4" type="radio" name="tour_type"
                                                id="flexRadioDefault1" value="customized" formControlName="tour_type">
                                            <label class="form-check-label ml-5">
                                                Customized
                                            </label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group col-md-12">
                                    <label for="imageUpload">Multiple Image</label>&nbsp;&nbsp;
                                    <input type="file" id="imageUpload" multiple (change)="addImageControl($event.target.files)">
                                </div>
                                <div class="form-group col-md-12">
                                    <div class="row">
                                        <div class="col-md-2" *ngFor="let imageControl of imagesFormArray.controls; let i = index">
                                            <div class="card">
                                                <img class="card-img-top" height="120px" *ngIf="imageControl.previewUrl" [src]="imageControl.previewUrl"
                                                    alt="Card image">
                                                <div class="card-body p-1">
                                                    <div class="text-center p-1">
                                                        <button
                                                            class="btn btn-danger btn-sm btn-block mt-1" (click)="removeImageControl(i)"  >Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remainder-day">Last Reminder Day<span
                                                class="required"></span></label>
                                        <select class="form-control" name="lastReminderDay"
                                            formControlName="lastReminderDay" id="remainder-day">
                                            <option *ngFor="let rd of reminderDays" [selected]="rd===1" value="{{rd}}">
                                                {{rd}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <!-- <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Start Date <span class="required"></span></label>
                                                <input type="date" id="start_date1" [readonly]="addedNew"
                                                    (change)="minDate=addMinDate($event.target.value, 1)"
                                                    [min]="initStartDate" formControlName="start_date1"
                                                    class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>End Date<span class="required"></span></label>
                                                <input type="date" id="end_date1" [readonly]="addedNew" [min]="minDate"
                                                    (change)="getAddButton()" formControlName="end_date1"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Show Date</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="show_date1"
                                                        formControlName="show_date1">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div #addDate class="col-md-12"></div>

                                <div class="row" style="margin-left: 1px;">
                                    <div class="form-group col-md-12">
                                        <button type="button" (click)="addvalue()" *ngIf="isAdd"
                                            class="btn btn-info">Add</button>
                                        <button type="button" (click)="removevalue(index)" *ngIf="isRemove"
                                            class="btn btn-secondary ml-2">Remove</button>
                                    </div>
                                </div> -->

                                <!-- <div class="form-group col-md-4">
                                                      <label>Start Date <span class="required"></span></label>
                                                      <input type="date" class="form-control" ngbDatepicker
                                                            (click)="datePicker1.toggle()" #datePicker1="ngbDatepicker"
                                                            formControlName="start_date" [minDate]="minDate"
                                                            placeholder="dd-mm-yyyy">
                                                </div> -->

                                <!-- <div *ngIf="addMakeTripForm.value.start_date!==null"
                                                      class="form-group col-md-4">
                                                      <a (click)="toggleShow()"> <i class="fas fa-plus-square"></i></a>
                                                </div> -->
                                <!-- <div class="form-group col-md-4" >
                                                      <label>End Date<span class="required"></span></label>
                                                      <input type="date" class="form-control" ngbDatepicker
                                                            (click)="datePicker2.toggle()" #datePicker2="ngbDatepicker"
                                                            formControlName="end_date" [minDate]="minDate"
                                                            placeholder="dd-mm-yyyy">
                                                </div> -->
                                <div class="form-group col-md-12">

                                    <label for="class_name">Order Note</label>
                                    <ckeditor [config]="config_ck" formControlName="orderNote">

                                    </ckeditor>

                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <input id="male" type="checkbox" class="custom-control-input"
                                                formControlName="isHome"
                                                (change)="addMakeTripForm.get('isHome').setValue($event.target.checked ? 'true' : 'false')"
                                                [checked]="addMakeTripForm .value.isHome == 'true'" />
                                            <label class="custom-control-label" for="male">Is
                                                Home</label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="custom-control custom-checkbox">
                                            <input id="tour" type="checkbox" class="custom-control-input"
                                                formControlName="upcomingtour"
                                                (change)="addMakeTripForm.get('upcomingtour').setValue($event.target.checked ? 'true' : 'false')"
                                                [checked]="addMakeTripForm .value.upcomingtour == 'true'" />
                                            <label class="custom-control-label" for="tour">Is
                                                Upcoming Tour</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Next'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>

                            <button type="button" class="btn btn-secondary ml-2"
                                [routerLink]="['/make-trip-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</div>