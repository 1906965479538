<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div  class="col-lg-3 col-6" *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("admin_registration")'>
        <div class="small-box bg-info">
          <div class="inner">
            <h4></h4>
            <p style="margin-top: -7px;">Super Admin</p>
            <p style="margin-top: -20px;">Active : {{this.count?.SuperAdminActiveCount}} </p>
            <p style="margin-top: -20px;">Inactive : {{this.count?.SuperAdminInactiveCount}}</p>
          </div>
          <div class="icon">
            <!-- <i class="ion ion-stats-bars"></i> -->
            <i class="fas fa-user-tie"></i>
          </div>
          <a routerLink="/super-admin-list" class="small-box-footer" style="margin-top: -15px;">More info <i
              class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <div  class="col-lg-3 col-6" *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("admin_registration")'>
        <div class="small-box bg-success">
          <div class="inner">
            <h4></h4>
            <p style="margin-top: -7px;">Admin</p>
            <p style="margin-top: -20px;">Active : {{this.count?.AdminActiveCount}} </p>
            <p style="margin-top: -20px;">Inactive : {{this.count?.AdminInactiveCount}}</p>
          </div>
          <div class="icon">
            <!-- <i class="ion ion-stats-bars"></i> -->
            <i class="fas fa-user-tie"></i>
          </div>
          <a routerLink="/super-admin-list" class="small-box-footer" style="margin-top: -15px;">More info <i
              class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <div  class="col-lg-3 col-6" *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("web_user")'>
        <div class="small-box bg-danger">
          <div class="inner">
            <h4></h4>
            <p style="margin-top: -7px;">User List</p>
            <p style="margin-top: -20px;">Active : {{this.count?.WebuserActiveCounte}} </p>
            <p style="margin-top: -20px;">Inactive : {{this.count?.WebuserInactiveCounte}}</p>
          </div>
          <div class="icon">

            <!-- <i class="ion ion-person-add"></i> -->
            <i class="far fa-user-circle"></i>
          </div>
          <a routerLink="/add-user-list" class="small-box-footer" style="margin-top: -15px;">More info <i
              class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <div  class="col-lg-3 col-6" *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("make_trip")' >
        <div class="small-box bg-warning">
          <div class="inner">
            <h4></h4>
            <p style="margin-top: -7px;">Tour</p>
            <p style="margin-top: -20px;">Active : {{this.count?.TourCountActive}} </p>
            <p style="margin-top: -20px;">Inactive : {{this.count?.TourCountInctive}}</p>
          </div>
          <div class="icon">

            <!-- <i class="ion ion-pie-graph"></i> -->
            <i class="fas fa-plane-departure"></i>
            <!-- <i class="fas fa-plane-departure"></i> -->
          </div>
          <a routerLink="/make-trip-list" class="small-box-footer" style="margin-top: -15px;">More info <i
              class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
      <div  class="col-lg-3 col-6" *ngIf='+userInfo.role === 0 || userInfo.user_role.includes("add_coupan")'>
        <div class="small-box bg-warning">
          <div class="inner">
            <h4></h4>
            <p style="margin-top: -7px;">Coupon</p>
            <p style="margin-top: -20px;">Active : {{this.count?.CouponCountActive}} </p>
            <p style="margin-top: -20px;">Inactive : {{this.count?.CouponCountInactive}}</p>
          </div>
          <div class="icon">

            <!-- <i class="ion ion-pie-graph"></i> -->
            <!-- <i class="fas fa-plane-departure"></i> -->
            <i class="fas fa-laptop-code"></i>
            <!-- <i class="fas fa-plane-departure"></i> -->
          </div>
          <a routerLink="/coupan-code-list" class="small-box-footer" style="margin-top: -15px;">More info <i
              class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
    </div>

   

    <!-- ==================================================================================== -->
    <!-- /.row -->
    <!-- Small boxes (Stat box) -->

    <!-- /.row -->
    <!-- Small boxes (Stat box) -->

    <!-- /.row -->
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->