import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-boarding-point',
  templateUrl: './boarding-point.component.html',
  styleUrls: ['./boarding-point.component.scss'],
})
export class BoardingPointComponent implements OnInit {
  addBoardingForm: FormGroup;
  public cancelCatRoute: string;
  private boardid: any;
  getData: any;
  public isAuthLoading = false;
  public submitted: boolean = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.addBoardingForm = new FormGroup({
      boarding: new FormControl('', [Validators.minLength(3)]),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });

    this.boardid = this.route.snapshot.paramMap.get('boarding_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.boardid != undefined &&
      this.boardid != null &&
      this.boardid != ''
    ) {
      this.editBoard(this.boardid);
    } else {
      this.addBoardingForm.get('status').setValue('active');
    }
  }

  editBoard(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getBoardById(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addBoardingForm.patchValue({
          boarding: result.getData.boarding,
        });
        if (result.getData.status == 'active') {
          this.addBoardingForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

   get f() {
    return this.addBoardingForm.controls;
  }

  addPoint() {
    if (!this.addBoardingForm.valid) {
       Swal.fire({
        text: 'Please enter valid details',
        icon: 'warning'
      });
      return;
    }
    if (
      this.addBoardingForm.value.boarding == undefined ||
      this.addBoardingForm.value.boarding.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter boarding point',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addBoardingForm.value.this._userId == undefined ||
    //   this.addBoardingForm.value.boarding.trim() == ''
    // )
    if (this.addBoardingForm.status == 'active') {
      this.addBoardingForm.get('status').setValue('active');
    }
    if (this.boardid != undefined && this.boardid != null && this.boardid != '') {
      this.updateBoar(this.boardid);
    } else {
      this.addBoardingForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addBoard(this.addBoardingForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/boarding-point-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateBoar(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateBoard(this.addBoardingForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/boarding-point-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}
