import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lable',
  templateUrl: './lable.component.html',
  styleUrls: ['./lable.component.scss']
})
export class LableComponent implements OnInit {
  
  addLableForm: FormGroup;
  public cancelCatRoute: string;
  private lableid: any;
  getData: any;
  data: any;
  id : any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addLableForm = new FormGroup({
      tagLable: new FormControl(),
      status: new FormControl(),
   
    });
    this.lableid = this.route.snapshot.paramMap.get('lable_id');
    
    
    if (
      this.lableid != undefined &&
      this.lableid != null &&
      this.lableid != ''
    ) {
      this.editpack(this.lableid);
    } else {
      this.addLableForm.get('status').setValue('active');
    }
  }
// done
  editpack(id) {
 
    // console.log(id);
    
    this.apiService.getAallLabelMaster(id).subscribe((result: any) => {
      console.log(result.data);
      if (result.success) {
        this.addLableForm.patchValue({
          tagLable: result.getData.tagLable,
        });
        if (result.getData.status == 'active') {
          this.addLableForm.get('status').setValue('active');
        }
      
      }
    });
  }

// done
  addLable(){
    // console.log(this.addLableForm.value.tagLable,'Hello')
    // return
    if (
      this.addLableForm.value.tagLable== undefined ||
      this.addLableForm.value.tagLable.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter lable ',
        icon: 'warning',
      });
      return false;
      
    }
    if (this.lableid != undefined && this.lableid != null && this.lableid != '') {
      this.updateLable(this.lableid);
    } else {
      this.addLableForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addLable(this.addLableForm.value)
        .subscribe((res: any) => {
          console.log(res,"response");
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/lable-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }



  }


  updateLable(id){
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateLable(this.addLableForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/lable-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

  }






