<section class="content">
    <div class="row" >
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a
              [routerLink]="['/add-voucher']"
              class="btn btn-info float-right mb-3"
              >Add Voucher</a
            >
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Voucher Code</th>
                  <th>Voucher Amount</th>
                  <th>Voucher Type</th>
                  <th>Voucher Expiry Date</th>
                  <!-- <th>Description</th> -->
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let data of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.coupon_code }}</td>
                  <td>{{ data.coupon_amount }}</td>
                  <!-- <td *ngIf="data.isShow === 'Voucher'">Voucher</td>
                  <td *ngIf="data.status === 'Private'">Private</td>
                  <td *ngIf="data.status === 'Public'">Public</td> -->
                  <td>{{ data.isShow }}</td>
                  <td>{{ data.coupon_expiry_date | date }}</td>
                  <!-- <td>{{ blogs.title | titlecase }}</td>
                  <td><div [innerHTML]="blogs.description"></div></td> -->
                <td *ngIf="data.status === 'active'">Active</td>
                <td *ngIf="data.status !== 'active'">Inactive</td>
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-info"

                        routerLink="/add-voucher/edit-voucher/{{ data._id }}"
                      ><i class="fas fa-pen"></i></button>
                      <button
                        *ngIf="data.status === 'active'"
                        type="button"
                        class="btn btn-success"
                        (click)="videolist(data._id, data.status)"
                      ><i class="far fa-check-circle"></i></button>
                      <button
                        *ngIf="data.status !== 'active'"
                        type="button"
                        class="btn btn-danger"
                        (click)="videolist(data._id, data.status)"
                      ><i class="fas fa-ban"></i></button>
                      <button
                      type="button"
                      class="btn btn-warning"
                      (click)="deletecoupon(data._id)"
                    ><i class="fas fa-trash-alt"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allData?.length == 0">
                <tr>
                  <td ></td>
                  <td ></td>
                  <td ></td>
                  <td class="no-data-available">No data!</td>
                  <td ></td>
                  <td ></td>
                  <td ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
