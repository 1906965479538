<li class="nav-item dropdown">
  <!-- (click)="toggleDropdownMenu()" -->
  <a class="nav-link" data-toggle="dropdown">
    <i class="far fa-clock"></i>
    <span class="badge badge-warning navbar-badge">{{allDate.length}}</span>
  </a>
  <div
    #dropdownMenu
    class="dropdown-menu dropdown-menu-lg dropdown-menu-right my-dropdown-style"
  >
    <span class="dropdown-item dropdown-header">{{allDate.length}} Reminder</span>
    <div class="dropdown-divider"></div>
    <!-- ! this -->
    <div
      *ngFor="let data of allDate; let i = index"
      class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle"
    >
      <a
        [routerLink]="[
          '/user-order-history/particular-order/' + data.applyTour_id._id
        ]"
        class="dropdown-item p-0"
      >
        <b>{{ data.applyTour_id.user_id.firstname }}</b> paid
        <b>{{ data.applyTour_id.payment_status | lowercase }}</b> of
        <b>&#8377;{{ data.applyTour_id.totalAmount - data.applyTour_id.amountPaid }}</b> for <b>{{data.applyTour_id.tour_id.title}}</b>
      </a>
      <small style="margin: 2px 0px 0px 8px; color: grey;"><i>{{ time[i] }} ago</i></small>
    </div>
    <!-- <ng-template *ngIf="messageArray.length < 10">
      <div
      *ngFor="let message of clickedNotify; let i = index"
      class="dropdown-item d-flex justify-content-between align-items-start py-3 border-bottom border-dark-subtle bg-secondary"
    >
      <a
        [routerLink]="[
          '/user-order-history/particular-order/' + message.applyTour_id._id
        ]"
        class="dropdown-item p-0"
      >
        <b>{{ dataUser[i] }}</b> booked a {{ tripTrekArr[i] | lowercase}} to
        <b>{{ dataTour[i] }}</b> for
        <b>&#8377;{{ message.last_amount_paid }}</b></a
      >
      <span style="margin-left: 8px">{{ time[i] }}h</span>
    </div>
    </ng-template> -->
    <!-- <a href="#" class="dropdown-item">
      <i class="fas fa-envelope mr-2"></i> 4 new messages
      <span class="float-right text-muted text-sm">3 mins</span>
    </a> -->
    <div class="dropdown-divider"></div>
    <!-- <a href="#" class="dropdown-item">
      <i class="fas fa-users mr-2"></i> 8 friend requests
      <span class="float-right text-muted text-sm">12 hours</span>
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item">
      <i class="fas fa-file mr-2"></i> 3 new reports
      <span class="float-right text-muted text-sm">2 days</span>
    </a> -->
    <div class="dropdown-divider"></div>
    <a routerLink="/reminders" class="dropdown-item dropdown-footer"
      >See All Reminder</a
    >
  </div>
</li>

<ng-template #notification>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> 4 new messages
    <span class="float-right text-muted text-sm">3 mins</span>
  </a>
  <div class="dropdown-divider"></div>
</ng-template>
