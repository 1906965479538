import { Component, OnInit, OnDestroy, ViewChild  } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-user-reminder',
  templateUrl: './user-reminder.component.html',
  styleUrls: ['./user-reminder.component.scss']
})
export class UserReminderComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public timeDate: any = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
data: any;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.reminder();
  }

  reminder(){
    this.apiService.getReminder().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      let reminderData = []
      for(let data of this.allData){
        console.log(data?.applyTour_id?.user_id?.firstname);
      }
      console.log(this.allData);
     
      for(let date of resp.data){
        let addonsAmount = 0;
        // console.log((new Date(date.applyTour_id.tourReminderEndDate).getTime()) - Date.now())
        // let a = new Date(2023, 3, 12);
        // console.log(a, new Date(2023, 3, 12).getTime(), date);
        // console.log(Math.ceil((new Date(date.applyTour_id.tourReminderEndDate).getTime() - Date.now())/(1000 * 3600 * 24)));
        // this.timeDate.push(Math.ceil(((new Date(date.applyTour_id.tourReminderEndDate).getTime()) - Date.now())/(1000 * 3600 * 24)))
        if(Math.ceil(((new Date(date.applyTour_id.tourReminderEndDate).getTime()) - Date.now())/(1000 * 3600 * 24)) >= 0 && date.applyTour_id.payment_status == "Partial"){
          this.timeDate.push(Math.ceil(((new Date(date.applyTour_id.tourReminderEndDate).getTime()) - Date.now())/(1000 * 3600 * 24)))
          reminderData.push(date)

          // ! for addons amount
          if(date.applyTour_id.addons_id){
            date.applyTour_id.addons_id.addons_data.forEach((elem) => {
              console.log(elem.price, elem.quantity)
              addonsAmount += elem.price * elem.quantity
              date.applyTour_id.addons_id = addonsAmount 
            })
            // console.log(date.applyTour_id.addons_id, "THISSSSSSSSS")
          } else {
            // console.log(date.applyTour_id.addons_id, "NOTTTT THISSSSSSSSS")
            date.applyTour_id.addons_id = addonsAmount
          }
        }
      }
      this.allData = reminderData
      console.log(this.timeDate);
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  calculateDiff(startDate: string, endDate: string) {
    return Math.ceil(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (1000 * 3600 * 24)
    );
  }


 ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
  