import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit, OnDestroy {
  dtOptions: any = {};
  allUser: any;
  render: number;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  constructor(
    private apiService: ApiService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: ['excel']
    };
    this.render = 1;
    // this.showItem();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showItem() {
    const that = this;
    this.apiService.getAllUsers().subscribe((resp: any) => {
      that.allUser = resp.data;
      if (this.render === 1) {
        this.dtTrigger.next();
      }
      this.render = 0;
    });
  }

  async changeStaus(id, status) {
    // console.log(id);
    if (id == undefined || id == '') {
      this.toastr.error('User Id is Invalid');
      return false;
    }
    if (
      id != undefined &&
      id != '' &&
      confirm('Are you sure, you want to change status?')
    ) {
      let getStatus = {};
      getStatus = status === 'active' ? { "status": "inactive" } : { "status": "active" };
      await this.apiService.changeStausById(id, JSON.stringify(getStatus)).subscribe((result: any) => {
        if (result.success) {
          this.toastr.success(result.message);
          this.showItem();
        } else {
          this.toastr.error(result.message);
        }
      });
    }
  }

}
