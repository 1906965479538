import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
//declare var CKEDITOR: any;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit, AfterViewInit, OnDestroy {
  addBlogForm: FormGroup;
  private category: string;
  public minDate = undefined;
  private blog_id: any;
  public news_type: any;
  alldesti: any;
  blogData: any;
  public SubCats: any;
  blog_image: any;
  private _userId: string = localStorage.getItem('id');
  public cancelCatRoute: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
   // uploadUrl:
     // 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    //filebrowserBrowseUrl:
     // 'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
 //   filebrowserImageBrowseUrl:
   //   'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
   // filebrowserUploadUrl:
     // 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
   // filebrowserImageUploadUrl:
   //   'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
  };
  instance: any;

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  @ViewChild('importImg', { static: false }) importImg: ElementRef;
  @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
  @ViewChild('importFile', { static: false }) importFile: ElementRef;
  @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;
  @ViewChild('importAdImg', { static: false }) importAdImg: ElementRef;
 // @ViewChild('ckedit', { static: false }) ckedit: any;

  ngOnInit(): void {
    this.addBlogForm = new FormGroup({
      title: new FormControl(),
      slug_url: new FormControl(),
      description: new FormControl(),
      blog_image: new FormControl(),
      destination : new FormControl(),
      status: new FormControl(),
    });
    this.addBlogForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      slug_url: [''],
      description: ['', [Validators.required]],
      destination: ['', [Validators.required]],
      blog_image: [''],
      Meta_Title: [''],
      Meta_Description: [''],
      Meta_Keywords: [''],
      status: ['', []],
    });

    this.blog_id = this.route.snapshot.paramMap.get('blog_id');
    console.log(this.blog_id);
    if (this.blog_id != undefined && this.blog_id != '') {
      this.getBlogsById(this.blog_id);
    } else {
      this.addBlogForm.get('status').setValue('active');
      // this.addBlogForm.get('subCatName').setValue('All', { onlySelf: true });
    }
    this.destination();
  }

  ngAfterViewInit() {
    //this.instance = CKEDITOR.replace(this.ckedit.nativeElement, this.config_ck);
  }

  destination() {
    this.apiService.allMenuMatser().subscribe((res: any) => {
      console.log(res);
      if(res.success == true)
      this.alldesti = res.data;
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addBlogForm.get('blog_image').setValue(file);
      this.importImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  getBlogsById(id) {
    let dataForForm = {
      id: id,
    };
    // console.log(dataForForm);

    this.apiService.getBlogsById(dataForForm).subscribe((result: any) => {
      if (result.success) {
        console.log(result.getData, 'hiiiiiiiiiii');
        let blogData: any = result.getData;
        this.addBlogForm.patchValue({
          title: blogData.title,
          slug_url: blogData.title,
          blog_image: blogData.blog_image[0]?.src,
          description: blogData.description,
          Meta_Title: blogData.Meta_Title,
          Meta_Description: blogData.Meta_Description,
          Meta_Keywords: blogData.Meta_Keywords,
          destination: blogData.menumaster_id,
        });
       // CKEDITOR.instances[this.instance.name].setData(blogData.description);
        if (blogData.status == 'active') {
          this.addBlogForm.get('status').setValue('active');
        }
        setTimeout(() => {
          this.importImg.nativeElement.innerText =
            blogData.blog_image[0]?.orgName;
        }, 1000);
      } else {
        this.toastr.error(result.message);
      }
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  async addBlogs() {
  //  this.addBlogForm.get("description").setValue(CKEDITOR.instances[this.instance.name].getData())
    // console.log(this.addBlogForm.value.description);
    // return;
    if (
      this.addBlogForm.value.title.trim() == undefined ||
      this.addBlogForm.value.title.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter title',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter title');
      // return false;
    }
    if (
      this.addBlogForm.value.description.trim() == undefined ||
      this.addBlogForm.value.description.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter content',
        icon: 'warning',
      });
      return false;
      // this.toastr.error('Please enter content');
      // return false;
    }
     if (
        this.addBlogForm.value.destination == undefined ||
        this.addBlogForm.value.destination == ''
      ) {
        Swal.fire({
          text: 'Please select menumaster_id',
          icon: 'warning',
        });
        return false;
      }

    if (this.blog_id != undefined && this.blog_id != '') {
      this.updateBlog(this.blog_id);
    } else {
      if (
        this.addBlogForm.value.blog_image.name == undefined ||
        this.addBlogForm.value.blog_image.name == ''
      ) {
        Swal.fire({
          text: 'Please select image',
          icon: 'warning',
        });
        return false;
      }
     

      let formData = new FormData();
      formData.append('user_id', this._userId);
      formData.append('destination', this.addBlogForm.get('destination').value);
      formData.append('title', this.addBlogForm.get('title').value);
      formData.append('slug_url', this.addBlogForm.get('title').value);
      formData.append('description', this.addBlogForm.get('description').value);
      formData.append('Meta_Title', this.addBlogForm.get('Meta_Title').value);
      formData.append('Meta_Description', this.addBlogForm.get('Meta_Description').value);
      formData.append('Meta_Keywords', this.addBlogForm.get('Meta_Keywords').value);
      formData.append('blog_image', this.addBlogForm.get('blog_image').value);
      formData.append('status', this.addBlogForm.get('status').value);
      //return;
      console.log(formData);
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let catTypeExAddApi: any;
      await this.apiService.addBlogs(formData).subscribe((result: any) => {
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'success',
          });
          //this.toastr.success(result.message);
          this.router.navigateByUrl('/blog-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
          //this.toastr.error(result.message);
        }
      });
    }
  }

  async updateBlog(id) {
    let formData = new FormData();
    console.log(this.addBlogForm.value.blog_image?.name);

    if (
      this.addBlogForm.value.blog_image?.name != undefined
      //  ||
      //  this.addBlogForm.value.blog_image?.name != ''
    ) {
      //console.log('kalu');

      formData.append('blog_image', this.addBlogForm.get('blog_image').value);
    }

    formData.append(
      'status',
      this.addBlogForm.get('status').value == '' ||
        this.addBlogForm.get('status').value == 'inactive'
        ? 'inactive'
        : 'active'
    );
    formData.append('user_id', this._userId);
    formData.append('title', this.addBlogForm.get('title').value);
    formData.append('slug_url', this.addBlogForm.get('title').value);
    formData.append('description', this.addBlogForm.get('description').value);
    formData.append('destination', this.addBlogForm.get('destination').value);

    console.log(this.addBlogForm.value);
    //return
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    await this.apiService.updateBlogs(formData, id).subscribe((result: any) => {
      if (result.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'success',
        });
        //this.toastr.success(result.message);
        this.router.navigateByUrl('/blog-list');
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        Swal.fire({
          text: result.message,
          icon: 'error',
        });
        //this.toastr.error(result.message);
      }
    });
  }

  ngOnDestroy() {
    if (this.instance) {
      //CKEDITOR.instances[this.instance.name].destroy();
      this.instance.destroy();
      this.instance = null;
    }
  }
}
