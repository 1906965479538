import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { forEach } from 'jszip';
@Component({
  selector: 'app-agent-booking-order',
  templateUrl: './agent-booking-order.component.html',
  styleUrls: ['./agent-booking-order.component.scss']
})
export class AgentBookingOrderComponent implements OnInit {
  allTour: any
  allUser: any
  dtOptions: any = {};
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'excel'  },
        { extend: 'copy' },
        { extend: 'csv' },
        { extend: 'print' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', }
      ]
    };
    this.activeTour();
    this.userreport();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  activeTour() {
    this.apiService.getTrip().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.data;
      // this.dtTrigger.next();
      // this.isDtInitialized = true;
          // this.render = 0;
    });
  }

  userreport() {
    // const dateSchedule = this.dateSchedule?.nativeElement?.value;
    // const status = this.status?.nativeElement?.value;
    const orderDetails = {
    tour_id: '',
    // travel_date: dateSchedule, 
    payment_status:  '',
    };
    console.log(orderDetails);
    //return;
    this.spinner.show();
    this.apiService.report(orderDetails).subscribe((res: any) => {
      console.log(res,"sssssssssssssssssssssssssssss");
        if (res.success) {
          this.spinner.hide();
          this.allUser = res.userData;
          this.allUser.forEach(async (timeObj, i) => {
            // timeObj.totalAmount = timeObj.totalAmount?.toFixed(2);
            let totalAddon = 0;
            if(timeObj.addons_id){
              timeObj.addons_id.addons_data.forEach(async addons => {
                  totalAddon += addons.price*addons.quantity
                  // console.log(totalAddon, addons);
                  timeObj.addons_id = totalAddon;
              })
            } else if(!timeObj.addons_id) {
              timeObj.addons_id = 0
            }
          })
          // for(let order of this.allUser) {
          //   if (order?.addons_id && order?.addons_id?.addons_data.length) {
          //     const addonData = order?.addons_id?.addons_data;
          //     for (let addon of addonData) {
          //       if (addon.status === 'unpaid') {
          //         this.totalUnpaidAddon += (addon.price * addon.quantity);
          //       }
          //     }
          //     order.addons_id = this.totalUnpaidAddon;
          //   }
          // }

          // console.log(this.allUser,'heyyyyyy');
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
    });
  }

}
