<li class="nav-item dropdown user-menu">
  <a
    class="nav-link dropdown-toggle"
    data-toggle="dropdown"
    (click)="toggleDropdownMenu()"
  >
    <img
      [src]="user.image"
      class="user-image img-circle elevation-2"
      alt="User Image"
    />
  </a>
  <ul #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    <!-- User image -->
    <li class="user-header bg-info">
        

      <p>
        {{userData?.firstname + " " + userData?.lastname}}
        <small>{{+userData?.role === 0 ? 'Super Admin' : 'Admin'}}</small>
      </p>
    </li>
    <!-- Menu Body -->
    <li class="user-body" style="display: none">
      <div class="row">
        <div class="col-4 text-center">
          <a href="#">Followers</a>
        </div>
        <div class="col-4 text-center">
          <a href="#">Sales</a>
        </div>
        <div class="col-4 text-center">
          <a href="#">Friends</a>
        </div>
      </div>
      <!-- /.row -->
    </li>
    <!-- Menu Footer-->
    <li class="user-footer d-flex justify-content-center">
      <!-- <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
        Profile
      </a> -->
      <a (click)="logout()" class="btn btn-default btn-flat float-right"
        >Sign out</a
      >
    </li>
  </ul>
</li>
