<section class="content">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <a
            [routerLink]="['/add-menu']"
            class="btn btn-info float-right mb-3"
            >Add Menu</a
          > -->
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Trip Name</th>
                <th>Name</th>
                <th>State</th>
                <!-- <th>Mobile Number</th> -->
                <!-- <th>Description</th> -->
                <!-- <th>Refund Date</th> -->
                <th>Refund Amount</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Total</th>
                <th>Invoice</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data.tour_id.title }}</td>
                <td>{{ data.user_id.firstname }} {{ data.user_id.lastname
                  }}</td>
                <td>{{data.user_id.state}}</td>
                <td>{{data.refundAmount}}</td>
                   <!-- * CGST -->
                <td> {{
                  !data.isOuter && data.cgst
                  ? (
                  (data.refundAmount *
                  data.cgst *
                  0.01) /
                  (1 + data.cgst * 0.01 + data.sgst * 0.01)
                  ).toFixed(2)
                  : '-'
                  }}</td>
                  <!-- * SGST -->
                  <td>
                    {{
                      !data.isOuter && data.sgst
                        ? (
                            (data.refundAmount *
                              data.sgst *
                              0.01) /
                            (1 + data.cgst * 0.01 + data.sgst * 0.01)
                          ).toFixed(2)
                        : '-'
                    }}
                  </td>
                   <!-- * IGST -->
                <td>
                  {{
                    data.isOuter && data.igst
                      ? (
                          (data.refundAmount *
                            data.igst *
                            0.01) /
                          (1 + data.igst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }}
                </td>
                 <!-- * total -->
                 <td>
                  {{
                    !data.isOuter
                      ? data.cgst && data.sgst
                        ? (
                            ( data.refundAmount *
                              data.cgst *
                              0.01) /
                              (1 + data.cgst * 0.01 + data.sgst * 0.01) +
                            ( data.refundAmount  *
                              data.sgst *
                              0.01) /
                              (1 + data.cgst * 0.01 + data.sgst * 0.01)
                          ).toFixed(2)
                        : '-'
                      : data.igst
                      ? (
                          ( data.refundAmount *
                            data.igst *
                            0.01) /
                          (1 + data.igst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }}
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-info"
                      [routerLink]="[
                        '/user-order-history/particular-order/' + data._id
                      ]"
                    >
                      Order History
                    </button>
                  </div>
                </td>

                <td><div class="py-1">
                  <button class="btn btn-curve my-2"
                      
                      (click)="sendDataToGenerateInvoice(data)"
                      data-bs-toggle="tooltip" data-bs-placement="top"
                      title="INVOICE">INVOICE <i class="far fa-file-pdf"
                          aria-hidden="true"></i>
                  </button>
              </div>
            </td>
                <!-- <td>{{data.user_id.state == "MP" ? data.refundAmount * +'0.05' : "-"}}</td> -->
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>No data!</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>