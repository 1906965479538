<section class="content">
      <div class="row" >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <a
                [routerLink]="['/special-tour']"
                class="btn btn-info float-right mb-3"
                >Add Image</a
              >
              <table 
                datatable 
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger" 
                class="row-border hover"
              >
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="allData?.length != 0">
                  <tr *ngFor="let data of allData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data.title }}</td>
                    <td><img src="{{baseURLofAPi+data.special_tour_image[0].src}}" style="height: 100px; width: 100px" /></td>
                    <!-- <td>{{ blogs.title | titlecase }}</td>
                    <td><div [innerHTML]="blogs.description"></div></td> -->
                    <td *ngIf="data.status === 'active'">Active</td>
                  <td *ngIf="data.status !== 'active'">Inactive</td>
                    <td>
                      <div class="btn-group">
                        <button 
                          type="button" 
                          class="btn btn-info"
                          routerLink="/special-tour/edit-special-tour/{{ data._id }}"
                          
                        ><i class="fas fa-pen"></i></button>
                        <button 
                          *ngIf="data.status === 'active'"
                          type="button" 
                          class="btn btn-success"
                          (click)="Imagelist(data._id, data.status)"
                        ><i class="far fa-check-circle"></i></button>
                        <button 
                          *ngIf="data.status !== 'active'"
                          type="button" 
                          class="btn btn-danger"
                          (click)="Imagelist(data._id, data.status)"
                        ><i class="fas fa-ban"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="allData?.length == 0">
                  <tr>
                    <td colspan="3" class="no-data-available">No data!</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    