<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a
                [routerLink]="['/add-menu-master']"
                class="btn btn-info float-right mb-3"
                >Add </a
              >
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Menu Name</th>
                  <th>Title</th>
                  <th>Tag Line</th>
                  <!-- <th>Email</th>
                  <th>Mobile No.</th> -->
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allMenu?.length != 0">
                <tr *ngFor="let menu of allMenu; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ menu.menu }}</td>
                  <td>{{ menu.title }}</td>
                  <td>{{ menu.tagline }}</td>
                  <!-- <td>{{ menu.email }}</td>
                  <td>{{ menu.mobile }}</td> -->
                  <td *ngIf="menu.status === 'active'">Active</td>
                  <td *ngIf="menu.status !== 'active'">Inactive</td>
                  <td>
                    <div class="btn-group">
                      <button 
                        type="button" 
                        class="btn btn-info"
                        routerLink="/add-menu-master/edit-add-menu-master/{{ menu._id }}"
                      ><i class="fas fa-pen"></i></button>
                      <button 
                        *ngIf="menu.status === 'active'"
                        type="button" 
                        class="btn btn-success"
                        (click)="menulist(menu._id, menu.status)"
                      ><i class="far fa-check-circle"></i></button>
                      <button 
                        *ngIf="menu.status !== 'active'"
                        type="button" 
                        class="btn btn-danger"
                        (click)="menulist(menu._id, menu.status)"
                      ><i class="fas fa-ban"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allMenu?.length == 0">
                <tr>
                  <td colspan="3" class="no-data-available">No data!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
  