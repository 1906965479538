<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <div class="card-body">
                                    <div class="row">

                                          <div class="col-md-3">
                                                <div class="form-group">
                                                      <label for="class_name">Select Destination</label>
                                                      <select class="form-control"
                                                            (change)="ontourtypeSelectt($event.target.value, $event.target.options[$event.target.selectedIndex].text);">
                                                            <option value="" selected>Select Destination</option>
                                                            <option *ngFor="let data of  alldesti" [value]="data._id
                                                    ">{{ data?.menu}}</option>
                                                      </select>
                                                </div>
                                          </div>

                                          <div class="form-group col-md-3">
                                                <label for="class_name">Tour Name </label>
                                                <select class="form-control" #cat
                                                      >
                                                      <option value="" selected>Plan Name</option>
                                                      <option *ngFor="let item of this.allTour
                                                      ; let i = index" value="{{item._id }}">
                                                            {{ item.title }}</option>
                                                </select>
                                          </div>
                                          <div class="form-group col-md-3">
                                                <label for="class_name">Tour Type</label>
                                                <select class="form-control"
                                                      (change)="ontourtypeSelect($event.target.value);">
                                                      <option value="" selected>Tour Type</option>
                                                      <option value="normal">Normal</option>
                                                      <option value="customized">Customized</option>
                                                </select>
                                          </div>
                                          <div class="form-group col-md-3">
                                                <label for="class_name"> Trip Type </label>
                                                <select class="form-control"
                                                      (change)="ondomesticSelect($event.target.value);">
                                                      <option value="" selected>Tour Type</option>
                                                      <option value="domestic">Domestic</option>
                                                      <option value="international">International</option>
                                                </select>
                                          </div>
                                          <!-- <div class="col-md-3">
                                                <div class="form-group">
                                                      <label for="class_name">Adventure Type</label>
                                                      <select class="form-control" [(ngModel)]="selectedAdventures">
                                                            <option value="" selected>Select Adventure Type</option>
                                                            <option *ngFor="let item of allAdve
                                                         let i = index" value={{item._id}}>
                                                                  {{item.adventure_type}}</option>
                                                      </select>
                                                     

                                                </div>
                                          </div> -->


                                          <div class="col-md-3 form-group">
                                                <div class="form-group">
                                                      <label for="class_name">Status</label>
                                                      <select class="form-control" #status>
                                                            <option value="" selected>All</option>
                                                            <option value="Complete">Complete</option>
                                                            <option value="Partial">Partial</option>
                                                            <option value="Cancelled">Cancelled</option>
                                                      </select>

                                                </div>
                                          </div>
                                          <div class="form-group col-md-3">
                                                <label for="class_name">Select Financial Year </label>
                                                <select class="form-control" [(ngModel)]="selectFinancialyear">
                                                      <option value="" [selected]="true" [disabled]="true">Select Year
                                                      </option>
                                                      <option value={{year}}
                                                            *ngFor="let year of getFinancialYear; let i = index">
                                                            {{year}}</option>
                                                </select>
                                          </div>

                                          <div class="col-md-3">
                                                <div class="form-group">
                                                      <label for="class_name">Select Month</label>
                                                      <select class="form-control" #month>
                                                            <option value="" selected>Select Month</option>
                                                            <option value="01">January</option>
                                                            <option value="02">February</option>
                                                            <option value="03">March</option>
                                                            <option value="04">April</option>
                                                            <option value="05">May</option>
                                                            <option value="06">June</option>
                                                            <option value="07">July</option>
                                                            <option value="08">August</option>
                                                            <option value="09">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                      </select>
                                                </div>
                                          </div>





                                          <div class="col-md-1 d-flex align-items-center justify-content-center ">
                                                <button type="submit" class="btn btn-info"
                                                      (click)="userreport()">Search</button>
                                          </div>
                                    </div>
                                    <!-- <div class="row" *ngIf="this.showrow && !value">
                                        <div class="form-group col-md-2">
                                              <label for="class_name">Tour Name </label>
                                              <select class="form-control" #cat
                                                    (change)="onCategorySelect($event.target.value);">
                                                    <option value="" selected>Plan Name</option>
                                                    <option *ngFor="let item of this.allUser
                                                    ; let i = index" value="{{item.tour_id._id }}">
                                                          {{ item.tour_id.title }}</option>
                                              </select>
                                        </div>

                                      

                                        <div class="form-group col-md-2" *ngIf="allOrder?.start_date">
                                              <div class="form-group">
                                                    <label class="title-label">Date</label>
                                                    <select class="form-control" id="slct" #dateSchedule>
                                                          <option value="" selected="selected">Select Date
                                                          </option>
                                                          <option value="{{ date._id }}"
                                                                *ngFor="let date of this.allOrder?.select_batch; index as i">
                                                                {{ date.start_date
                                                                | date:
                                                                'dd/MM/yy' }} - {{ date.end_date | date: 'dd/MM/yy' }}
                                                                ({{date.batchNumber}})
                                                                &nbsp;&nbsp;
                                                          </option>
                                                    </select>
                                              </div>
                                        </div>

                                        <div class="col-md-2 form-group">
                                              <div class="form-group">
                                                    <label for="class_name">Month</label>
                                                    <select class="form-control" #month>
                                                          <option value="" [selected]="true">Select Month</option>
                                                          <option value="01">January</option>
                                                          <option value="02">February</option>
                                                          <option value="03">March</option>
                                                          <option value="04">April</option>
                                                          <option value="05">May</option>
                                                          <option value="06">June</option>
                                                          <option value="07">July</option>
                                                          <option value="08">August</option>
                                                          <option value="09">September</option>
                                                          <option value="10">October</option>
                                                          <option value="11">November</option>
                                                          <option value="12">December</option>
                                                    </select>
                                              </div>
                                        </div>

                                        <div class="col-md-2 form-group">
                                              <div class="form-group">
                                                    <label for="class_name">Status</label>
                                                    <select class="form-control" #status>
                                                          <option value="" selected>All</option>

                                                          <option value="Complete">Complete</option>
                                                          <option value="Partial">Partial</option>
                                                          <option value="Cancelled">Cancel</option>
                                                         
                                                    </select>

                                              </div>
                                        </div>
                                        <div class="col-md-1 d-flex align-items-center justify-content-center">
                                              <button type="submit" class="btn btn-info"
                                                    (click)="tour()">Report</button>
                                        </div>
                                  </div> -->
                                    <!-- <ng-template #financialSearch>
                                        <div class="row">
                                              <div class="form-group col-md-5">
                                                    <label for="class_name">Select Financial Year <span
                                                                class="required"></span></label>
                                                    <select class="form-control" [(ngModel)]="selectFinancialyear">
                                                          <option value="Select Year" [selected]="true"
                                                                [disabled]="true">Select Year</option>
                                                          <option value={{year}}
                                                                *ngFor="let year of getFinancialYear; let i = index">
                                                                {{year}}</option>
                                                    </select>
                                              </div>
                                              <div class="col-md-2 d-flex align-items-center justify-content-start">
                                                    <button type="submit" class="btn btn-info mt-3"
                                                          (click)="financialList()">Financial Search</button>
                                              </div>
                                        </div>
                                  </ng-template>
                                  <div>
                                        <button (click)="value = !value" class="btn btn-info mb-3">{{value? 'Plan
                                              Search':'Financial Search'}}</button>
                                  </div> -->
                                    <br>
                                    <div class="row">
                                          <div class="col-12">
                                                <div class="card">
                                                      <div class="card-body">

                                                            <table datatable [dtOptions]="dtOptions"
                                                                  [dtTrigger]="dtTrigger" class="row-border hover">
                                                                  <thead>
                                                                        <tr>
                                                                              <th>S. No.</th>
                                                                              <th>Name</th>
                                                                              <th>Mobile</th>
                                                                              <th>Tour Name</th>
                                                                              <!-- <th>Package Type</th> -->
                                                                              <th>Payment Status</th>
                                                                              <th>Date & Time</th>
                                                                              <th>Number Of Traveler</th>
                                                                              <th>Total Amount</th>
                                                                              <th>Amount Paid</th>
                                                                              <th>Remaining Amount</th>

                                                                              <th>Order History</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody *ngIf="allUser?.length > 0">
                                                                        <tr *ngFor="let user of allUser; let i = index">
                                                                              <td>{{ i + 1 }}</td>
                                                                              <td>{{ user?.user_id?.firstname }} {{
                                                                                    user?.user_id?.lastname }} </td>
                                                                              <td>{{user?.user_id?.mobile}}</td>
                                                                              <!-- <td>{{user.travel_boarding_point.boarding}}
                                                                            </td> -->
                                                                              <td>{{user?.tour_id?.title}}
                                                                              </td>

                                                                              <!-- <td>{{this.getPackage(user.travel_packageType)}}
                                                                            </td> -->
                                                                              <td>{{user?.payment_status}}</td>
                                                                              <td>{{user?.payment_time |
                                                                                    date:"d/M/yy,h:mm a"}}</td>
                                                                              <td>{{user.travel_no_of_traveler}}</td>
                                                                              <td>
                                                                                    {{(user.totalAmount +
                                                                                    user.addons_id).toLocaleString('en-IN',
                                                                                    {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                    })}}
                                                                              </td>
                                                                              <td>{{(+user?.amountPaid).toLocaleString('en-IN',
                                                                                    {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                    })}}</td>

                                                                              <td
                                                                                    *ngIf="(user.finalPrice/(user.partial_payment))-((user.finalPrice/(user.partial_payment))-user?.travel_no_of_traveler)===1 && user?.payment_status==='Cancelled';else Amt">
                                                                                    0</td>
                                                                              <ng-template #Amt>
                                                                                    <td>{{(+user?.totalAmount +
                                                                                          +user?.addons_id -
                                                                                          +user?.amountPaid).toLocaleString('en-IN',
                                                                                          {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                          })}} </td>
                                                                                    <!-- <td >{{user.addons_id.addons_data[i].price? user.addons_id.addons_data[i].price : user.totalAmount - user.amountPaid}}</td> -->
                                                                              </ng-template>
                                                                              <td>
                                                                                    <div class="btn-group">
                                                                                          <!-- <button type="button" class="btn btn-info" [routerLink]="['/user-order-history/'+data._id]">
                                                                                              Order History</button> -->
                                                                                          <button type="button"
                                                                                                class="btn btn-info"
                                                                                                [routerLink]="['/user-tour-details/'+user?.checkout_id]">
                                                                                                View More
                                                                                          </button>
                                                                                    </div>
                                                                              </td>
                                                                        </tr>
                                                                  </tbody>

                                                                  <tfoot *ngIf="allUser?.length > 0">
                                                                        <tr>
                                                                              <td colspan="7"
                                                                                    style="text-align: right;">Total:
                                                                              </td>
                                                                              <td>
                                                                                    {{
                                                                                    totalAmount.toLocaleString('en-IN',
                                                                                    { minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2 }) }}
                                                                              </td>
                                                                              <td>
                                                                                    {{
                                                                                    amountPaid.toLocaleString('en-IN', {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2 }) }}
                                                                              </td>
                                                                              <td>
                                                                                    {{
                                                                                    remainingAmount.toLocaleString('en-IN',
                                                                                    { minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2 }) }}
                                                                              </td>
                                                                              <td></td>
                                                                        </tr>
                                                                  </tfoot>


                                                                  <tbody *ngIf="allUser?.length === 0"
                                                                        style="text-align: center;">
                                                                        <tr>
                                                                              <td colspan="11"
                                                                                    class="no-data-available ">
                                                                                    No data!</td>
                                                                        </tr>
                                                                  </tbody>




                                                            </table>

                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</div>